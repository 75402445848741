define("inboxfront/templates/components/assistants/flows/add-triggers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XrsljWv6",
    "block": "{\"symbols\":[\"p\",\"item\"],\"statements\":[[4,\"common/panel\",null,[[\"open\"],[[24,[\"isOpen\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"toggle\"]],\"expected `p.toggle` to be a contextual component but found a string. Did you mean `(component p.toggle)`? ('inboxfront/templates/components/assistants/flows/add-triggers.hbs' @ L2:C7) \"],null]],[[\"icon\"],[\"configure\"]],{\"statements\":[[0,\"        Triggers\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `p.body` to be a contextual component but found a string. Did you mean `(component p.body)`? ('inboxfront/templates/components/assistants/flows/add-triggers.hbs' @ L5:C7) \"],null]],null,{\"statements\":[[4,\"each\",[[24,[\"triggersList\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[11,\"class\",[29,[\"alignCenter item-component trigger-item\",[28,\"if\",[[23,2,[\"disabled\"]],\" disabled\"],null],[28,\"if\",[[23,2,[\"textColor\"]],\" orange\"],null]]]],[11,\"onClick\",[28,\"action\",[[23,0,[]],[23,0,[\"addTrigger\"]],[23,2,[]]],null]],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"icon-component\"],[8],[0,\"\\n                    \"],[1,[28,\"svg-icon\",[[23,2,[\"icon\"]]],null],false],[0,\"\\n                \"],[9],[0,\"\\n                \"],[1,[23,2,[\"label\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/flows/add-triggers.hbs"
    }
  });

  _exports.default = _default;
});