define("inboxfront/components/common/sidenavs/create-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    users: Ember.inject.service(),
    validation: Ember.inject.service(),
    userIsInvalid: Ember.computed('user.{firstName,lastName,email,userDep,userProp,withEmail}', function () {
      var user = this.get('user');
      this.users.validateUser(user);
      return !(user && user.isValid);
    }),
    userIsMember: Ember.computed('user.systemRole.id', function () {
      return this.get('user.systemRole.id') === 'member';
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.resetUserForm();
    },
    resetUserForm: function resetUserForm() {
      this.set('user', this.users.create());
    }
  });

  _exports.default = _default;
});