define("inboxfront/controllers/escalations/edit", ["exports", "inboxfront/controllers/escalations/main", "inboxfront/gql/mutation"], function (_exports, _main, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _main.default.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    validation: Ember.inject.service(),
    escalationChanged: false,
    actions: {
      updateField: function updateField(field, name) {
        var _this = this;

        var value = this.get("escalation.".concat(field));

        if (value !== this.get("escalation._original".concat(field))) {
          if (field === 'name' && !this.get('validation').trimValidation[0].validate(value)) {
            return;
          } else if (field === 'description' && value.length > 250) {
            return;
          }

          var hash = {
            escalationId: this.get('escalation.id')
          };
          hash[field] = value;
          this.ajax.mutation(_mutation.default.CREATE_UPDATE_ESCALATION, 'createUpdateEscalation', hash, function () {
            _this.set("escalation._original.".concat(field), value);

            _this.get('paperToaster').show("".concat(name, " ").concat(_this.intl.t('status.updated')));
          });
        }
      },
      deleteEscalation: function deleteEscalation() {
        var _this2 = this;

        this.ajax.mutation(_mutation.default.DELETE_ESCALATION, 'deleteEscalation', {
          escalationId: this.get('escalation.id')
        }, function () {
          _this2.paperToaster.show(_this2.intl.t('Escalations.escalation_deleted'));

          _this2.set('openDeleteEscalationDialog', false);

          _this2.transitionToRoute('escalations.index');
        });
      },
      discardChanges: function discardChanges() {
        var escalation = this.copyEscalation(this.get('escalation._original'));
        escalation._original = this.get('escalation._original');
        this.set('escalation', escalation);
        this.set('escalationChanged', false);
      },
      saveChanges: function saveChanges() {
        var _this3 = this;

        var diff = this.get('diff');
        var escalationId = this.get('escalation.id');
        var mutations = [];

        if (!diff.reminders.isEqual) {
          diff.reminders.deleted.forEach(function (id) {
            mutations.push(_this3.ajax.mutation(_mutation.default.DELETE_REMINDER, 'deleteReminder', {
              reminderId: id
            }));
          });
          diff.reminders.added.forEach(function (r) {
            if (!_this3.validateReminder(r)) return;
            mutations.push(_this3.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this3.serializeReminder(r, escalationId), function (res) {
              Ember.set(r, 'id', res.reminder.id);
            }));
          });
          this.get('escalation.reminders').forEach(function (r) {
            if (r.updated === true) {
              if (!_this3.validateReminder(r)) return;
              mutations.push(_this3.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this3.serializeReminder(r, escalationId)));
            }
          });
        }

        if (!diff.escalations.isEqual) {
          diff.escalations.deleted.forEach(function (id) {
            mutations.push(_this3.ajax.mutation(_mutation.default.DELETE_REMINDER, 'deleteReminder', {
              reminderId: id
            }));
          });
          diff.escalations.added.forEach(function (r) {
            if (!_this3.validateReminder(r)) return;
            mutations.push(_this3.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this3.serializeReminder(r, escalationId), function (res) {
              Ember.set(r, 'id', res.reminder.id);
            }));
          });
          this.get('escalation.escalations').forEach(function (r) {
            if (r.updated === true) {
              if (!_this3.validateReminder(r)) return;
              mutations.push(_this3.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this3.serializeReminder(r, escalationId)));
            }
          });
        }

        if (!diff.conditions || !diff.slaTarget) {
          var hash = {
            escalationId: this.get('escalation.id')
          };

          if (!diff.conditions) {
            hash.conditions = JSON.stringify(this.serializeConditions(this.get('escalation.conditions'), this.get('escalation.targetType')));
          }

          if (!diff.slaTarget) {
            hash.slaTarget = JSON.stringify(this.get('escalation.slaTarget'));
          }

          mutations.push(this.ajax.mutation(_mutation.default.CREATE_UPDATE_ESCALATION, 'createUpdateEscalation', hash));
        }

        Ember.RSVP.all(mutations).then(function () {
          _this3.set('escalation._original', _this3.copyEscalation(_this3.get('escalation')));

          _this3.get('paperToaster').show('Escalation updated');

          _this3.set('escalationChanged', false);
        });
      },
      testChanges: function testChanges() {
        var diff = {};
        var conditions = this.get('escalation.conditions');
        var originalConditions = this.get('escalation._original.conditions');
        var isEqual = conditions.length === originalConditions.length;

        for (var i = 0; i < conditions.length && isEqual; i++) {
          var c = originalConditions.findBy('rule', conditions[i].rule);

          if (!c || conditions[i].values.length !== c.values.length) {
            isEqual = false;
          } else {
            for (var k = 0; k < conditions[i].values.length && isEqual; k++) {
              var v = c.values.findBy('id', conditions[i].values[k].id);

              if (!v) {
                isEqual = false;
              }
            }
          }
        }

        diff.conditions = isEqual;
        diff.slaTarget = JSON.stringify(this.get('escalation.slaTarget')) === JSON.stringify(this.get('escalation._original.slaTarget'));
        diff.reminders = this.testReminders(this.get('escalation.reminders'), this.get('escalation._original.reminders'));
        diff.escalations = this.testReminders(this.get('escalation.escalations'), this.get('escalation._original.escalations'));
        this.set('diff', diff);
        this.set('escalationChanged', !(diff.conditions && diff.slaTarget && diff.reminders.isEqual && diff.escalations.isEqual));

        if (this.get('escalationChanged')) {
          this.checkForm();
        }
      }
    },
    checkForm: function checkForm() {
      this.set('formIsInvalid', true);
      var conditions = this.get('escalation.conditions');
      var valid = true;

      for (var i = 0; i < conditions.length && valid; i++) {
        if (!conditions[i].rule) valid = false;
        if (conditions[i].values.length < 1) valid = false;
      }

      if (!valid) {
        return;
      }

      var slaTarget = this.get('escalation.slaTarget');
      valid = true;

      for (var _i = 0; _i < slaTarget.length && valid; _i++) {
        if (!slaTarget[_i].frt) valid = false;
        if (!slaTarget[_i].rt) valid = false;
      }

      if (!valid) {
        return;
      }

      var reminders = this.get('escalation.reminders');
      valid = true;

      for (var _i2 = 0; _i2 < reminders.length && valid; _i2++) {
        if (reminders[_i2].rule) {
          valid = this.validateReminder(reminders[_i2]);
        }
      }

      if (!valid) {
        return;
      }

      var escalations = this.get('escalation.escalations');
      valid = true;

      for (var _i3 = 0; _i3 < escalations.length && valid; _i3++) {
        valid = this.validateReminder(escalations[_i3]);
      }

      if (!valid) {
        return;
      }

      this.set('formIsInvalid', false);
    },
    testReminders: function testReminders(reminders, originalReminders) {
      var isEqual = true;
      var diff = {
        deleted: [],
        added: []
      };
      originalReminders.forEach(function (reminder) {
        if (!reminders.findBy('rule', reminder.rule)) {
          diff.deleted.push(reminder.id);
          isEqual = false;
        }
      });
      reminders.forEach(function (reminder) {
        Ember.set(reminder, 'updated', false);
        var r = originalReminders.findBy('rule', reminder.rule);

        if (!r) {
          diff.added.push(reminder);
          isEqual = false;
        } else {
          if (reminder.id === undefined) {
            Ember.set(reminder, 'id', r.id);
          }

          if (reminder.values.length !== r.values.length || reminder.minutes !== r.minutes) {
            Ember.set(reminder, 'updated', true);
            isEqual = false;
          } else if (reminder.values.length === r.values.length) {
            for (var k = 0; k < reminder.values.length; k++) {
              var v = r.values.findBy('id', reminder.values[k].id);

              if (!v) {
                isEqual = false;
                Ember.set(reminder, 'updated', true);
                break;
              }
            }
          }
        }
      });
      diff.isEqual = isEqual;
      return diff;
    }
  });

  _exports.default = _default;
});