define("inboxfront/components/ecommerce/connect-form-bc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('dummy', {
        accessToken: 'ksyzu7vrx1t4nmd8w9h4tkmjvgemzvk',
        clientName: 'raincheck app access to webhooks',
        apiPath: 'https://api.bigcommerce.com/stores/mx6txkf2mg/v3/',
        clientId: '3wu4c5cn0jtyqkxp1ls5bcd5karv8g6',
        clientSecret: '9596b8d8577b49fffe705bfbb5e700d357f74063ad5af2e755894f1a72f514cf'
      });
      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== "";
        }
      }];
      this.set('formData', {});
    },
    actions: {
      onDnDStoreCredentials: function onDnDStoreCredentials() {
        this.set('formData', _objectSpread({}, this.get('dummy')));
      },
      onSave: function onSave() {
        var accessToken = this.get('formData.accessToken') || '';

        if (accessToken && accessToken.trim() !== '') {
          this.onUpdate && this.onUpdate(this.get('formData'));
        } else {
          console.log('FORM INVALID', this.get('formData'));
        }
      },
      onConfirmDisconnect: function onConfirmDisconnect() {
        this.set('formData', {});
        this.onDisconnect && this.onDisconnect();
      }
    }
  });

  _exports.default = _default;
});