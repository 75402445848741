define("inboxfront/services/chatbots", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.triggerLimitations = [{
        enum: 'A_12_HOURS',
        value: '12_HOURS',
        label: '12h',
        tooltip: 'Send once per 12 hours.'
      }, {
        enum: 'A_24_HOURS',
        value: '24_HOURS',
        label: '24h',
        tooltip: 'Send once per 24 hours.'
      }, {
        enum: 'ONCE',
        value: 'ONCE',
        label: '1',
        tooltip: 'Send only once.'
      }, {
        enum: null,
        value: null,
        label: '',
        tooltip: 'Send per each visit.'
      }];
      this.triggerOptions = [{
        'name': 'first_visit',
        'label': 'First-time visit',
        'description': 'The bot starts when a new visitor lands on your website for the first time.',
        'limited': false,
        icon: 'trigger-first-time'
      }, {
        "name": "mouse_leaves_window",
        "label": "Mouse leaves window",
        'description': 'The bot starts when the visitor\'s mouse pointer leaves your website window for over a specified time.',
        'limited': true
      }, {
        "name": "specific_period",
        "label": "Specific period",
        'description': 'The bot starts only on the specified days and times of the day.',
        'limited': true
      }, {
        "name": "return_visitor",
        "label": "Return visitor",
        'description': 'The bot starts when the visitor returns to your site (is not a new visitor).',
        'limited': true
      }, {
        "name": "scrolling_scroll_percentage",
        "label": "Current Page Time/Scroll",
        'description': 'The bot will trigger when the visitor scrolls your website by a percentage or stays on your site for a set time.',
        'limited': true,
        'pageMeasure': 'time',
        'pageTimeValue': 30,
        'pageTimeMeasure': 'seconds',
        'pageUrlRule': 'exact',
        'pageScroll': '10%',
        'isForPage': false,
        'pageUrl': '',
        icon: 'trigger-current-page-scroll'
      }, {
        "name": "current_page",
        "label": "Current Page",
        'description': 'The bot starts when the visitor opens a page you specified inside the trigger.',
        'limited': true,
        'pageUrlRule': 'exact',
        'pageUrl': '',
        icon: 'trigger-current-page'
      }, {
        "name": "chat_initiated_by_visitor",
        "label": "Visitor clicks on chat button",
        'description': 'The bot starts when the visitor clicks on the chat icon visible on your website.',
        'limited': true
      }, {
        "name": "visitor_abandoned_form",
        "label": "* Visitor abandoned form",
        'textColor': 'orange',
        'description': 'The bot starts when the visitor fills in the form and leaves it.',
        'limited': true
      }, {
        "name": "instagram_story_reply",
        "label": "* Instagram - Story Reply",
        'textColor': 'orange',
        'description': 'The bot starts when a visitor replies to your Instagram story.',
        'limited': true
      }, {
        "name": "visitor_adds_to_a_cart",
        "label": "* Visitor adds to a cart",
        'textColor': 'orange',
        'description': 'The bot will trigger when visitors add an item to their shopping cart. It only works when the integration with Shopify/Big-Commerce is activated.',
        'limited': false
      }, {
        "name": "agent_starts_chatbot",
        "label": "* The operator starts the chatbot",
        'textColor': 'orange',
        'description': 'The bot starts when the operator triggers the bot during a chat conversation. The bot will take over the conversation.',
        'limited': false
      }, {
        "name": "agent_is_idle",
        "label": "The operator is idle",
        'description': 'The chatbot starts when an operator is assigned to a conversation but has not responded for a specified time.',
        'limited': false,
        'idleTimeValue': '',
        'idleTimeMeasure': 'minutes',
        'idleTimeoutValue': '',
        'idleTimeoutMeasure': 'minutes'
      }, {
        "name": "unassigned_conversation",
        "label": "Unassigned conversation",
        'description': 'The chatbot starts when the operator hasn\'t opened a new incoming chat (it remains unassigned).',
        'limited': false,
        'idleTimeValue': '',
        'idleTimeMeasure': 'seconds'
      }, {
        "name": "operator_marks_conversation_as_solved",
        "label": "* The operator marks conversation as solved",
        'textColor': 'orange',
        'description': 'The bot starts when the operator clicks on the "close conversation" button in the chat conversation.',
        'limited': false
      }, {
        "name": "from_another_chatbot",
        "label": "* From another chatbot",
        'textColor': 'orange',
        'description': 'Triggers when there is a paired "To another chatbot" node is issued from another chatbot.',
        'limited': false,
        icon: 'trigger-another-chatbot'
      }];
      this.components = [{
        name: 'Message',
        type: 'message',
        icon: 'message-circle',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Options (Single select)',
        type: 'single',
        icon: 'options-single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Options (Cards)',
        type: 'slider',
        icon: 'slider',
        data: null,
        blockType: 'SLIDER'
      }, {
        name: 'Data Collector (form)',
        type: 'data',
        icon: 'data-collector',
        data: null,
        blockType: 'FORM'
      }, {
        name: 'Randomize',
        type: 'random',
        icon: '',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Add/Remove a tag',
        type: 'add_remove_tag',
        icon: '',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Voucher/Promo Code',
        type: 'promo_code',
        icon: '',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Subscribe for mailing',
        type: 'subscribe',
        icon: '',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Transfer to Operator',
        type: 'old_action_assign_to',
        icon: '',
        data: null,
        blockType: 'TEXT'
      }, {
        name: '* Transfer to a department',
        type: 'assign_to_department',
        icon: '',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Create a Support Ticket',
        type: 'old_action_create_ticket',
        icon: '',
        data: null,
        blockType: 'TEXT'
      }, {
        name: '* Continue with Another Chatbot',
        type: 'go_to_chatbot',
        icon: '',
        data: null,
        blockType: 'TEXT'
      }];
      this.additionalConditions = [{
        name: 'Current Page',
        type: 'condition',
        icon: 'single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Contact Property',
        type: 'condition',
        icon: 'single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Return Visitor',
        type: 'condition',
        icon: 'single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: '* Browser',
        type: 'condition',
        icon: 'single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: '* Language',
        type: 'condition',
        icon: 'single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Mobile',
        type: 'condition',
        icon: 'single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Operator Status',
        type: 'condition',
        icon: 'single',
        data: null,
        blockType: 'TEXT'
      }];
    },
    deserializeFlow: function deserializeFlow(flow) {
      console.error('DESER FLOW', flow.newBlocks); // flow.newBlocks.forEach(b => {
      //     let extraData;
      //     try {
      //         extraData = JSON.parse(b.extraData);
      //     } catch (e) {
      //         extraData = {};
      //     }
      //     b.extraData = extraData;
      // });

      return flow.newBlocks;
      var bl = flow.blocks;
      var blocks = [];
      bl.forEach(function (b) {
        if (b.parent) {
          var p = bl.findBy('id', b.parent.id);

          if (p) {
            b.parent = p;
          }
        }

        var children = [];
        b.children.forEach(function (c) {
          c = bl.findBy('id', c.id);

          if (c) {
            children.addObject(c);
          }
        });

        if (!b.parent) {// console.log('SET CHILDREN', b, children);
        }

        b.children = children.sortBy('id');

        try {
          if (b.blockType === 'TEXT' && b.extraData) {
            b.extraData = JSON.parse(b.extraData);
          }
        } catch (e) {
          console.error(e);
          b.extraData = null;
        }

        blocks.addObject(b);
      }); // console.log('DESER FLOW', blocks);
      // console.log('NEW BLOCKS 1', flow.newBlocks);

      return blocks;
    },
    saveFlowSettings: function saveFlowSettings(extraData, flow) {
      var data = {
        extraData: JSON.stringify(extraData),
        flowId: flow.id
      }; // console.log('SAVE', data);

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', data).then(function () {});
    },
    saveNewBlock: function saveNewBlock(blockToSave) {
      var hash = {
        flowId: blockToSave.flow.id,
        mainTitle: blockToSave.mainTitle,
        blockType: blockToSave.blockType,
        name: blockToSave.name,
        firstBlock: blockToSave.firstBlock,
        childBlockIds: [],
        extraData: JSON.stringify(blockToSave.extraData)
      };

      if (blockToSave.id) {
        hash.blockId = blockToSave.id;
      } //createUpdateNewFlowBlock
      // $actions: String
      // $attachments: [ID]
      // $blockId: Int
      // $blockType: String
      // $childBlockIds: [Int]
      // $extraData: String
      // $firstBlock: Boolean
      // $flowId: Int
      // $mainTitle: String
      // $name: String
      // $prevBlockId: Int
      // $subtitle: String


      console.log('SAVE NEW BLOCK', hash, blockToSave); // const hash = {
      //     blockId: 3,
      //     mainTitle: 'new block 3',
      //     childBlockIds: [1, 2]
      // };
      // console.log('CREATE', hash);
      // this.ajax.query(Mutation.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {blockId: 15}).then((res) => {
      //     console.log('RES', res);
      // });

      this.ajax.query(_mutation.default.CREATE_UPDATE_NEW_FLOW_BLOCK, 'createUpdateNewFlowBlock', hash).then(function (_ref) {
        var block = _ref.block;

        if (!blockToSave.id) {
          Ember.set(blockToSave, 'id', block.id);
        }

        console.log('RES', block);
      });
    },
    removeNewBlock: function removeNewBlock(block) {
      console.log('REMOVE NEW BLOCK', block);
      this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
        blockId: block.id
      }).then(function (res) {
        console.log('RES', res);
      });
    },
    deserialize: function deserialize(flow) {
      /*
              workflow.title = workflow.name;
              workflow.type = 2;
              let trigger;
               try {
                  trigger = JSON.parse(workflow.trigger);
              } catch (e) {
                  trigger = {conditions: {any: [], all: []}, actions: []};
              }
               workflow.trigger = trigger;
              workflow.triggerBlock = {id: null, type: 'trigger', children: []};
               let blocks = [];
              workflow.blocks.forEach(b => {
                  if (!b.parent) {
                      b.parent = workflow.triggerBlock;
                      workflow.triggerBlock.children.addObject(b);
                  } else {
                      const block = workflow.blocks.findBy('id', b.parent.id);
                      block && (b.parent = block);
                  }
                  let children = [];
                  b.children.forEach(c => {
                      const block = workflow.blocks.findBy('id', c.id);
                      block && children.addObject(block);
                  });
                  b.children = children;
                   let rules;
                  try {
                      rules = JSON.parse(b.rules);
                  } catch (e) {
                      rules = {conditions: {}, actions: []};
                  }
                  b.rules = rules;
                  if (Array.isArray(rules)) {
                      this._setBlockDefaults(b, 'if_else');
                       b.mainTitle = b.description;
                      b.children = [];
                       let rule = rules[0];
                      let block;
                      if (rule.actions[0] && rule.actions[0].params && rule.actions[0].params.block_id) {
                          block = workflow.blocks.findBy('id', '' + rule.actions[0].params.block_id);
                      }
                       let dummyId = this.get('dummyId');
                      let children = [];
                      const branch1 = {
                          flowId: workflow.id,
                          blockType: 'BRANCH',
                          parent: b,
                          children: children,
                          name: ((rule && rule.name) ? rule.name : 'Branch 1'),
                          id: dummyId,
                          position: 0
                      };
                      if (block) {
                          children.addObject(block);
                          block.parent = branch1;
                          block = undefined;
                      }
                       dummyId -= 1;
                       b.children.addObject(branch1);
                      blocks.addObject(branch1);
                       rule = rules[1];
                      if (rule.actions[0] && rule.actions[0].params && rule.actions[0].params.block_id) {
                          block = workflow.blocks.findBy('id', '' + rule.actions[0].params.block_id);
                      }
                       children = [];
                      const branch2 = {
                          flowId: workflow.id,
                          blockType: 'BRANCH',
                          parent: b,
                          children: children,
                          name: ((rule && rule.name) ? rule.name : 'Branch 2'),
                          id: dummyId,
                          position: 1
                      };
                      if (block) {
                          children.addObject(block);
                          block.parent = branch2;
                      }
                       dummyId -= 1;
                       b.children.addObject(branch2);
                      blocks.addObject(branch2);
                      this.set('dummyId', dummyId);
                       b.ifConditions = Array.isArray(b.rules[0].if) ? b.rules[0].if : [];
                      b.thenConditions = Array.isArray(b.rules[1].then) ? b.rules[1].then : [];
                  } else {
                      if (rules.type) {
                          this._setBlockDefaults(b, 'terminator');
                      } else {
                          if (!rules.conditions) {
                              rules.conditions = {};
                          }
                          if (!rules.actions) {
                              rules.actions = [];
                          }
                          let period;
                           if (b.rules.actions[0]) {
                              const tagModules = [
                                  {id: 'tickets', label: 'Ticket/Task'},
                                  {id: 'contact', label: 'Contact'}
                              ];
                              const commentModules = [
                                  {id: 'tickets', label: 'Ticket/Task'},
                                  {id: 'contact', label: 'Contact'},
                              ];
                              const paramsAction = b.rules && b.rules.actions[0] && b.rules.actions[0].params;
                               this._setBlockDefaults(b, b.rules.actions[0].name);
                               switch (b.type) {
                                  case 'send_email':
                                      if (paramsAction.sender) {
                                          b.sender = emailAccounts.findBy('pk', paramsAction.sender);
                                      }
                                      b.to = this.restoreVariables(paramsAction.to) || '{{Customer Email}}';
                                      b.subject = this.restoreVariables(paramsAction.subject);
                                      b.body = this.restoreVariables(paramsAction.body);
                                      b.mainTitle = b.subject;
                                       break;
                                  case 'send_internal':
                                      b.model = b.rules.actions[0].params.model === 'rooms' ?
                                          {id: 'rooms', label: 'Teams'} :
                                          {id: 'users', label: 'Direct Messages'};
                                      b.description = paramsAction.description;
                                      b.users = paramsAction.users;
                                      b.rooms = paramsAction.rooms;
                                      b.recipients = paramsAction.recipients;
                                      b.message = paramsAction.message;
                                      b.mainTitle = b.description;
                                       break;
                                  case 'add_comment':
                                      b.module = commentModules.findBy('id', paramsAction.module);
                                      b.comment = paramsAction.comment;
                                      b.mainTitle = b.comment;
                                       break;
                                  case 'create_ticket':
                                      b.subject = paramsAction.subject;
                                      b.department = workflow.departments.findBy('id', paramsAction.department);
                                       if (paramsAction.assignee && b.department) {
                                          const departmentId = b.department.id;
                                          const propertyId = workflow.properties[0] ? workflow.properties[0].id : '';
                                           if (propertyId && departmentId) {
                                              const hash = {departments: [departmentId], propertyIds: [propertyId]};
                                              this.ajax.query(Query.HAVE_TASK_ACCESS, 'haveAccess', hash).then(result => {
                                                  set(b, 'assignee', result.findBy('id', paramsAction.assignee));
                                              });
                                          }
                                      }
                                       b.status = paramsAction.status;
                                      b.priority = paramsAction.priority;
                                      b.periodTimes = paramsAction.times || 'minutes';
                                      period = paramsAction.period || 0;
                                      if (b.periodTimes === 'days') {
                                          period = Math.floor(period / 3600);
                                      } else if (b.periodTimes === 'hours') {
                                          period = Math.floor(period / 60);
                                      }
                                      b.period = period;
                                      b.tags = Array.isArray(paramsAction.tag_list) ? paramsAction.tag_list : '';
                                      b.mainTitle = b.subject;
                                       break;
                                  case 'update_contact':
                                      b.firstName = paramsAction.first_name;
                                      b.lastName = paramsAction.last_name;
                                      b.email = paramsAction.email;
                                      b.owner = paramsAction.owner;
                                       break;
                                  case 'add_tags':
                                      b.module = tagModules.findBy('id', paramsAction.module);
                                      b.tag_list = Array.isArray(paramsAction.tag_list) ? paramsAction.tag_list : [];
                                       break;
                                  case 'set_status':
                                      b.status = paramsAction.status;
                                       break;
                                  case 'set_due_date':
                                      b.periodTimes = paramsAction.times || 'minutes';
                                      period = paramsAction.due_date || 0;
                                       if (b.periodTimes === 'days') {
                                          period = Math.floor(period / 3600);
                                      } else if (b.periodTimes === 'hours') {
                                          period = Math.floor(period / 60);
                                      }
                                      b.period = period;
                                       break;
                                  default:
                                      b.blockType = 'DEFAULT';
                              }
                          }
                      }
                  }
                   this.validateBlock(b, workflow);
              });
              blocks.forEach(b => {
                  workflow.blocks.addObject(b);
              });
               this.validateWorkflow(workflow);
               return workflow;/**/
    },
    _setBlockDefaults: function _setBlockDefaults(block, type) {
      var _ref2;

      var component = (_ref2 = []).concat.apply(_ref2, _toConsumableArray(this.get('components').map(function (componentGroup) {
        return componentGroup.items;
      }))).findBy('type', type);

      block.blockType = component.blockType;
      block.name = component.name;
      block.icon = component.icon;
      block.color = component.color;
      block.type = component.type;
    },
    addBlock: function addBlock(component, after, workflow, onAdd) {
      var _this = this;

      var block = _objectSpread(_objectSpread({}, component), {}, {
        parent: after,
        children: after.children
      });

      if (!this.get('dummyId')) {
        this.set('dummyId', -1);
      }

      if (block.blockType === 'IFELSE') {
        var branch1 = {
          blockType: 'BRANCH',
          parent: block,
          children: after.children,
          name: 'Branch 1',
          position: 0,
          id: this.get('dummyId')
        };
        this.set('dummyId', this.get('dummyId') - 1);
        var branch2 = {
          blockType: 'BRANCH',
          parent: block,
          children: [],
          name: 'Branch 2',
          position: 1,
          id: this.get('dummyId')
        };
        this.set('dummyId', this.get('dummyId') - 1);
        block.children = [branch1, branch2];
      } else if (block.blockType === 'TICKET' || block.blockType === 'TASK') {
        Ember.set(block, 'status', 'OPEN');
        Ember.set(block, 'priority', 'LOW');
        Ember.set(block, 'periodTimes', 'minutes');
      } else if (block.blockType === 'EMAIL') {
        Ember.set(block, 'to', '{{Customer Email}}');
      } else if (block.blockType === 'DUEDATE') {
        Ember.set(block, 'period', 0);
        Ember.set(block, 'periodTimes', 'minutes');
      }

      this.saveBlock(block, workflow).then(function () {
        if (block.blockType === 'IFELSE') {
          workflow.blocks.addObject(block.children[0]);
          workflow.blocks.addObject(block.children[1]);

          if (block.children[0].children[0]) {
            block.children[0].children[0].parent = block.children[0];

            _this.saveBlock(block.children[0].children[0], workflow);
          }
        } else {
          if (block.children[0]) {
            block.children[0].parent = block;

            _this.saveBlock(block.children[0], workflow);
          }
        }

        Ember.set(after, 'children', [block]);

        if (after.id) {
          if (after.blockType === 'BRANCH') {
            _this.saveBlock(after.parent, workflow);
          } else {
            _this.saveBlock(after, workflow);
          }
        } else {
          _this.updateTrigger(workflow);
        }

        workflow.blocks.addObject(block);

        _this.validateWorkflow(workflow);

        onAdd && onAdd(block);
      }).catch(function (e) {
        console.error('ADD BLOCK', block, e);
      });
    },
    clearVariables: function clearVariables(text) {
      var parts = text.split('{{');

      if (parts.length) {
        text = '';
        parts.forEach(function (p) {
          if (p.indexOf('}}') >= 0) {
            var vars = p.split('}}');
            vars[0] = vars[0].split(' ').join('_').toLowerCase();
            text += '{' + vars[0] + '}' + vars[1];
          } else {
            text += p;
          }
        });
      }

      return text;
    },
    restoreVariables: function restoreVariables(text) {
      var parts = text.split('{');

      if (parts.length) {
        text = '';
        parts.forEach(function (p) {
          if (p.indexOf('}') >= 0) {
            var vars = p.split('}');
            var words = vars[0].split('_');

            for (var i = 0, l = words.length; i < l; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);

              if (words[i].indexOf('}') >= 0) {
                break;
              }
            }

            vars[0] = words.join(' ');
            text += '{{' + vars[0] + '}}' + vars[1];
          } else {
            text += p;
          }
        });
      }

      return text;
    },
    saveBlock: function saveBlock(block, workflow, onSave) {
      var _this2 = this;

      var rules = {};

      if (block.blockType === 'IFELSE') {
        if (!block.rules) {
          block.rules = [{
            conditions: {},
            actions: []
          }, {
            conditions: {},
            actions: []
          }];
        }

        block.rules[0].name = block.children[0].name;
        block.rules[1].name = block.children[1].name;
        block.rules[0].if = block.ifConditions;
        block.rules[1].then = block.thenConditions;
        block.rules[0].conditions = this.formatConditions(block.ifConditions);
        block.rules[1].conditions = this.formatConditions(block.thenConditions);
        block.ifConditions && block.ifConditions.length && console.log('IF CONDITIONS', block.rules[0].conditions);
        block.thenConditions && block.thenConditions.length && console.log('THEN CONDITIONS', block.rules[1].conditions);

        if (!block.thenConditions || !block.thenConditions.length) {
          var then = [];
          block.rules[0].conditions.any && block.rules[0].conditions.any.forEach(function (c) {
            var n = _objectSpread({}, c);

            if (c.operator === 'equal_to') {
              n.operator = 'not_equal_to';
            }

            then.addObject(n);
          });
          block.rules[1].conditions.all = then;
          console.log('REVERSE CONDITIONS', block.rules[0].conditions, 'TO', block.rules[1].conditions);
        }
      } else {
        if (!block.rules) {
          block.rules = {};
        }

        if (!block.rules.actions) {
          block.rules.actions = [];
        }

        if (block.blockType === 'EMAIL') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'send_email',
              params: {
                sender: '',
                to: '{{Customer Email}}',
                subject: '',
                body: ''
              }
            };
          }

          rules = block.rules;
          block.sender && (rules.actions[0].params.sender = +block.sender.pk);
          rules.actions[0].params.to = block.to ? this.clearVariables(block.to) : '';
          rules.actions[0].params.subject = block.subject ? this.clearVariables(block.subject) : '';
          rules.actions[0].params.body = block.body ? this.clearVariables(block.body) : '';
        } else if (block.blockType === 'INTERNAL') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'send_internal',
              params: {
                model: '',
                users: [],
                rooms: [],
                recipients: [],
                description: '',
                message: ''
              }
            };
          }

          rules = block.rules;
          block.model && block.model.id && (rules.actions[0].params.model = block.model.id);
          block.users && (rules.actions[0].params.users = block.users);
          block.rooms && (rules.actions[0].params.rooms = block.rooms);
          block.recipients && (rules.actions[0].params.recipients = block.recipients.map(function (r) {
            return r.id;
          }));
          rules.actions[0].params.description = block.description || '';
          rules.actions[0].params.message = block.message || '';
        } else if (block.blockType === 'COMMENT') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'add_comment',
              params: {
                module: '',
                comment: ''
              }
            };
          }

          rules = block.rules;
          block.module && (rules.actions[0].params.module = block.module.id);
          rules.actions[0].params.comment = block.comment || '';
        } else if (block.blockType === 'TICKET' || block.blockType === 'TASK') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'create_ticket',
              params: {
                subject: '',
                department: '',
                assignee: '',
                status: 'OPEN',
                priority: 'LOW',
                period: 0,
                tag_list: [],
                task_type: block.blockType
              }
            };
          }

          rules = block.rules;
          rules.actions[0].params.subject = block.subject || '';
          block.department && (rules.actions[0].params.department = block.department.id);
          block.assignee && (rules.actions[0].params.assignee = block.assignee.id);
          block.status && (rules.actions[0].params.status = block.status);
          block.priority && (rules.actions[0].params.priority = block.priority);
          var period = block.period || 0;

          if (block.periodTimes === 'days') {
            period = period * 3600;
          } else if (block.periodTimes === 'hours') {
            period = period * 60;
          }

          rules.actions[0].params.period = period;
          block.tags && (rules.actions[0].params.tag_list = block.tags.length > 0 ? block.tags : []);
          block.periodTimes && (rules.actions[0].params.times = block.periodTimes);
        } else if (block.blockType === 'CONTACT') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'update_contact',
              params: {
                first_name: '',
                last_name: '',
                email: '',
                contact_owner: ''
              }
            };
          }

          rules = block.rules;
          rules.actions[0].params.first_name = block.firstName || '';
          rules.actions[0].params.last_name = block.lastName || '';
          rules.actions[0].params.email = block.email || '';
          block.owner && (rules.actions[0].params.contact_owner = block.owner.id);
        } else if (block.blockType === 'TAG') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'add_tags',
              params: {
                module: '',
                tag_list: []
              }
            };
          }

          rules = block.rules;
          block.module && (rules.actions[0].params.module = block.module.id);
          rules.actions[0].params.tag_list = block.tag_list || [];
        } else if (block.blockType === 'STATUS') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'set_status',
              params: {
                status: ''
              }
            };
          }

          rules = block.rules;
          block.status && (rules.actions[0].params.status = block.status);
        } else if (block.blockType === 'DUEDATE') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'set_due_date',
              params: {
                due_date: ''
              }
            };
          }

          rules = block.rules;

          var _period = block.period || 0;

          if (block.periodTimes === 'days') {
            _period = _period * 3600;
          } else if (block.periodTimes === 'hours') {
            _period = _period * 60;
          }

          rules.actions[0].params.due_date = _period;
          block.periodTimes && (rules.actions[0].params.times = block.periodTimes);
        } else if (block.blockType === 'TERMINATE') {
          if (!block.rules.type) {
            block.rules.type = block.type;
          }
        }
      }

      var parentId = block.parent.id;

      if (block.parent.blockType === 'BRANCH') {
        parentId = block.parent.parent.id;
      }

      if (block.blockType === 'IFELSE') {
        if (block.children[0].children[0]) {
          block.rules[0].actions[0] = {
            name: 'run_next',
            params: {
              block_id: +block.children[0].children[0].id
            }
          };
        } else {
          block.rules[0].actions = [];
        }

        if (block.children[1].children[0]) {
          block.rules[1].actions[0] = {
            name: 'run_next',
            params: {
              block_id: +block.children[1].children[0].id
            }
          };
        } else {
          block.rules[1].actions = [];
        }
      } else {
        if (!block.parent.id) {
          block.rules.conditions = workflow.trigger.conditions;
        } else {
          if (block.parent.blockType === 'BRANCH') {
            if (block.parent === block.parent.parent.children[0]) {
              block.rules.conditions = block.parent.parent.rules[0].conditions;
            } else {
              block.rules.conditions = block.parent.parent.rules[1].conditions;
            }
          } else {
            block.rules.conditions = block.parent.rules.conditions;
          }
        }

        if (block.children[0]) {
          block.rules.actions[1] = {
            name: 'run_next',
            params: {
              block_id: +block.children[0].id
            }
          };
        } else {
          if (block.blockType === 'TERMINATE') {
            block.rules.actions = [];
          } else {
            block.rules.actions[1] = {};
          }
        }
      }

      var data = {
        parentId: parentId,
        rules: JSON.stringify(block.rules),
        workflowId: workflow.id,
        description: block.description
      };

      if (block.id) {
        data.blockId = block.id;

        if (!parentId) {
          data.parentId = 0;
        }
      }

      return this.ajax.query(_mutation.default.CREATE_UPDATE_WORKFLOW_BLOCK, 'createOrUpdateWorkflowBlock', data).then(function (res) {
        if (res.errors) {
          console.error('createOrUpdateWorkflowBlock', res.errors[0].message, 'DATA:', data);
        }

        if (res.block) {
          if (!block.id) {
            Ember.set(block, 'id', res.block.id);
          }

          _this2.validateBlock(block, workflow);

          onSave && onSave();
        }
      });
    },
    replaceVariable: function replaceVariable(text) {
      return text;
    },
    deleteBlock: function deleteBlock(block, workflow) {
      var _this3 = this;

      var requests = [];

      if (block.blockType === 'IFELSE') {
        if (block.children[0].children[0]) {
          Ember.set(block.parent, 'children', block.children[0].children);
          block.children[0].children[0].parent = block.parent;
          requests.addObject(this.saveBlock(block.children[0].children[0], workflow));
        } else {
          Ember.set(block.parent, 'children', block.children[1].children);

          if (block.children[1].children[0]) {
            block.children[1].children[0].parent = block.parent;
            requests.addObject(this.saveBlock(block.children[1].children[0], workflow));
          }
        }

        block.children.forEach(function (b) {
          workflow.blocks.removeObject(b);
        });
      } else {
        Ember.set(block.parent, 'children', block.children);

        if (block.children[0]) {
          block.children[0].parent = block.parent;
          requests.addObject(this.saveBlock(block.children[0], workflow));
        }
      }

      if (!block.parent.id) {
        requests.addObject(this.updateTrigger(workflow));
      } else {
        if (block.parent.blockType === 'BRANCH') {
          requests.addObject(this.saveBlock(block.parent.parent, workflow));
        } else {
          requests.addObject(this.saveBlock(block.parent, workflow));
        }
      }

      return new Promise(function (resolve) {
        Ember.RSVP.all(requests).then(function () {
          _this3.ajax.mutation(_mutation.default.DELETE_WORKFLOW_BLOCK, 'deleteWorkflowBlock', {
            blockId: block.id
          }, function () {
            workflow.blocks.removeObject(block);

            _this3.validateWorkflow(workflow);

            resolve();
          });
        }).catch(function (e) {
          console.error(e);
        });
      });
    },
    updateTrigger: function updateTrigger(workflow, objectType) {
      if (workflow.triggerBlock.children[0]) {
        workflow.trigger.actions[0] = {
          name: 'run_next',
          params: {
            block_id: +workflow.triggerBlock.children[0].id
          }
        };
      } else {
        workflow.trigger.actions = [];
      }

      var data = {
        workflowId: workflow.id,
        trigger: JSON.stringify(workflow.trigger)
      };

      if (objectType) {
        data.objectType = objectType;
        Ember.set(workflow, 'objectType', objectType);
      }

      this.validateWorkflow(workflow);
      return this.ajax.mutation(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', data);
    },
    validateBlock: function validateBlock(block, workflow) {
      var valid = false;

      if (block.blockType === 'EMAIL') {
        valid = true;

        if (valid && !block.sender) {
          valid = false;
        }

        if (valid && block.to) {
          if (block.to !== '{{Customer Email}}') {
            valid = this.validateEmail(block.to);
          }
        } else {
          valid = false;
        }

        if (valid) {
          if (!block.subject || block.subject.trim() === '') {
            valid = false;
          }
        }

        if (valid) {
          if (!block.body || block.body.trim() === '') {
            valid = false;
          }
        }
      } else if (block.blockType === 'TICKET' || block.blockType === 'TASK') {
        valid = true;

        if (valid) {
          if (!block.subject || block.subject.trim() === '') {
            valid = false;
          }
        }

        if (valid && !block.department) {
          valid = false;
        }

        if (valid && !block.status) {
          valid = false;
        }

        if (valid && !block.priority) {
          valid = false;
        } // if (valid && !block.period) {
        //     valid = false;
        // }

      } else if (block.blockType === 'INTERNAL') {
        valid = true;

        if (!block.description || block.description.trim() === '') {
          valid = false;
        }

        if (!block.message || block.message.trim() === '') {
          valid = false;
        }

        if (valid && !block.model) {
          valid = false;
        }

        if (block.recipients && !block.recipients.length) {
          valid = false;
        }
      } else if (block.blockType === 'COMMENT') {
        valid = true;

        if (!block.comment || block.comment.trim() === '') {
          valid = false;
        }

        if (valid && !block.module) {
          valid = false;
        }
      } else if (block.blockType === 'STATUS') {
        valid = true;

        if (!block.status) {
          valid = false;
        }
      } else if (block.blockType === 'IFELSE') {
        valid = true;

        if (!block.description) {
          valid = false;
        }

        if (!block.ifConditions || !block.ifConditions.length) {
          valid = false;
        }
      } else if (block.blockType === 'TAG') {
        valid = true;

        if (!block.tag_list || !block.tag_list.length) {
          valid = false;
        }

        if (valid && !block.module) {
          valid = false;
        }
      } else if (block.blockType === 'DUEDATE') {
        valid = true;

        if (isNaN(block.period) || block.period < 0) {
          valid = false;
        }
      } else if (block.blockType === 'TERMINATE') {
        valid = true;
      } else if (block.blockType === 'BRANCH') {
        valid = true;
      }

      Ember.set(block, 'isValid', valid);
      this.validateWorkflow(workflow);
    },
    validateEmail: function validateEmail(value) {
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(value);
    },
    validateWorkflow: function validateWorkflow(workflow) {
      var valid = !!(workflow.title && workflow.title.trim());

      if (valid) {
        if (!(workflow.trigger.conditions.any && workflow.trigger.conditions.any.length) && !(workflow.trigger.conditions.all && workflow.trigger.conditions.all.length)) {
          valid = false;
        }

        valid = valid && workflow.blocks.length > 1;
        valid && workflow.blocks.forEach(function (b) {
          if (b.blockType === 'BRANCH') {
            return;
          }

          if (valid && !b.isValid) {
            valid = false;
          }
        });

        if (valid) {
          valid = this.checkTerminator(workflow.triggerBlock);
        }
      }

      Ember.set(workflow, 'isValid', valid);
    },
    checkTerminator: function checkTerminator(block) {
      if (!block.children.length) {
        //only terminator block without children
        return block.blockType === 'TERMINATE';
      } else if (block.children.length === 1) {
        //move forward
        return this.checkTerminator(block.children[0]);
      } else {
        // branches
        return this.checkTerminator(block.children[0]) && this.checkTerminator(block.children[1]);
      }
    },
    formatConditions: function formatConditions(rules) {
      var conditions = {};
      var any = [];
      var all = [];
      rules && rules.forEach(function (r, index) {
        var rule = {};

        if (!(r.selectedModule && r.selectedRule && r.selectedOption)) {
          return;
        }

        if (r.selectedModule === 'Ticket/Task') {
          switch (r.selectedRule) {
            case 'Status':
              rule.name = 'status';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id.toLowerCase();
              break;

            case 'Priority':
              rule.name = 'priority';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id.toLowerCase();
              break;

            case 'Issue Type':
              rule.name = 'issue_type';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id;
              break;

            case 'Source':
              rule.name = 'source';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id.toLowerCase();
              break;

            case 'Department':
              rule.name = 'department';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.pk;
              break;

            case 'Assignee':
              rule.name = 'assignee';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.pk;
              break;

            case 'Attachment':
              rule.name = 'attachment';
              rule.operator = r.selectedOption.name === 'Is true' ? 'is_true' : 'is_false';
              rule.value = '';
              break;
          }
        } else if (r.selectedModule === 'Conversation') {
          switch (r.selectedRule) {
            case 'Channel/source':
              rule.name = 'channel';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id.toLowerCase();
              break;

            case 'Assignee':
              rule.name = 'assignee';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.pk;
              break;

            case 'Country':
              rule.name = 'country';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.iso2;
              break;

            case 'Refund':
              rule.name = 'refund';
              rule.operator = r.selectedOption.name === 'Is true' ? 'is_true' : 'is_false';
              rule.value = '';
              break;

            case 'Cancel':
              rule.name = 'cancel';
              rule.operator = r.selectedOption.name === 'Is true' ? 'is_true' : 'is_false';
              rule.value = '';
              break;

            case 'Profile qualification':
              rule.name = 'profile_is_complited';
              rule.operator = r.selectedOption.name === 'Is complete' ? 'is_true' : 'is_false';
              rule.value = '';
              break;
          }
        } else {
          console.error('WRONG RULE', r);
          return;
        }

        if (rules[index - 1]) {
          if (rules[index - 1].operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        } else {
          if (r.operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        }
      });

      if (any.length) {
        conditions.any = any;
      }

      if (all.length) {
        conditions.all = all;
      }

      return conditions;
    }
  });

  _exports.default = _default;
});