define("inboxfront/router", ["exports", "inboxfront/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('dashboard', {
      path: "/"
    });
    this.route('onboarding');
    this.route('signup', function () {
      this.route('active');
    });
    this.route('settings');
    this.route('users', function () {
      this.route('edit');
    });
    this.route('flows', {
      path: '/chatbots'
    }, function () {
      this.route('edit');
      this.route('tmpl');
    });
    this.route('workflows', {
      path: '/orchestrations'
    }, function () {
      this.route('edit');
    });
    this.route('kb');
    this.route('tasks', function () {
      this.route('edit');
      this.route('board');
    });
    this.route('chats');
    this.route('channels', {
      queryParams: ['page', 'code']
    }); // this.route('channels');

    this.route('companies', function () {
      this.route('edit');
    });
    this.route('profile');
    this.route('security');
    this.route('preference', function () {
      this.route('notification');
    });
    this.route('roles', function () {
      this.route('edit');
    });
    this.route('departments');
    this.route('response');
    this.route('permissions');
    this.route('import');
    this.route('contacts', function () {
      this.route('edit');
    });
    this.route('organizations', function () {
      this.route('edit');
    });
    this.route('forgotpassword');
    this.route('setpassword', {
      path: '/setpassword/:key'
    });
    this.route('group', function () {
      this.route('edit');
    });
    this.route('escalations', function () {
      this.route('edit');
    });
    this.route('ticketfields');
    this.route('issuetypes');
    this.route('contactfields');
    this.route('orgfields');
    this.route('ecommerce');
    this.route('linkexpired');
    this.route('chat', {
      path: '/chat/:chat_id'
    });
    this.route('crm');
    this.route('chatslimit');
    this.route('all_icons');
  });
  var _default = Router;
  _exports.default = _default;
});