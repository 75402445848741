define("inboxfront/routes/customfields", ["exports", "inboxfront/routes/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    actions: {
      willTransition: function willTransition(transition) {
        var applicationController = Ember.getOwner(this).lookup('controller:application');
        console.log('CHECK CHANGES BEFORE LEAVE PAGE', applicationController);

        this._super(transition);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('editedField', null);
      controller.set('editedSection', null);
    }
  });

  _exports.default = _default;
});