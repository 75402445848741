define("inboxfront/components/common/customfields/cf-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    customfields: Ember.inject.service(),
    validation: Ember.inject.service(),
    tagName: '',
    showDeleteSectionDialog: false,
    openDiscardChangesDialog: false,
    restValues: Ember.computed('editedSection', function () {
      var editedSection = this.get('editedSection');
      var section = this.get('section');

      if (editedSection && editedSection !== section) {
        return [];
      }

      var restValues = _toConsumableArray(section.parentField.children);

      section.conditions.forEach(function (c) {
        restValues.removeObject(c);
      });
      return restValues;
    }),
    selectedValues: Ember.computed('editedSection', function () {
      return _toConsumableArray(this.get('section.conditions'));
    }),
    actions: {
      deleteSection: function deleteSection() {
        var _this = this;

        var section = this.get('section');
        this.get('customfields').deleteSection(section, function () {
          _this.set('showDeleteSectionDialog', false);

          _this.get('paperToaster').show('Section deleted');

          _this.onDeleteSection(section);
        });
      },
      startEdit: function startEdit() {
        if (this.get('isEditMode')) {
          return;
        }

        var section = this.get('section');
        Ember.set(section, '_edited', {
          'title': section.title
        });
        this.set('editedSection', section);
        this.checkSection();
      },
      endEdit: function endEdit(confirmed) {
        var newSection = !this.get('section.id');

        if (!confirmed && (this.get('sectionIsUpdated') || newSection)) {
          this.set('openDiscardChangesDialog', true);
        } else {
          if (newSection) {
            this.onDeleteSection(this.get('section'));
          }

          this.set('openDiscardChangesDialog', false);
          this.set('editedSection', null);
        }
      },
      update: function update(name, value) {
        Ember.set(this.get('section._edited'), name, value);
        this.checkSection();
      },
      selectValue: function selectValue(value) {
        this.get('restValues').removeObject(value);
        this.get('selectedValues').addObject(value);
        this.checkSection();
      },
      deselectValue: function deselectValue(value) {
        this.get('restValues').addObject(value);
        this.get('selectedValues').removeObject(value);
        this.checkSection();
      },
      saveSection: function saveSection() {
        var _this2 = this;

        var section = this.get('section');
        Ember.set(section, 'conditions', this.get('selectedValues'));
        Ember.set(section, 'title', section._edited.title);
        this.get('customfields').updateSection(section, this.get('settingsId'), function (res) {
          _this2.set('editedSection', null);

          if (section.id === undefined) {
            Ember.set(section, 'id', res.section.id);

            _this2.get('paperToaster').show('Section created');
          } else {
            _this2.get('paperToaster').show('Section updated');
          }
        });
      },
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            sourceArgs = _ref.sourceArgs,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex,
            targetArgs = _ref.targetArgs;
        this.onSubFieldDragEnd({
          sourceList: sourceList,
          sourceIndex: sourceIndex,
          sourceArgs: sourceArgs,
          targetList: targetList,
          targetIndex: targetIndex,
          targetArgs: targetArgs
        });
      },
      onChoicesDragEnd: function onChoicesDragEnd(_ref2) {
        var sourceList = _ref2.sourceList,
            sourceIndex = _ref2.sourceIndex,
            sourceArgs = _ref2.sourceArgs,
            targetList = _ref2.targetList,
            targetIndex = _ref2.targetIndex,
            targetArgs = _ref2.targetArgs;
        this.onChoicesDragEnd({
          sourceList: sourceList,
          sourceIndex: sourceIndex,
          sourceArgs: sourceArgs,
          targetList: targetList,
          targetIndex: targetIndex,
          targetArgs: targetArgs
        });
      }
    },
    checkSection: function checkSection() {
      var section = this.get('section');
      var updated = section._edited.title !== section.title;
      var valid = this.get('validation').trimValidation[0].validate(section._edited.title);
      var selectedValues = this.get('selectedValues');
      var isEqual = true;

      if (selectedValues.length < 1) {
        valid = false;
        isEqual = false;
      } else if (section.conditions.length !== selectedValues.length) {
        updated = true;
      } else {
        for (var i = 0; i < section.conditions.length && isEqual; i++) {
          var v = selectedValues.findBy('id', section.conditions[i].id);

          if (!v) {
            isEqual = false;
          }
        }
      }

      if (!isEqual) {
        updated = true;
      }

      this.set('sectionIsUpdated', updated);
      this.set('sectionIsValid', updated && valid);
    }
  });

  _exports.default = _default;
});