define("inboxfront/templates/components/assistants/workflows/add-components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ADj2ZeWM",
    "block": "{\"symbols\":[\"group\",\"component\"],\"statements\":[[4,\"each\",[[24,[\"componentsFlow\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"group-name\"],[8],[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"each\",[[23,1,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[\"alignCenter item-component\",[28,\"if\",[[23,2,[\"disabled\"]],\" disabled\"],null]]]],[11,\"onClick\",[28,\"action\",[[23,0,[]],[23,0,[\"chooseComponent\"]],[23,2,[]]],null]],[8],[0,\"\\n            \"],[7,\"div\",true],[11,\"class\",[29,[\"icon-component \",[23,2,[\"color\"]]]]],[8],[0,\"\\n                \"],[1,[28,\"svg-icon\",[[23,2,[\"icon\"]]],null],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[1,[23,2,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/workflows/add-components.hbs"
    }
  });

  _exports.default = _default;
});