define("inboxfront/routes/workflows/index", ["exports", "inboxfront/routes/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    sessionAccount: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('companyId', this.sessionAccount.loggeduser.staffprofile.estates[0].id);
      controller.set('isReverse', {
        title: true,
        triggered: true,
        engaged: true,
        completed: true,
        updatedDate: true,
        createdDate: true
      });
      controller.queryFlows();
    }
  });

  _exports.default = _default;
});