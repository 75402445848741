define("inboxfront/controllers/workflows/edit", ["exports", "jquery", "inboxfront/config/environment", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _jquery, _environment, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var WORKFLOW = 2;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    workflows: Ember.inject.service(),
    validation: Ember.inject.service(),
    isActiveFlow: false,
    isShowComponent: false,
    isShowListComponents: false,
    isActiveStartBlockFlow: true,
    reRender: true,
    selectedFlowTab: 'settings',
    canvasZoom: 100,
    firstBlock: Ember.computed('blocks.[]', function () {
      return this.get('blocks').findBy('parent.id', null);
    }),
    componentsFlow: Ember.computed('flow.{type,objectType}', 'flow', 'selectedComponent', function () {
      return this.workflows.getComponents(this.get('flow'), this.get('selectedComponent'));
    }),
    conditionModules: Ember.computed('flow.objectType', function () {
      if (this.get('flow.objectType') === 'CONVERSATION') {
        return ['Conversation'];
      } else {
        return ['Ticket/Task'];
      }
    }),
    isDisabledFlowActive: Ember.computed('flow.isValid', function () {
      return !this.get('flow.isValid');
    }),
    changeParamModule: function changeParamModule(flow) {
      var parent = flow.parent;
      var commentModule = this.get('commentModules').findBy('id', 'tickets');
      var triggerRules = this.get('triggerRules');

      if (parent && (parent.blockType === 'TICKET' || parent.blockType === 'TASK' || triggerRules.findBy('selectedColumn', 'Ticket/Task'))) {
        Ember.set(commentModule, 'disabled', false);
      } else {
        if (parent) {
          this.changeParamModule(parent);
        } else {
          Ember.set(commentModule, 'disabled', true);
        }
      }
    },
    changeParamModuleTags: function changeParamModuleTags(flow) {
      var parent = flow.parent;
      var tagModuleTicket = this.get('tagModules').findBy('id', 'tickets');
      var triggerRules = this.get('triggerRules');

      if (parent && (parent.blockType === 'TICKET' || parent.blockType === 'TASK' || triggerRules.findBy('selectedColumn', 'Ticket/Task'))) {
        Ember.set(tagModuleTicket, 'disabled', false);
      } else {
        if (parent) {
          this.changeParamModuleTags(parent);
        } else {
          Ember.set(tagModuleTicket, 'disabled', true);
        }
      }
    },
    resetCommentModule: function resetCommentModule(flow) {
      var triggerRules = this.get('triggerRules');

      if (flow.module && flow.module.id === 'tickets' && triggerRules.findBy('selectedColumn', 'Conversation')) {
        Ember.set(flow, 'module', {});
      }
    },
    resetView: function resetView() {
      this.set('selectedFlowTab', 'settings');
      this.set('isShowComponent', false);
      this.set('isShowTrigger', false);
    },
    actions: {
      showTriggers: function showTriggers() {
        this.triggerOpenStartConfigure();
        this.set('isShowTriggers', true);
      },
      addTrigger: function addTrigger(item) {
        //TODO remove, this part is for chatbots
        if (item.disabled) {
          return;
        }

        this.get('triggers').addObject(item);
        Ember.set(item, 'disabled', true);
        this.triggerOpenStartConfigure();
        this.set('selectedTrigger', item);
        Ember.set(item, 'active', true);
        this.set('isShowTrigger', true);
        this.set('isShowTriggers', false);
      },
      removeTrigger: function removeTrigger(item) {
        //TODO remove, this part is for chatbots
        this.triggerOpenStartConfigure();
        this.set('selectedTrigger', null);
        this.set('isShowTrigger', false);
        this.get('triggers').removeObject(item);
        Ember.set(item, 'disabled', false);
        this.set('isShowTriggers', true);
      },
      backMainPage: function backMainPage() {
        this.transitionToRoute('workflows');
      },
      openTriggerConfigure: function openTriggerConfigure(item, event) {
        this.triggerOpenStartConfigure();
        this.set('selectedTrigger', item);
        this.set('selectedTrigger.active', true);
        this.set('isShowTrigger', true);
        this.set('isShowTriggers', false);
      },
      openStartConfigure: function openStartConfigure() {
        this.triggerOpenStartConfigure();
      },
      activateFlow: function activateFlow() {
        var _this = this;

        var hash = {
          isActive: !this.get('flow.isActive')
        };
        this.set('openActivateFlowDialog', false);
        this.mutationWorkflow(hash).then(function () {
          var status = hash.isActive ? 'activate_workflow' : 'deactivate_workflow';

          _this.set('flow.isActive', hash.isActive);

          _this.paperToaster.show(_this.intl.t(status, {
            name: _this.get('flowName')
          }));
        });
      },
      statusChangedItemFlow: function statusChangedItemFlow(value) {
        var _this2 = this;

        if (this.get('flow.isActive')) {
          this.set('flow.isActive', false);
          var hash = {
            isActive: false
          };
          this.mutationWorkflow(hash).then(function () {
            var status = value ? 'activate_flow' : 'deactivate_flow';

            _this2.paperToaster.show(_this2.intl.t(status, {
              name: _this2.get('flowName')
            }));
          });
        } else {
          // this.set('flow.isActive', false);
          this.set('openActivateFlowDialog', true);
        }
      },
      openConfigure: function openConfigure(component) {
        //if (component.id === this.get('selectedComponent.id')) return;
        this.set('isShowComponent', true);
        this.set('isShowTrigger', false);
        this.openConfigureComponent(component.id);

        if (document.querySelector('.txt-input')) {
          setTimeout(function () {
            document.querySelector('.txt-input').innerHTML = component.mainTitle;
          }, 0);
        }
      },
      deleteComponent: function deleteComponent(component, event) {
        var _this3 = this;

        event.stopPropagation();
        this.workflows.deleteBlock(component, this.get('flow')).then(function () {
          _this3.showStatus();

          if (_this3.get('selectedComponent.id') === component.id) {
            _this3.triggerOpenStartConfigure();
          }
        });
      },
      chooseComponent: function chooseComponent(component) {
        var _this4 = this;

        var selectedComponent = this.get('selectedComponent');
        var hash = {
          flowId: this.get('flowId'),
          mainTitle: '',
          blockType: component.blockType,
          name: component.name,
          icon: component.icon
        };
        this.set('isShowComponent', true);
        this.set('isShowTrigger', false);
        this.set('isShowListComponents', false);
        this.set('isActiveStartBlockFlow', false);
        this.set('isLoadingComponent', true);
        document.querySelector('.canvas-block').scroll(0, 0);
        return this.workflows.addBlock(component, selectedComponent, this.get('flow'), function (block) {
          _this4.showStatus();

          _this4.openConfigureComponent(block.id);
        });
      },
      openListComponents: function openListComponents() {
        var component = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var event = arguments.length > 1 ? arguments[1] : undefined;
        event.stopPropagation();

        if (component) {
          this.set('selectedComponent', component);
        } else {
          this.set('selectedComponent', this.get('flow.triggerBlock'));
        }

        this.set('selectedFlowTab', 'configure');
        this.set('isShowListComponents', true);
        this.set('isActiveStartBlockFlow', false);
        this.set('isShowComponent', false);
        this.set('isShowTrigger', false);
        (0, _jquery.default)('.item-flow, .item-flow-wrap').removeClass('active');
        (0, _jquery.default)('.add-component-flow').removeClass('active');
        (0, _jquery.default)(event.target).closest('.add-component-flow').addClass('active');
      },
      addOption: function addOption() {
        this.updateOptionsComponent(this.get('selectedComponent.id'));
      },
      removeOption: function removeOption(option) {
        var _this5 = this;

        this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
          blockId: option.id
        }).then(function () {
          _this5.mutationFlow();

          _this5.updateFlow(option.parent.id);
        });
      },
      addRule: function addRule() {
        this.get('selectedComponent.rules').pushObject({});
      },
      removeRule: function removeRule(rule) {
        this.get('selectedComponent.rules').removeObject(rule);
        this.updateActionsComponent();
      },
      addAction: function addAction() {
        this.get('selectedComponent.actions').pushObject({});
      },
      removeAction: function removeAction(action) {
        this.get('selectedComponent.actions').removeObject(action);
        this.updateSelectActions(null, action.checkAction);
        this.updateActionsComponent();
      },
      onBlurInputFlow: function onBlurInputFlow(name, val) {
        var hash = _defineProperty({}, name, val);

        if (val && val.length) {
          this.mutationWorkflow(hash);
        }
      },
      showSaveStatus: function showSaveStatus() {
        this.showStatus();
      },
      selectBranch: function selectBranch(component, index, input) {
        if (input !== true && this.get('inputFocused')) {
          return;
        }

        Ember.set(component.children[index], 'active', true);

        if (index === 0) {
          Ember.set(component.children[1], 'active', false);
        } else {
          Ember.set(component.children[0], 'active', false);
        }

        if (input === true) {
          this.set('inputFocused', true);
        }
      },
      deselectBranch: function deselectBranch(component, index, input) {
        if (input !== true && this.get('inputFocused')) {
          return;
        }

        Ember.set(component.children[index], 'active', false);

        if (input === true) {
          this.set('inputFocused', false);
        }
      },
      updateBranchName: function updateBranchName(component, index, value) {
        Ember.set(component.children[index], 'name', value);
        this.saveBlock(component);
      },
      addIfCondition: function addIfCondition(component, module, operator) {
        if (!component.ifConditions) {
          Ember.set(component, 'ifConditions', []);
        }

        var cond = {};

        if (module) {
          cond.selectedModule = module;
        }

        if (typeof operator === 'string') {
          if (component.ifConditions.length > 0) {
            Ember.set(component.ifConditions[component.ifConditions.length - 1], 'operator', operator);
          }
        } else {
          if (component.ifConditions.length > 0) {
            Ember.set(component.ifConditions[component.ifConditions.length - 1], 'operator', 'AND');
          }
        }

        component.ifConditions.addObject(cond);
        Ember.set(component.children[0], 'active', false);
      },
      addThenCondition: function addThenCondition(component, module, operator) {
        if (!component.thenConditions) {
          Ember.set(component, 'thenConditions', []);
        }

        var cond = {};

        if (module) {
          cond.selectedModule = module;
        }

        if (typeof operator === 'string') {
          if (component.thenConditions.length > 0) {
            Ember.set(component.thenConditions[component.thenConditions.length - 1], 'operator', operator);
          }
        } else {
          if (component.thenConditions.length > 0) {
            Ember.set(component.thenConditions[component.thenConditions.length - 1], 'operator', 'AND');
          }
        }

        component.thenConditions.addObject(cond);
        Ember.set(component.children[1], 'active', false);
      },
      onRemoveIfCondition: function onRemoveIfCondition(cond, component) {
        component.ifConditions.removeObject(cond);

        if (component.ifConditions.length === 1) {
          Ember.set(component.ifConditions[0], 'operator', '');
        }

        this.saveIfElseConditions(component);
      },
      onRemoveThenCondition: function onRemoveThenCondition(cond, component) {
        component.thenConditions.removeObject(cond);

        if (component.thenConditions.length === 1) {
          Ember.set(component.thenConditions[0], 'operator', '');
        }

        this.saveIfElseConditions(component);
      },
      onConditionOperator: function onConditionOperator(component, cond, value) {
        Ember.set(cond, 'operator', value);
        this.saveIfElseConditions(component);
      },
      selectIfThenConditionModule: function selectIfThenConditionModule(cond, value) {
        if (cond.selectedModule !== value) {
          Ember.set(cond, 'selectedRule', '');
          Ember.set(cond, 'selectedOption', '');
        }

        Ember.set(cond, 'selectedModule', value);
      },
      selectIfThenConditionRule: function selectIfThenConditionRule(cond, value) {
        if (cond.selectedRule !== value) {
          Ember.set(cond, 'selectedOption', '');
        }

        Ember.set(cond, 'selectedRule', value);
      },
      selectIfThenConditionOption: function selectIfThenConditionOption(component, cond, value) {
        Ember.set(cond, 'selectedOption', value);
        this.saveIfElseConditions(component);
      },
      showPreviewEmailDialog: function showPreviewEmailDialog() {
        this.get('recipientEmail') || this.set('recipientEmail', this.get('sessionAccount.loggeduser.email'));
        this.get('recipientAccount') || this.set('recipientAccount', this.get('selectedComponent.sender'));
        this.set('showPreviewDialog', true);
      },
      sendEmailPreview: function sendEmailPreview() {
        var _this6 = this;

        var workflowsService = this.get('workflows');
        var hash = {
          accountId: this.get('recipientAccount.pk'),
          to: this.get('recipientEmail')
        };
        var body = this.get('selectedComponent.body');
        var subject = this.get('selectedComponent.subject');
        hash.body = workflowsService.clearVariables(body);
        hash.subject = workflowsService.clearVariables(subject);
        this.ajax.mutation(_mutation.default.SEND_TEST_EMAIL, 'sendTestEmail', hash, function () {
          _this6.paperToaster.show('Email sent, check your mailbox');
        });
      },
      selectTriggerColumn: function selectTriggerColumn(rule, value) {
        var options = [];

        switch (value) {
          case 'Ticket/Task':
            options = ['Is created', 'Is updated', 'Is deleted', 'Is idled'];
            break;

          case 'Conversation':
            options = ['New incoming', 'Is idled', 'Visitor has responded'];
        }

        if (rule.selecteColumn !== value) {
          Ember.set(rule, 'selectedColumn', value);
          Ember.set(rule, 'selectedCondition', '');
          Ember.set(rule, 'ruleOptions', options);
        }
      },
      selectTriggerConditionRule: function selectTriggerConditionRule(rule, value) {
        Ember.set(rule, 'selectedCondition', value);

        if (value !== 'Is idled') {
          this.saveTriggerMutation();
        }
      },
      selectTriggerPeriodTimes: function selectTriggerPeriodTimes(rule, value) {
        Ember.set(rule, 'idledPeriodTimes', value);
        this.saveTriggerMutation();
      },
      saveTrigger: function saveTrigger() {
        this.saveTriggerMutation();
      },
      onAddTriggerRule: function onAddTriggerRule(module, operator) {
        var variables = this.get('triggerOptions.variables');
        var selectedColumn = typeof module === 'string' ? module : '';
        var ruleOptions = [];
        var rules = this.get('triggerRules');

        if (this.get('triggerRules.length') > 0) {
          var firstRuleColumn = this.get('triggerRules.0.selectedColumn');
          variables = [firstRuleColumn];
          selectedColumn = firstRuleColumn;
          ruleOptions = this.get('triggerRules.0.ruleOptions');
          this.set('triggerRules.0.variables', variables);
        }

        switch (selectedColumn) {
          case 'Ticket/Task':
            ruleOptions = ['Is created', 'Is updated', 'Is deleted', 'Is idled'];
            break;

          case 'Conversation':
            ruleOptions = ['New incoming', 'Is idled', 'Visitor has responded'];
        }

        if (typeof operator === 'string') {
          Ember.set(rules[rules.length - 1], 'operator', operator);
        }

        rules.addObject({
          selectedColumn: selectedColumn,
          selectedCondition: '',
          idledPeriod: 0,
          idledPeriodTimes: 'minutes',
          variables: variables,
          operator: '',
          ruleOptions: ruleOptions
        });
      },
      onRemoveTriggerRule: function onRemoveTriggerRule(rule) {
        var rules = this.get('triggerRules');
        rules.removeObject(rule);

        if (rules.length === 1) {
          this.set('triggerRules.0.variables', this.get('triggerOptions.variables'));
        }

        if (rules.length > 0) {
          Ember.set(rules[rules.length - 1], 'operator', '');
        }

        this.saveTriggerMutation();
      },
      saveCursorPosition: function saveCursorPosition() {
        var input = (0, _jquery.default)('.email-subj-input > input');
        var start = input.prop('selectionStart');
        var end = input.prop('selectionEnd');

        if (start || start == '0') {
          this.set('cursor', {
            start: start,
            end: end
          });
        } else {
          this.set('cursor', {
            start: -1,
            end: -1
          });
        }
      },
      addVariable: function addVariable(variable) {
        var input = (0, _jquery.default)('.email-subj-input > input');
        var cursor = this.get('cursor');

        if (!cursor || cursor.start < 0) {
          input.val(input.val() + '{{' + variable + '}}');
        } else {
          var value = input.val();
          var before = value.substring(0, cursor.start);
          var after = value.substring(cursor.end);
          input.val(before + ' {{' + variable + '}} ' + after);
        }

        this.set('selectedComponent.subject', input.val());
        this.set('selectedComponent.mainTitle', input.val());
        input.focus();
        this.saveBlock(this.get('selectedComponent'));
      },
      addBodyVariable: function addBodyVariable(variable) {
        this.set('selectedComponent.body', this.get('selectedComponent.body') + '{{' + variable + '}}');
        this.saveBlock(this.get('selectedComponent'));
      },
      addToVariable: function addToVariable(variable) {
        this.set('selectedComponent.to', '{{' + variable + '}}');
        this.saveBlock(this.get('selectedComponent'));
      },
      updateEmailParams: function updateEmailParams(component, name, value) {
        if (name === 'subject') {
          var parts = value.split('{{');
          var subjVariables = this.get('subjVariables');
          parts.forEach(function (p, i) {
            if (p.indexOf('}}') === p.length - 2) {
              if (subjVariables.indexOf(p.substring(0, p.length - 2)) < 0) {
                parts[i] = '';
              }
            } else {
              if (p.indexOf('{') >= 0) {
                var tmp = p.split('{');
                parts[i] = tmp.join('');
              }

              if (parts[i].indexOf('}') >= 0) {
                var _tmp = parts[i].split('}');

                parts[i] = _tmp.join('');
              }
            }
          });
          Ember.set(component, 'mainTitle', value);
        }

        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateTargetChatParams: function updateTargetChatParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'description') {
          Ember.set(component, 'mainTitle', value);
        }

        if (name === 'users' || name === 'rooms') {
          Ember.set(component, 'recipients', value);
        }

        if (name === 'model') {
          var modelValue = value.id === 'users' ? this.get('selectedComponent.users') : this.get('selectedComponent.rooms');
          Ember.set(component, 'recipients', modelValue);
        }

        this.saveBlock(component);
      },
      updateAddCommentParams: function updateAddCommentParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'comment') {
          Ember.set(component, 'mainTitle', value);
        }

        this.saveBlock(component);
      },
      updateTicketParams: function updateTicketParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'subject') {
          Ember.set(component, 'mainTitle', value);
        }

        if (name === 'department') {
          this.updateDepartmentUsers(value.id);
        }

        this.saveBlock(component);
      },
      removeTagItem: function removeTagItem(component, tag) {
        if (this.get('flow.isActive')) {
          return;
        }

        component.tags && component.tags.removeObject(tag);
        this.saveBlock(component);
      },
      addTagToTicket: function addTagToTicket(component) {
        var tag = this.get('newTag');

        if (tag) {
          if (!component.tags) {
            Ember.set(component, 'tags', []);
          }

          component.tags.addObject(tag);
          this.saveBlock(component);
        }
      },
      addTagToBlock: function addTagToBlock(component, tag) {
        if (component.tag_list !== undefined) {
          component.tag_list.pushObject(tag);
        } else {
          Ember.set(component, 'tag_list', []);
          component.tag_list.pushObject(tag);
        }

        this.saveBlock(component);
      },
      removeTag: function removeTag(component, tagIndex) {
        component.tag_list.removeAt(tagIndex);
        this.saveBlock(component);
      },
      updateContactParams: function updateContactParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateTagParams: function updateTagParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateStatusParams: function updateStatusParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateDueDateParams: function updateDueDateParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      onBlurInputMessage: function onBlurInputMessage(blockId, mainTitle) {
        var block = this.get('blocks').findBy('id', blockId);
        Ember.set(block, 'mainTitle', mainTitle);

        if (block.blockType === 'IFELSE') {
          Ember.set(block, 'description', mainTitle);
        }

        this.saveBlock(block);
      },
      updateDelayParams: function updateDelayParams(block, name, value) {
        var _this7 = this;

        Ember.set(block.extraData, name, value);
        var hash = {
          flowId: this.get('flowId'),
          extraData: JSON.stringify(block.extraData),
          blockId: block.id
        };
        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
          _this7.showStatus();

          _this7.mutationFlow();
        });
      }
    },
    saveIfElseConditions: function saveIfElseConditions(component) {
      this.saveBlock(component);
    },
    updateDepartmentUsers: function updateDepartmentUsers(departmentId) {
      var _this8 = this;

      var propertyId = this.get('flow.properties.0.id');

      if (!propertyId || !departmentId) {
        return;
      }

      var hash = {
        departments: [departmentId],
        propertyIds: [propertyId]
      };
      this.ajax.query(_query.default.HAVE_TASK_ACCESS, 'haveAccess', hash).then(function (result) {
        _this8.set('departmentUsers', result);
      });
    },
    saveBlock: function saveBlock(block) {
      var _this9 = this;

      if (this.get('saveTimer')) clearTimeout(this.get('saveTimer'));
      this.set('saveTimer', setTimeout(function () {
        _this9.get('workflows').saveBlock(block, _this9.get('flow'), function () {
          _this9.showStatus();
        });
      }), 1000);
    },
    updateSelectActions: function updateSelectActions(action, checkAction) {
      var actionsFlow = this.get('selectedComponent.actionsFlow');
      actionsFlow.removeObject(action);
      checkAction && actionsFlow.addObject(checkAction);
    },
    saveTriggerMutation: function saveTriggerMutation() {
      var triggerRules = this.get('triggerRules');
      var conditions = {};
      var any = [];
      var all = [];
      var objectType;
      triggerRules.forEach(function (r, index) {
        var rule = {};

        if (r.selectedColumn === 'Ticket/Task') {
          objectType = 'TICKET';
          rule.name = 'age';

          switch (r.selectedCondition) {
            case 'Is created':
              rule.operator = 'equal_to';
              rule.value = 'created';
              break;

            case 'Is updated':
              rule.operator = 'equal_to';
              rule.value = 'updated';
              break;

            case 'Is deleted':
              rule.operator = 'equal_to';
              rule.value = 'deleted';
              break;

            case 'Is idled':
              rule.name = 'idled';
              rule.operator = 'greater_than';
              rule.value = Math.floor(r.idledPeriod) * (r.idledPeriodTimes === 'days' ? 3600 : r.idledPeriodTimes === 'hours' ? 60 : 1);
          }
        } else if (r.selectedColumn === 'Conversation') {
          objectType = 'CONVERSATION';

          switch (r.selectedCondition) {
            case 'New incoming':
              rule.name = 'is_new_incomming';
              rule.operator = 'is_true';
              break;

            case 'Visitor has responded':
              rule.name = 'visitor_has_responded';
              rule.operator = 'is_true';
              break;

            case 'Is idled':
              rule.name = 'idled';
              rule.operator = 'greater_than';
              rule.value = Math.floor(r.idledPeriod) * (r.idledPeriodTimes === 'days' ? 3600 : r.idledPeriodTimes === 'hours' ? 60 : 1);
              rule.module = 'Conversation';
          }
        }

        if (triggerRules[index - 1]) {
          if (triggerRules[index - 1].operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        } else {
          if (r.operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        }
      });

      if (any.length) {
        conditions.any = any;
      }

      if (all.length) {
        conditions.all = all;
      }

      Ember.set(this.get('flow.trigger'), 'conditions', conditions);
      this.get('workflows').updateTrigger(this.get('flow'), this.get('flow.objectType') !== objectType ? objectType : '');
    },
    triggerOpenStartConfigure: function triggerOpenStartConfigure() {
      (0, _jquery.default)('.item-flow').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      (0, _jquery.default)('.item-flow-wrap').addClass('active');
      this.set('isShowComponent', false);
      this.set('isShowTrigger', false);
      var curTrigger = this.get('selectedTrigger');
      curTrigger && Ember.set(curTrigger, 'active', false);
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', true);
      this.set('selectedFlowTab', 'configure');
    },
    mouseDownHandler: function mouseDownHandler(e) {
      if (this.get('isCopyState') && e.button === 2) {
        e.preventDefault();
        e.stopPropagation();
        this.set('isCopyState', false);
        this.get('blocks').removeObject(this.get('copyBlock'));
        this.set('copyBlock', null);
        (0, _jquery.default)('.canvas-block').off('mousemove');
        return;
      }

      var ele = document.querySelector('.canvas-block');
      var position = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        x: e.clientX,
        y: e.clientY
      };
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';

      function move(e) {
        var dx = e.clientX - position.x;
        var dy = e.clientY - position.y;
        ele.scrollTop = position.top - dy;
        ele.scrollLeft = position.left - dx;
      }

      function up() {
        (0, _jquery.default)('.canvas-block').off('mousemove', move);
        (0, _jquery.default)('.canvas-block').off('mouseup');
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
      }

      (0, _jquery.default)('.canvas-block').on('mousemove', move);
      (0, _jquery.default)('.canvas-block').on('mouseup', up);
    },
    dragCanvas: function dragCanvas() {
      var _this10 = this;

      (0, _jquery.default)('.canvas-block').on('mousedown', function (e) {
        return _this10.mouseDownHandler(e);
      });
    },
    openConfigureComponent: function openConfigureComponent(id) {
      var _this11 = this;

      var blocks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var newBlocks = blocks || this.get('blocks');
      var prevActiveBlock = newBlocks.findBy('id', this.get('selectedComponent.id'));
      var activeBlock = newBlocks.findBy('id', id);
      this.set('cursor', {});
      (0, _jquery.default)('.item-flow-wrap').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      prevActiveBlock && Ember.set(prevActiveBlock, 'active', false);
      var curTrigger = this.get('selectedTrigger');
      curTrigger && Ember.set(curTrigger, 'active', false);
      this.set('selectedFlowTab', 'configure');
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', false);

      if (activeBlock) {
        if (activeBlock.blockType === 'COMMENT') {
          this.changeParamModule(activeBlock);
          this.resetCommentModule(activeBlock);
        } else if (activeBlock.blockType === 'TAG') {
          this.changeParamModuleTags(activeBlock);
          this.resetCommentModule(activeBlock);
        }

        Ember.set(activeBlock, 'active', true);
        this.set('selectedComponent', activeBlock);
      } else {
        this.set('selectedComponent', null);
        this.set('isActiveStartBlockFlow', true);
        this.triggerOpenStartConfigure();
      }

      this.set('isLoadingComponent', false);
      setTimeout(function () {
        var activeElement = document.querySelector('.item-flow.active');

        if (document.getElementById('editor')) {
          document.getElementById('editor').innerHTML = activeBlock.mainTitle;
        }

        _this11.set('reRender', true); // activeElement && activeElement.scrollIntoView({ behavior: 'smooth' });

      }, 500);
    },
    showStatus: function showStatus() {
      var _this12 = this;

      this.set('showStatusSave', true);
      setTimeout(function () {
        _this12.set('showStatusSave', false);
      }, 2000);
    },
    mutationWorkflow: function mutationWorkflow() {
      var _this13 = this;

      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (hash.title) {
        hash.name = hash.title;
      }

      var data = _objectSpread(_objectSpread({}, hash), {}, {
        workflowId: this.get('flow.id')
      });

      return this.ajax.mutation(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', data).then(function () {
        _this13.showStatus();
      });
    },
    deserializeFlow: function deserializeFlow(bl) {
      var blocks = [];
      bl.forEach(function (b) {
        if (b.parent) {
          var p = bl.findBy('id', b.parent.id);

          if (p) {
            b.parent = p;
          }
        }

        var children = [];
        b.children.forEach(function (c) {
          c = bl.findBy('id', c.id);

          if (c) {
            children.addObject(c);
          }
        });
        b.children = children.sortBy('id');

        try {
          if (b.blockType === 'TEXT' && b.extraData) {
            b.extraData = JSON.parse(b.extraData);
          }
        } catch (e) {
          console.error(e);
          b.extraData = null;
        }

        blocks.addObject(b);
      });
      return blocks;
    }
  });

  _exports.default = _default;
});