define("inboxfront/routes/import", ["exports", "inboxfront/routes/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    customfields: Ember.inject.service(),
    contacts: Ember.inject.service(),
    queryParams: {
      tab: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var contactFields = [//variants - header's values for automapping
      {
        label: 'First Name',
        variants: ['first name', 'firstname', 'fname'],
        id: 'first_name',
        required: true
      }, {
        label: 'Last Name',
        variants: ['last name', 'lastname', 'lname', 'surname', 'family name'],
        id: 'last_name',
        required: true
      }, {
        label: 'Email',
        variants: ['email', 'email address'],
        id: 'email',
        required: true
      }, {
        label: 'Phone Number',
        variants: ['phone', 'telephone', 'telephone number', 'phone number'],
        id: 'personal_phone'
      }, {
        label: 'Mobile Number',
        variants: ['mobile', 'mobile number'],
        id: 'mobile_phone'
      }, {
        label: 'Postal Code',
        variants: ['postal code', 'postcode'],
        id: 'postalcode'
      }, {
        label: 'State',
        variants: ['state', 'province'],
        id: 'state'
      }, {
        label: 'Country',
        variants: [],
        id: 'country'
      }, {
        label: 'City',
        variants: [],
        id: 'city'
      }, {
        label: 'Address 1',
        variants: [],
        id: 'line_1'
      }, {
        label: 'Organisation',
        variants: [],
        id: 'organization'
      }, {
        label: 'Office Address 1',
        variants: [],
        id: 'office_line_1'
      }, {
        label: 'Office Address 2',
        variants: [],
        id: 'office_line_2'
      }, {
        label: 'Home Address 1',
        variants: [],
        id: 'home_line_1'
      }, {
        label: 'Home Address 2',
        variants: [],
        id: 'home_line_2'
      }, {
        label: 'Date of Birth',
        variants: [],
        id: 'dob'
      }];
      var organizationFields = [//variants - header's values for automapping
      {
        label: 'Organisation Name',
        variants: ['name', 'company name', 'organization name', 'organisation name'],
        id: 'name',
        required: true
      }, {
        label: 'Phone Number',
        variants: ['phone', 'telephone', 'telephone number', 'phone number'],
        id: 'phone'
      }, {
        label: 'Fax Number',
        variants: ['fax'],
        id: 'fax'
      }, {
        label: 'Email',
        variants: ['email', 'email address'],
        id: 'email'
      }, {
        label: 'Website',
        variants: [],
        id: 'website'
      }, {
        label: 'Industry',
        variants: [],
        id: 'industry'
      }, {
        label: 'Postal Code',
        variants: ['postal code', 'postcode'],
        id: 'postalcode'
      }, {
        label: 'State',
        variants: ['state', 'province'],
        id: 'state'
      }, {
        label: 'Country',
        variants: [],
        id: 'country'
      }, {
        label: 'City',
        variants: [],
        id: 'city'
      }, {
        label: 'Office Address 1',
        variants: [],
        id: 'office_line_1'
      }, {
        label: 'Office Address 2',
        variants: [],
        id: 'office_line_2'
      }];
      var moduleName = params.tab;
      return Ember.RSVP.all([this.contacts.getContactFields(), this.contacts.getOrganizationFields()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            contactCustomFields = _ref2[0],
            orgCustomFields = _ref2[1];

        contactCustomFields.forEach(function (f) {
          contactFields.pushObject({
            label: f.label,
            variants: [],
            id: f.id
          });
        });
        orgCustomFields.forEach(function (f) {
          organizationFields.pushObject({
            label: f.label,
            variants: [],
            id: f.id
          });
        });
        return {
          guestSettings: contactCustomFields.settings,
          organizationSettings: orgCustomFields.settings,
          contactFields: contactFields,
          organizationFields: organizationFields,
          moduleName: moduleName
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('dateFormats', ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD']);
      controller.set('importHistory', []);
      controller.set('fieldTypes', this.customfields.types);
      controller.set('properties', this.sessionAccount.loggeduser.properties);
      controller.updateImportData();
    }
  });

  _exports.default = _default;
});