define("inboxfront/components/assistants/flows/add-triggers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chatbots: Ember.inject.service(),
    tagName: '',
    isOpen: Ember.computed('expanded', function () {
      return this.get('expanded');
    })
  });

  _exports.default = _default;
});