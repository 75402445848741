define("inboxfront/components/helpdesk/task-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    tasks: Ember.inject.service(),
    tagName: '',
    statusTaskColumns: Ember.computed('tasks', function () {
      return [{
        title: this.intl.t('status.open'),
        type: 'OPEN',
        data: this.get('openTasks'),
        isBtnCreate: true
      }, {
        title: this.intl.t('status.in_progress'),
        type: 'IN_PROGRESS',
        data: this.get('inProgressTasks'),
        isBtnCreate: true
      }, {
        title: this.intl.t('status.resolved'),
        type: 'RESOLVED',
        data: this.get('resolvedTasks'),
        isBtnCreate: false
      }, {
        title: this.intl.t('status.closed'),
        type: 'CLOSED',
        data: this.get('closedTasks'),
        isBtnCreate: false
      }, {
        title: this.intl.t('status.deleted'),
        type: 'DELETED',
        data: this.get('deletedTasks'),
        isBtnCreate: false
      }];
    }),
    actions: {
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            sourceArgs = _ref.sourceArgs,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex,
            targetArgs = _ref.targetArgs;

        if (sourceList === targetList) {
          return;
        }

        var task = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, task);
        Ember.set(task, 'status', targetList.status);
        Ember.set(task, '_parentList', targetList);
        this.onUpdateStatus(task);
      },
      checkTask: function checkTask(task) {
        this.onSelectTask(task);
      },
      selectWatcher: function selectWatcher(task) {
        this.onSelectWatcher(task);
      }
    }
  });

  _exports.default = _default;
});