define("inboxfront/routes/profile", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "inboxfront/config/environment", "inboxfront/gql/query"], function (_exports, _authenticatedRouteMixin, _environment, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      var locales = this.ajax.query(_query.default.LOCALES);
      return Ember.RSVP.all([locales]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            locales = _ref2[0];

        return {
          locales: locales
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var profile = this.get('sessionAccount.loggeduser');
      var attachment = profile.attachment;

      if (attachment) {
        controller.set('urlAttachment', "".concat(_environment.default.mediaUrl).concat(attachment.datafile));
        controller.set('attachmentId', attachment.id);
      }

      controller.set('locales', JSON.parse(model.locales));
      controller.set('profile', profile);
      controller.set('cloneProfile', Ember.copy(profile, true));
      controller.set('selectedLocale', controller.get('locales').find(function (_ref3) {
        var code = _ref3.code;
        return code === profile.staffprofile.language;
      }));
    }
  });

  _exports.default = _default;
});