define("inboxfront/components/integrations/channel-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    channelName: Ember.computed('selectedProperty', 'name', function () {
      var acc = this._findAccount(this.get('name'));

      if (acc) {
        return acc.name;
      }

      return '';
    }),
    _findAccount: function _findAccount(application) {
      var _this$get, _this$get$accounts, _this$get$accounts$ed;

      var ret = null;
      (_this$get = this.get('selectedProperty')) === null || _this$get === void 0 ? void 0 : (_this$get$accounts = _this$get.accounts) === null || _this$get$accounts === void 0 ? void 0 : (_this$get$accounts$ed = _this$get$accounts.edges) === null || _this$get$accounts$ed === void 0 ? void 0 : _this$get$accounts$ed.forEach(function (e) {
        if (e.node.application === application) {
          ret = e.node;
        }
      });
      return ret;
    }
  });

  _exports.default = _default;
});