define("inboxfront/components/assistants/flows/add-components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chatbots: Ember.inject.service(),
    tagName: '',
    actions: {
      onDragStart: function onDragStart(component, event) {
        event.dataTransfer.setData('component', JSON.stringify(component));
      }
    }
  });

  _exports.default = _default;
});