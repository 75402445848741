define("inboxfront/controllers/organizations/index", ["exports", "inboxfront/helpers/countries-list", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _countriesList, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    validation: Ember.inject.service(),
    sliceData: Ember.inject.service(),
    countriesList: _countriesList.COUNTRIES_LIST,
    chosenDetail: 'Contains',
    optionDate: Date,
    startDate: Date,
    endDate: Date,
    showDeleteDialog: false,
    showMergeConfirmDialog: false,
    allCheckOrganizations: Ember.computed('organizations.{@each.checked}', function () {
      return this.get('organizations').every(function (org) {
        return org.checked;
      });
    }),
    isVisibleActions: Ember.computed('organizations.{@each.checked}', function () {
      return this.get('organizations').some(function (org) {
        return org.checked;
      });
    }),
    isVisibleMergeButton: Ember.computed('organizations.{@each.checked}', function () {
      var _this$get$filter;

      return ((_this$get$filter = this.get('organizations').filter(function (org) {
        return org.checked;
      })) === null || _this$get$filter === void 0 ? void 0 : _this$get$filter.length) === 2;
    }),
    isDisabledBtnForm: Ember.computed('organization.{name}', function () {
      return !!this.get('organization.name');
    }),
    observerPage: Ember.observer('pageNum', function () {
      if (this.get('cloneOrganizations')) {
        this.set('organizations', this.get('sliceData').getSliceData(this.get('cloneOrganizations'), this.get('pageNum')));
      }
    }),
    actions: {
      updateColumns: function updateColumns(columnSettings) {
        try {
          var hash = {
            id: this.get('model.settings.id'),
            fields: JSON.stringify(columnSettings)
          };
          this.ajax.mutation(_mutation.default.UPDATE_CUSTOM_SETTINGS, 'updateMainEntitySettings', hash);
        } catch (e) {
          console.error('ON UPDATE ORG COLUMNS', e);
        }
      },
      allCheckOrganizations: function allCheckOrganizations(val) {
        this.set('allCheck', val);
        this.get('organizations').map(function (el) {
          return Ember.set(el, 'checked', val);
        });
      },
      checkOrganization: function checkOrganization(val) {
        Ember.set(val, 'checked', !val.checked);
      },
      showDeleteOrganizationModal: function showDeleteOrganizationModal() {
        this.set('showDeleteDialog', true);
      },
      showAssignOrganizationSidebar: function showAssignOrganizationSidebar() {
        this.set('showPopupAssign', true);
      },
      mergeOrganizationsConfirm: function mergeOrganizationsConfirm() {
        var _this = this;

        var _this$get$map = this.get('selectedOrganizations').map(function (item) {
          return item.id;
        }),
            _this$get$map2 = _slicedToArray(_this$get$map, 2),
            secondaryOrganizationId = _this$get$map2[0],
            primaryOrganizationId = _this$get$map2[1];

        var _this$get = this.get('mergeHeaderOrganization'),
            name = _this$get.name,
            fax = _this$get.fax,
            phone = _this$get.phone,
            industry = _this$get.industry,
            website = _this$get.website,
            address = _this$get.address;

        var hash = {
          secondaryOrganizationId: secondaryOrganizationId,
          primaryOrganizationId: primaryOrganizationId,
          name: name,
          fax: fax,
          phone: phone,
          industry: industry,
          website: website,
          homeAddress1: address.line1,
          homeAddress2: address.line2,
          homeCity: address.city,
          homeState: address.state,
          homeCountry: address.country
        };
        Object.keys(hash).forEach(function (key) {
          return (hash[key] === null || hash[key] === undefined) && delete hash[key];
        });
        console.log('hash', hash);
        this.ajax.query(_mutation.default.MERGE_ORGANIZATIONS, 'mergeOrganizations', hash).then(function () {
          _this.set('showPopupMerge', false);

          _this.set('selectedOrganizations', []);

          _this.set('mergeHeaderOrganization', null);

          _this.set('showMergeConfirmDialog', false);

          _this.updateQueryOrganizations();
        });
      },
      showMergeOrganizationSidebar: function showMergeOrganizationSidebar() {
        var titleMergeOrganizations = this.get('titleMergeOrganizations');
        var selectedOrganizations = this.get('organizations').filter(function (el) {
          return el.checked;
        });
        var matrixArrayOrganizations = [];
        titleMergeOrganizations.forEach(function (item, i) {
          matrixArrayOrganizations.push([]);

          for (var j = 0; j < 4; j++) {
            if (j !== 0) {
              var index = j === 1 || j === 3 ? 0 : 1;

              if (item.isSocial) {
                var _selectedOrganization;

                var findOrg = (_selectedOrganization = selectedOrganizations[index]['guestcontactSet'].findBy('contactType', item.key)) === null || _selectedOrganization === void 0 ? void 0 : _selectedOrganization.contactValue;
                matrixArrayOrganizations[i].push(findOrg || '-');
              } else {
                var result = selectedOrganizations[index];
                var keys = item.key.split('.');

                var _iterator = _createForOfIteratorHelper(keys),
                    _step;

                try {
                  for (_iterator.s(); !(_step = _iterator.n()).done;) {
                    var key = _step.value;
                    result = result[key];
                  }
                } catch (err) {
                  _iterator.e(err);
                } finally {
                  _iterator.f();
                }

                matrixArrayOrganizations[i].push(result || '-');
              }
            } else {
              matrixArrayOrganizations[i].push(item.title);
            }
          }
        });
        this.set('matrixArrayOrganizations', matrixArrayOrganizations);
        this.set('selectedOrganizations', selectedOrganizations);
        this.set('mergeHeaderOrganization', Ember.copy(selectedOrganizations[0], true));
        this.set('showPopupMerge', true);
        setTimeout(function () {
          document.querySelectorAll('.merge-guest-popup tbody tr td:nth-child(2) div').forEach(function (el) {
            el.classList.add('active');
          });
        }, 300);
      },
      assignOrganizations: function assignOrganizations() {
        var _this2 = this;

        var filterContacts = this.get('organizations').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        var hash = {
          ids: filterContacts,
          contactOwnerId: this.get('selectedUser.id')
        };
        this.ajax.query(_mutation.default.BULK_ORGANIZATION_OWNER, 'bulkOrganizationOwner', hash).then(function () {
          _this2.set('showPopupAssign', false);

          _this2.set('selectedUser', undefined);

          _this2.updateQueryOrganizations();
        });
      },
      mergeOrganizations: function mergeOrganizations() {
        this.set('showMergeConfirmDialog', true);
      },
      deleteOrganizations: function deleteOrganizations() {
        var _this3 = this;

        var filterMapping = this.get('organizations').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        this.ajax.query(_mutation.default.DELETE_ORGANIZATION, 'deleteOrganization', {
          organizationIds: filterMapping
        }).then(function (res) {
          if (res.status === 400) {
            _this3.get('paperToaster').show(_this3.intl.t('org_not_found'));

            return;
          }

          _this3.get('paperToaster').show(_this3.intl.t('orgs_deleted'));

          _this3.updateQueryOrganizations();
        }).finally(function () {
          return _this3.set('showDeleteDialog', false);
        });
      },
      chooseOrganization: function chooseOrganization(val, indexProp, rowIndex) {
        var mappingOrganizations = this.get('matrixArrayOrganizations').map(function (row, i) {
          if (i === rowIndex) {
            return row.map(function (item, i) {
              return i === 3 ? val : item;
            });
          } else {
            return row;
          }
        });

        if (rowIndex === 0) {
          this.set('mergeHeaderOrganization.name', val);
        }

        var previousIndex = indexProp === 2 ? 1 : 2;
        this.set('matrixArrayOrganizations', mappingOrganizations);
        setTimeout(function () {
          var els = document.querySelectorAll(".merge-guest-popup tbody tr:nth-child(".concat(rowIndex + 1, ") td div"));
          els[indexProp].classList.add('active');
          els[previousIndex].classList.remove('active');
        }, 0);
      },
      closeAssignDialog: function closeAssignDialog() {
        this.set('selectedUser', undefined);
        this.set('showPopupAssign', false);
      },
      closeMergeDialog: function closeMergeDialog() {
        this.set('selectedOrganizations', []);
        this.set('mergeHeaderOrganization', null);
        this.set('showPopupMerge', false);
      },
      saveOrganization: function saveOrganization() {
        var _this4 = this;

        var isOpen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var _this$get2 = this.get('organization'),
            name = _this$get2.name,
            phone = _this$get2.phone,
            fax = _this$get2.fax,
            line1 = _this$get2.line1,
            line2 = _this$get2.line2,
            zipOrPostcode = _this$get2.zipOrPostcode,
            state = _this$get2.state,
            industry = _this$get2.industry,
            website = _this$get2.website,
            property = _this$get2.property,
            contactOwner = _this$get2.contactOwner,
            country = _this$get2.country;

        var hash = {
          name: name,
          phone: phone,
          line1: line1,
          line2: line2,
          zipOrPostcode: zipOrPostcode,
          state: state,
          industry: industry,
          website: website,
          fax: fax,
          propertyId: (property === null || property === void 0 ? void 0 : property.id) || null,
          contactOwnerId: (contactOwner === null || contactOwner === void 0 ? void 0 : contactOwner.id) || null,
          country: (country === null || country === void 0 ? void 0 : country.name) || null
        };
        Object.keys(hash).forEach(function (key) {
          if (hash[key] === undefined) hash[key] = '';
        });
        this.ajax.mutation(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash, function () {
          _this4.clearForm();

          _this4.get('paperToaster').show(_this4.intl.t('org_created'));

          _this4.updateQueryOrganizations(isOpen);
        });
      },
      clearFilter: function clearFilter() {
        var page = this.get('page');
        var limit = this.get('limit');
        var organizationPage = this.get('cloneOrganizations').slice(page * limit, (page + 1) * limit);
        this.set('searchOrganizations', null);
        this.set('isShowClearFilterBtn', false);
        this.set('organizations', organizationPage);
      },
      filterOrganizations: function filterOrganizations() {
        var _this5 = this;

        var search = this.get('searchOrganizations');

        if (!search.length) {
          this.set('organizations', this.get('cloneOrganizations'));
          return;
        }

        if (search.length > 2) {
          var hash = {
            search: [{
              field: this.get('selectedFilterField.param'),
              lookup: this.get('selectedFilterLookup.param'),
              value: search
            }]
          };
          this.set('isLoader', true);
          this.set('isShowClearFilterBtn', true);
          this.ajax.query(_query.default.ORGANIZATIONS, 'organizations', hash).then(function (data) {
            _this5.set('organizations', data);

            _this5.set('isLoader', false);
          });
        }
      },
      showAddOrganization: function showAddOrganization() {
        var _this6 = this;

        this.set('showAddOrganization', true);
        this.ajax.query(_query.default.PROPERTY_SELECT).then(function (data) {
          _this6.set('properties', data);
        });
      },
      showFilterContact: function showFilterContact() {
        this.set('filterPopup', true);
        this.set('isFilterInserted', true);
        this.set('showFilterDialog', true);
      },
      closeContactDialog: function closeContactDialog() {
        this.set('filterPopup', false);
        this.clearForm();
      }
    },
    updateQueryOrganizations: function updateQueryOrganizations() {
      var _this7 = this;

      var isOpen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.set('isLoader', true);
      this.ajax.query(_query.default.ORGANIZATIONS).then(function (data) {
        _this7.set('cloneOrganizations', data);

        _this7.observerPage();

        isOpen && _this7.set('showAddOrganization', true);

        _this7.set('isLoader', false);
      });
    },
    clearForm: function clearForm() {
      this.set('showAddOrganization', false);
      this.set('organization', {});
    }
  });

  _exports.default = _default;
});