define("inboxfront/templates/components/assistants/flows/new-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a0VpoFf/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"canvas\",true],[10,\"id\",\"canvas_flows\"],[10,\"style\",\"border: solid 0 red\"],[11,\"ondragenter\",[28,\"action\",[[23,0,[]],\"onDragEnter\"],null]],[11,\"ondragover\",[28,\"action\",[[23,0,[]],\"onDragOver\"],null]],[11,\"ondrop\",[28,\"action\",[[23,0,[]],\"onDrop\"],null]],[11,\"ondragend\",[28,\"action\",[[23,0,[]],\"onDragEnd\"],null]],[11,\"onmousewheel\",[28,\"action\",[[23,0,[]],\"OnMouseWheel\"],null]],[8],[0,\"\\n    Canvas not supported\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/flows/new-canvas.hbs"
    }
  });

  _exports.default = _default;
});