define("inboxfront/templates/components/compact-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dpUQPP5r",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"side-menu\"],[8],[0,\"\\n\"],[4,\"ember-scrollable\",null,null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"menu-item\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"nav-item\",\"dashboard\"]],{\"statements\":[[0,\"                \"],[7,\"img\",true],[10,\"src\",\"/assets/images/main-logo.svg\"],[10,\"alt\",\"main\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"menuItems\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[11,\"class\",[29,[\"menu-item \",[28,\"if\",[[28,\"or\",[[28,\"eq\",[[24,[\"currentLink\"]],[23,1,[\"link\"]]],null],[28,\"and\",[[28,\"eq\",[[23,1,[\"link\"]],\"flows\"],null],[24,[\"isActiveFlowsMenu\"]]],null],[28,\"and\",[[28,\"eq\",[[23,1,[\"link\"]],\"contacts\"],null],[24,[\"isActiveContactsMenu\"]]],null],[28,\"and\",[[28,\"eq\",[[23,1,[\"link\"]],\"settings\"],null],[24,[\"isActiveSettingsMenu\"]]],null]],null],\"active\",\"\"],null]]]],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[[23,1,[\"link\"]]]],{\"statements\":[[0,\"                    \"],[1,[28,\"svg-icon\",[[28,\"concat\",[\"menu-\",[23,1,[\"link\"]]],null]],[[\"class\"],[\"menu-icon\"]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"gt\",[[24,[\"conversationCount\"]],0],null],[28,\"eq\",[[23,1,[\"link\"]],\"chats\"],null]],null]],null,{\"statements\":[[0,\"                        \"],[7,\"i\",true],[10,\"class\",\"badge\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                    \"],[4,\"paper-tooltip\",null,[[\"position\"],[[24,[\"tooltipPosition\"]]]],{\"statements\":[[1,[28,\"t\",[[28,\"or\",[[23,1,[\"title\"]],[23,1,[\"link\"]]],null]],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/compact-menu.hbs"
    }
  });

  _exports.default = _default;
});