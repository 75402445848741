define("inboxfront/controllers/ecommerce", ["exports", "inboxfront/controllers/ecommerce/crm-integrations", "inboxfront/gql/query"], function (_exports, _crmIntegrations, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend(_crmIntegrations.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    selectedIntegration: null,
    showLoginForm: false,
    showMarketDialog: false,
    queryParams: ['scope', 'code', 'context'],
    shopData: Ember.computed('selectedIntegration', 'selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount;

      var ret = false;
      (_this$_findAccount = this._findAccount(this.get('selectedIntegration'))) === null || _this$_findAccount === void 0 ? void 0 : _this$_findAccount.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'shop_name') {
          ret = {
            shop_name: e.node.value.split('.')[0]
          };
        } else if (e.node.key === 'bc_store_domain') {
          ret = {
            shop_name: e.node.value
          };
        }
      });
      return ret;
    }),
    shopifyIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount2;

      var ret = false;
      (_this$_findAccount2 = this._findAccount('SHOPIFY')) === null || _this$_findAccount2 === void 0 ? void 0 : _this$_findAccount2.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'shop_name') {
          ret = true;
        }
      });
      return ret;
    }),
    bigcommerceIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount3;

      var ret = false;
      (_this$_findAccount3 = this._findAccount('BIGCOMMERCE')) === null || _this$_findAccount3 === void 0 ? void 0 : _this$_findAccount3.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'bc_store_domain') {
          ret = true;
        }
      });
      return ret;
    }),
    facebookIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var ret = false;

      if (this._findAccount('FACEBOOK')) {
        ret = true;
      }

      return ret;
    }),
    instagramIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var ret = false;

      if (this._findAccount('INSTAGRAM')) {
        ret = true;
      }

      return ret;
    }),
    lineIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var ret = false;

      if (this._findAccount('LINE')) {
        ret = true;
      }

      return ret;
    }),
    wechatIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var ret = false;

      if (this._findAccount('WECHAT')) {
        ret = true;
      }

      return ret;
    }),
    telegramIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var ret = false;

      if (this._findAccount('TELEGRAM')) {
        ret = true;
      }

      return ret;
    }),
    twitterIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var ret = false;

      if (this._findAccount('TWITTER')) {
        ret = true;
      }

      return ret;
    }),
    whatsappIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var ret = false;

      if (this._findAccount('WHATSAPP')) {
        ret = true;
      }

      return ret;
    }),
    actions: {
      onSelectProperty: function onSelectProperty(property) {
        this.set('selectedProperty', property);
        this.set('loadingProgress', '');
        this.set('isShowSFSettings', false); //TODO remove dependency
      },
      onSelectIntegration: function onSelectIntegration(name) {
        this.set('loadingProgress', '');
        this.set('selectedIntegration', name);
        this.set('showMarketDialog', true);
      },
      showLogin: function showLogin() {
        this.set('showLoginForm', true);
        this.set('shopifyDomain', '');
        this.set('bcDomain', '');
      },
      saveShopifyForm: function saveShopifyForm() {
        var shopName = this.get('shopifyDomain') + '.myshopify.com';

        try {
          var clientId = '0335e023bd1c093f9582b0e50fcde52c';
          var scope = ['read_products', 'write_script_tags', 'write_customers', 'write_orders', 'read_fulfillments', 'write_fulfillments', 'read_assigned_fulfillment_orders', 'read_merchant_managed_fulfillment_orders', 'read_third_party_fulfillment_orders', 'read_shipping'].join(',');
          var redirectURL = 'https://dev.raincheck.co/ecommerce';
          var nonce = 'qweasd';
          var accessMode = '';
          var authURL = "https://".concat(shopName, "/admin/oauth/authorize?client_id=").concat(clientId, "&scope=").concat(scope, "&redirect_uri=").concat(redirectURL, "&state=").concat(nonce, "&grant_options[]=").concat(accessMode);
          this.updateLocalStorage(shopName);
          window.location = authURL;
        } catch (e) {
          console.error(e);
        }
      },
      saveBCForm: function saveBCForm() {
        var shopName = 'store-' + this.get('bcDomain') + '.mybigcommerce.com';

        try {
          this.updateLocalStorage(shopName);
          window.location = "https://".concat(shopName, "/manage/marketplace/apps/35931");
        } catch (e) {
          console.error(e);
        }
      },
      onChangeSelectCompany: function onChangeSelectCompany(val) {
        this.set('selectedProperty', val);
        this.changeSelectedChannels(val.id);
      },
      showDialogAction: function showDialogAction(channel) {
        var isNewCreate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var acc = this._findAccount(channel);

        this.showDialogTrigger(acc, acc ? false : isNewCreate, channel);
      },
      onCloseDialog: function onCloseDialog() {
        this.triggerDialog();
        this.set('isPopupInserted', false);
      },
      copyTxt: function copyTxt(name, text) {
        var webHookUrl = document.querySelector(".".concat(name, " input"));
        webHookUrl.disabled = false;
        webHookUrl.select();
        document.execCommand('copy');
        webHookUrl.disabled = true;
        this.get('paperToaster').show(this.intl.t('text_copied', {
          text: text
        }));
      },
      onDelete: function onDelete(channel) {
        var accounts = this.get('selectedProperty.accounts.edges');
        accounts.removeObject(accounts.findBy('node.id', channel.id));
        this.changeSelectedChannels();
      },
      onChannelCreated: function onChannelCreated() {
        var _this = this;

        var isClose = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        var isNew = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var channel = arguments.length > 2 ? arguments[2] : undefined;
        var instagramChannel = arguments.length > 3 ? arguments[3] : undefined;
        var type = this.get('selectedChannelType.type');
        var filterChannel = this.get('arrQuery').findBy('name', type);
        var toaster = this.get('paperToaster');
        var hash = {
          name: this.get('channel.name'),
          application: filterChannel.name,
          propertyId: this.get('selectedProperty').id
        };
        filterChannel.fields.forEach(function (el) {
          Object.entries(el).forEach(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                key = _ref2[0],
                i = _ref2[1];

            hash[key] = _this.get("channel.".concat(i));
          });
        });

        if (filterChannel.name === 'EMAIL' && !this.get('channel.selectedEmailProvider')) {
          hash.emailProvider = 'SENDGRID';
        }

        if (this.get('isEditMode')) hash.accountId = this.get('channel.accountId');
        this.set('isActiveLoader', true);
        this.ajax.query(filterChannel.mutation, 'createOrUpdateAccount', hash).then(function (result) {
          if (result.account) {
            if (_this.get('isEditMode')) {
              if (type === 'FACEBOOK' && instagramChannel.accountId) {
                var _filterChannel = _this.get('arrQuery').findBy('name', 'INSTAGRAM');

                var data = {
                  accountId: instagramChannel.accountId,
                  name: instagramChannel.name,
                  application: _filterChannel.name,
                  propertyId: _this.get('selectedProperty').id,
                  code: instagramChannel.code || ''
                };

                _this.ajax.query(_filterChannel.mutation, 'createOrUpdateAccount', data).then(function (instagram) {
                  if (!instagram.errors) {
                    var account = _this.get('selectedProperty.accounts.edges').findBy('node.id', instagram.account.id);

                    Ember.set(account, 'node.name', instagram.account.name);
                    Ember.set(account, 'node.accountsettingSet', instagram.account.accountsettingSet);
                  } else {
                    toaster.show(instagram.errors[0].message);
                  }

                  _this.onChannelUpdate(result.account, hash.accountId);
                });
              } else {
                _this.onChannelUpdate(result.account, hash.accountId);
              }
            } else {
              if (type === 'FACEBOOK') {
                if (instagramChannel.name) {
                  _this.ajax.query(_query.default.CHANNELS_BY_PROPERTY, 'allAccounts', {
                    propertyId: hash.propertyId
                  }).then(function (res) {
                    if (!res.errors && res.length) {
                      var filter = result.account.accountsettingSet.edges.findBy('node.key', 'my_instagram_account_id');

                      if (filter) {
                        var instagramAcc = res.findBy('pk', +filter.node.value);

                        if (instagramAcc) {
                          var _filterChannel2 = _this.get('arrQuery').findBy('name', 'INSTAGRAM');

                          var _data = {
                            accountId: instagramAcc.id,
                            name: instagramChannel.name,
                            application: _filterChannel2.name,
                            propertyId: _this.get('selectedProperty').id,
                            code: instagramChannel.code
                          };

                          _this.ajax.query(_filterChannel2.mutation, 'createOrUpdateAccount', _data).then(function (instagram) {
                            if (!instagram.errors) {
                              _this.get('selectedProperty.accounts.edges').pushObject({
                                node: instagram.account
                              });
                            } else {
                              toaster.show(instagram.errors[0].message);
                            }

                            _this.callMutation(result.account, isClose, isNew);
                          });
                        } else {
                          _this.callMutation(result.account, isClose, isNew);
                        }
                      } else {
                        _this.callMutation(result.account, isClose, isNew);
                      }
                    } else {
                      _this.callMutation(result.account, isClose, isNew);
                    }
                  });
                } else {
                  _this.callMutation(result.account, isClose, isNew);
                }
              } else {
                if (type === 'LINE') {
                  isClose = false;
                  isNew = true;
                  var filter = result.account.accountsettingSet.edges.findBy('node.key', 'line_webhook_url');

                  _this.set('channel.line_webhook_url', filter.node.value);
                } else if (type === 'WECHAT') {
                  isClose = false;
                  isNew = true;
                  var filterUrl = result.account.accountsettingSet.edges.findBy('node.key', 'wechat_webhook_url');
                  var filterToken = result.account.accountsettingSet.edges.findBy('node.key', 'wechat_token');

                  _this.set('channel.wechat_webhook_url', filterUrl.node.value);

                  _this.set('channel.wechat_token', filterToken.node.value);
                }

                _this.callMutation(result.account, isClose, isNew);
              }
            }

            _this.changeSelectedChannels(hash.propertyId);
          }
        }).catch(function (error) {
          return toaster.show(error.errors && error.errors[0].message);
        }).finally(function () {
          return _this.set('isActiveLoader', false);
        });
      }
    },
    sendCode: function sendCode(_ref3) {
      var code = _ref3.code,
          context = _ref3.context,
          scope = _ref3.scope;

      if (context) {
        this.set('selectedIntegration', 'BIGCOMMERCE');
        var hash = {
          code: code,
          context: context,
          scope: scope
        };
        this.sendBigCommerceCode(this.prepareShopData(hash));
      } else {
        this.set('selectedIntegration', 'SHOPIFY');
        var _hash = {
          code: code
        };
        this.sendShopifyCode(this.prepareShopData(_hash));
      }
    },
    sendShopifyCode: function sendShopifyCode(hash) {
      var _this2 = this;

      var url = 'https://' + window.localStorage.getItem('RC_ShopifyCallbackDomain');
      this.set('installationInProgress', true);
      this.ajax.raw("".concat(url, "/auth/callback"), {
        method: 'POST',
        data: hash,
        headers: {
          'authorization': "Bearer ".concat(this.get('session.data.authenticated.access_token'))
        },
        crossDomain: true
      }).then(function (response) {}).catch(function (_ref4) {
        var response = _ref4.response,
            jqXHR = _ref4.jqXHR,
            payload = _ref4.payload;

        if (jqXHR.status === 200) {
          _this2.get('selectedProperty.accounts.edges').addObject({
            node: {
              name: 'shopify',
              accountsettingSet: {
                edges: [{
                  node: {
                    key: 'shop_name',
                    value: hash.shop_name
                  }
                }]
              }
            }
          });

          _this2.get('paperToaster').show('Shopify store connected');

          _this2.clearLocalStorage();
        }
      }).catch(function (e) {
        console.error(e);
      }).finally(function () {
        _this2.set('installationInProgress', false);
      });
    },
    sendBigCommerceCode: function sendBigCommerceCode(hash) {
      var _this3 = this;

      var url = 'https://' + window.localStorage.getItem('RC_BigCommerceCallbackDomain');
      this.set('installationInProgress', true);
      this.ajax.raw("".concat(url, "/bigcommerce/callback"), {
        method: 'POST',
        data: hash,
        headers: {
          'authorization': "Bearer ".concat(this.get('session.data.authenticated.access_token'))
        },
        crossDomain: true
      }).then(function (response) {}).catch(function (_ref5) {
        var response = _ref5.response,
            jqXHR = _ref5.jqXHR,
            payload = _ref5.payload;

        if (jqXHR.status === 200) {
          _this3.get('selectedProperty.accounts.edges').addObject({
            node: {
              name: 'bigcommerce',
              accountsettingSet: {
                edges: [{
                  node: {
                    key: 'bc_store_domain',
                    value: hash.shop_name
                  }
                }]
              }
            }
          });

          _this3.get('paperToaster').show('BigCommerce store connected');

          _this3.clearLocalStorage();
        }
      }).finally(function () {
        _this3.set('installationInProgress', false);
      });
    },
    prepareShopData: function prepareShopData(hash) {
      hash.shop_name = window.localStorage.getItem('RC_ShopName');
      hash.widgetID = window.localStorage.getItem('RC_WidgetId');
      return hash;
    },
    updateLocalStorage: function updateLocalStorage(shopName) {
      window.localStorage.setItem('RC_PropertyId', this.get('selectedProperty.id'));
      window.localStorage.setItem('RC_WidgetId', this.get('selectedProperty.widgetSettings.id'));
      window.localStorage.setItem('RC_ShopName', shopName);
    },
    clearLocalStorage: function clearLocalStorage() {
      window.localStorage.removeItem('RC_PropertyId');
      window.localStorage.removeItem('RC_WidgetId');
      window.localStorage.removeItem('RC_ShopName');
    },
    openChannelEditDialog: false,
    isEditMode: false,
    isPopupInserted: false,
    isActiveLoader: false,
    showChannel: Ember.computed('page', function () {
      return this.get('page') !== 'widget';
    }),
    changeSelectedChannels: function changeSelectedChannels(propertyId, widgetSettings) {
      var _this4 = this;

      propertyId = propertyId || this.get('selectedProperty.id');
      this.ajax.query(_query.default.WIDGET_SETTINGS, 'widgetSettings', {
        propertyId: propertyId
      }).then(function (settings) {
        var _ref6 = widgetSettings || settings,
            lineDisplay = _ref6.lineDisplay,
            telegramDisplay = _ref6.telegramDisplay,
            wechatDisplay = _ref6.wechatDisplay,
            twitterDisplay = _ref6.twitterDisplay,
            facebookDisplay = _ref6.facebookDisplay,
            whatsappDisplay = _ref6.whatsappDisplay,
            instagramDisplay = _ref6.instagramDisplay;

        var accounts = [{
          id: 1,
          application: 'Line',
          icon: 'LINE',
          type: lineDisplay,
          key: 'lineDisplay'
        }, {
          id: 2,
          application: 'Telegram',
          icon: 'TELEGRAM',
          type: telegramDisplay,
          key: 'telegramDisplay'
        }, {
          id: 3,
          application: 'Wechat',
          icon: 'WECHAT',
          type: wechatDisplay,
          key: 'wechatDisplay'
        }, {
          id: 4,
          application: 'Twitter',
          icon: 'TWITTER',
          type: twitterDisplay,
          key: 'twitterDisplay'
        }, {
          id: 5,
          application: 'Facebook',
          icon: 'FACEBOOK',
          type: facebookDisplay,
          key: 'facebookDisplay'
        }, {
          id: 6,
          application: 'Whatsapp',
          icon: 'WHATSAPP',
          type: whatsappDisplay,
          key: 'whatsappDisplay'
        }, {
          id: 7,
          application: 'Instagram',
          icon: 'INSTAGRAM',
          type: instagramDisplay,
          key: 'instagramDisplay'
        }];

        _this4.ajax.query(_query.default.CHANNELS_BY_PROPERTY, 'allAccounts', {
          propertyId: propertyId
        }).then(function (channels) {
          var mappingChannels = channels.map(function (channel) {
            return channel.application;
          });

          _this4.set('channels', accounts.map(function (account) {
            var channel = channels.find(function (c) {
              return c.application === account.icon;
            });
            return _objectSpread(_objectSpread({}, account), {}, {
              type: (channel && channel.active) === account.type ? account.type : false,
              disabled: mappingChannels.indexOf(account.icon) < 0
            });
          }));
        });
      });
    },
    onChannelUpdate: function onChannelUpdate(data, id) {
      var account = this.get('selectedProperty.accounts.edges').findBy('node.id', id);
      Ember.set(account, 'node.name', data.name);
      Ember.set(account, 'node.accountsettingSet', data.accountsettingSet);
      this.triggerDialog();
      this.get('paperToaster').show(this.intl.t('channel_updated'));
    },
    callMutation: function callMutation(data, isClose, isNew) {
      this.get('selectedProperty.accounts.edges').pushObject({
        node: data
      });
      isClose && this.triggerDialog();
      this.get('paperToaster').show(this.intl.t('channel_created'));

      if (isNew) {
        this.set('isEditMode', true);
      }
    },
    triggerDialog: function triggerDialog() {
      this.set('openChannelEditDialog', !this.get('openChannelEditDialog'));
    },
    showDialogTrigger: function showDialogTrigger(channel, isNewCreate) {
      var _this5 = this;

      var accname = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Facebook';
      var application = channel && channel.application !== 'Instagram' && channel.application || accname;
      var channelTypes = [{
        id: 1,
        component: "facebook",
        title: "Facebook messenger",
        type: 'FACEBOOK'
      }, {
        id: 2,
        component: "line",
        title: "LINE",
        type: 'LINE'
      }, {
        id: 3,
        component: "wechat",
        title: "WeChat",
        type: 'WECHAT'
      }, {
        id: 4,
        component: "telegram",
        title: "Telegram",
        type: 'TELEGRAM'
      }, {
        id: 5,
        component: "twitter",
        title: "Twitter DM",
        type: 'TWITTER'
      }, {
        id: 6,
        component: "whatsapp",
        title: "WhatsApp",
        type: 'WHATSAPP'
      }];
      var ch = channelTypes.findBy('type', application.toUpperCase());
      this.set('channel', {});
      this.set('selectedChannelType', {
        component: ch.component,
        title: ch.title,
        type: ch.type
      });

      if (isNewCreate) {
        this.set('isEditMode', false);
        this.set('channel.my_instagram_account_id', -1);
        this.set('instagramChannel', {
          pk: -1
        });
        this.set('instagramChannelType', {
          component: 'instagram',
          title: 'INSTAGRAM',
          type: 'INSTAGRAM'
        });
      } else {
        this.set('channel.accountId', channel.id);
        this.set('channel.pk', channel.pk);
        this.set('channel.name', channel.name);
        channel.accountsettingSet && channel.accountsettingSet.edges.forEach(function (_ref7) {
          var node = _ref7.node;

          _this5.set('channel.' + node.key, node.extraValue || node.value);
        });

        if (channel.application === 'FACEBOOK') {
          this.set('channel.selectedFacebookPage', {
            name: this.get('channel.facebook_page_name'),
            id: this.get('channel.facebook_page_id')
          });
          var instagramChannel = {
            application: 'INSTAGRAM'
          };
          var filter = channel.accountsettingSet && channel.accountsettingSet.edges.findBy('node.key', 'my_instagram_account_id');

          if (filter) {
            var instagram = this.get('selectedProperty.accounts.edges').findBy('node.pk', +filter.node.value);

            if (instagram) {
              instagramChannel.accountId = instagram.node.id;
              instagramChannel.pk = instagram.node.pk;
              instagramChannel.name = instagram.node.name;
              instagram.node.accountsettingSet.edges.forEach(function (s) {
                instagramChannel[s.node.key] = s.node.value;
              });
            }
          }

          this.set('instagramChannel', instagramChannel);
          this.set('instagramChannelType', {
            component: instagramChannel.application.toLowerCase(),
            title: instagramChannel.application,
            type: instagramChannel.application.toUpperCase()
          });
        } else if (channel.application === 'INSTAGRAM') {
          this.set('instagramChannel', this.get('channel'));
          this.set('instagramChannelType', this.get('selectedChannelType'));
          var fbChannel = {
            application: 'FACEBOOK'
          };
          var found = false;
          this.get('selectedProperty.accounts.edges').forEach(function (acc) {
            if (found) return;
            var filter = acc.node.accountsettingSet.edges.findBy('node.key', 'my_instagram_account_id');

            if (filter) {
              if (+filter.node.value === _this5.get('instagramChannel.pk')) {
                found = true;
                fbChannel.accountId = acc.node.id;
                fbChannel.pk = acc.node.pk;
                fbChannel.name = acc.node.name;
                acc.node.accountsettingSet.edges.forEach(function (s) {
                  fbChannel[s.node.key] = s.node.value;
                });
              }
            }
          });
          this.set('channel', fbChannel);
          this.set('channel.selectedFacebookPage', {
            name: this.get('channel.facebook_page_name'),
            id: this.get('channel.facebook_page_id')
          });
          this.set('selectedChannelType', {
            component: fbChannel.application.toLowerCase(),
            title: fbChannel.application,
            type: fbChannel.application.toUpperCase()
          });
        }

        this.set('channel.selectedDepartment', channel.department);
        this.set('channel.propertyId', this.get('selectedProperty.id'));
        this.set('isEditMode', true);
      }

      this.set('isPopupInserted', true);
      this.triggerDialog();
    }
  });

  _exports.default = _default;
});