define("inboxfront/controllers/roles/edit", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    roleUsers: Ember.computed('role.group.userSet.{}', 'users.{}', function () {
      var roleUsersIds = this.get('role.group.userSet').map(function (u) {
        return Number(u.id);
      });
      return this.get('users').filter(function (user) {
        return roleUsersIds.includes(user.pk);
      });
    }),
    availableUsers: Ember.computed('roleUsers.{}', 'users.{}', function () {
      var roleUsers = this.get('roleUsers');
      return this.get('users').filter(function (user) {
        return user.systemGroup.name === 'member';
      }).filter(function (user) {
        return !roleUsers.find(function (roleUser) {
          return roleUser.id === user.id;
        });
      });
    }),
    isDisabled: Ember.computed('sessionAccount.loggeduser', function () {
      var user = this.get('sessionAccount.loggeduser.systemGroup.name');
      return !(user === 'admin' || user === 'owner');
    }),
    isDisabledBtn: Ember.computed('selectedUser', function () {
      var selectedUser = this.get('selectedUser');
      return !selectedUser;
    }),
    actions: {
      removeUser: function removeUser(user) {
        this.get('roleUsers').removeObject(user);
        var hash = {
          roleId: this.get('role.id'),
          userId: user.id
        };
        this.ajax.query(_mutation.default.REMOVE_USER, 'removeUser', hash);
      },
      onBlurInputName: function onBlurInputName(val) {
        if (val === '') return;
        var hash = {
          name: val,
          roleId: this.get('role.id')
        };
        this.set('role.name', val);
        this.ajax.query(_mutation.default.CREATE_UPDATE_CUSTOM_ROLE, 'createUpdateCustomRole', hash);
      },
      onBlurInputDesc: function onBlurInputDesc(val) {
        if (val === '') return;
        var hash = {
          description: val,
          roleId: this.get('role.id')
        };
        this.set('role.description', val);
        this.ajax.query(_mutation.default.CREATE_UPDATE_CUSTOM_ROLE, 'createUpdateCustomRole', hash);
      },
      addUsers: function addUsers() {
        var _this = this;

        var user = this.get('selectedUser');
        if (!user) return;
        var hash = {
          roleId: this.get('role.id'),
          users: [user.id]
        };
        this.get('roleUsers').pushObject(user);
        this.ajax.query(_mutation.default.ADD_USERS, 'addUsers', hash).then(function () {
          _this.set('selectedUser', null);
        });
      }
    }
  });

  _exports.default = _default;
});