define("inboxfront/controllers/users/index", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    validation: Ember.inject.service(),
    sliceData: Ember.inject.service(),
    usersService: Ember.inject.service('users'),
    openUserCreateDialog: false,
    openUserDeactivateDialog: false,
    openUserDeleteDialog: false,
    openUserPasswordResetDialog: false,
    selectedUser: null,
    //TODO move to pagination component
    observerPage: Ember.observer('pageNum', function () {
      if (this.get('allUsers')) {
        this.set('users', this.get('sliceData').getSliceData(this.get('allUsers'), this.get('pageNum')));
      }
    }),
    limitIsReached: Ember.computed('quotas', 'allUsers.[]', function () {
      var ret = false;
      var quotas = this.get('quotas');
      var users = this.get('allUsers');

      if (users.length >= quotas.tariffPlan.userCountLimit) {
        ret = true;
      }

      return ret;
    }),
    activeUsersCount: Ember.computed('allUsers.{@each.isActive}', function () {
      return this.get('allUsers').filter(function (user) {
        return user.isActive;
      }).length;
    }),
    actions: {
      resendInvite: function resendInvite(_ref) {
        var _this = this;

        var id = _ref.id;
        this.ajax.mutation(_mutation.default.RESEND_INVITE, 'resendActivation', {
          userId: id
        }, function () {
          _this.get('paperToaster').show(_this.intl.t('invite_sent'));
        });
      },
      deleteUserDialog: function deleteUserDialog(user) {
        var cloneUsers = _toConsumableArray(this.get('allUsers'));

        var assigneeUsers = cloneUsers.removeObject(user);
        this.set('selectedUser', user);
        this.set('assigneeUser', null);
        this.set('assigneeUsers', assigneeUsers);
        this.set('openUserDeleteDialog', true);
      },
      deleteUser: function deleteUser() {
        var _this2 = this;

        var data = {
          userId: this.get('selectedUser.id')
        };

        if (this.get('assigneeUser')) {
          data.assignee = this.get('assigneeUser.id');
        }

        this.ajax.mutation(_mutation.default.DELETE_USER, 'deleteUser', data, function () {
          _this2.set('openUserDeleteDialog', false);

          _this2.get('paperToaster').show(_this2.intl.t('user_deleted'));

          _this2.refreshRoute();
        });
      },
      resetPasswordDialog: function resetPasswordDialog(user) {
        var _this3 = this;

        this.set('resetHeader', this.intl.t('actions.reset_password'));
        this.set('resetUser', user);
        this.set('resetLink', '');
        this.set('resetPasswordText', this.intl.t('status.loading'));
        this.set('openUserPasswordResetDialog', true);
        this.ajax.mutation(_mutation.default.RESET_PASSWORD, 'resetPassword', {
          userId: user.id
        }, function (result) {
          if (result.resetLink) {
            _this3.set('resetLink', result.resetLink);

            _this3.set('resetPasswordText', _this3.intl.t('reset_user_password', {
              link: result.resetLink,
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));
          } else {
            _this3.set('resetPasswordText', _this3.intl.t('reset_user_password_email', {
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));
          }
        }, function () {
          _this3.set('resetPasswordText', _this3.intl.t('internal_error'));
        });
      },
      showUserDeactivateDialog: function showUserDeactivateDialog(user) {
        this.set('selectedUser', user);
        this.set('openUserDeactivateDialog', true);
      },
      showUserCreateDialog: function showUserCreateDialog() {
        var _this4 = this;

        this.ajax.query(_query.default.CUSTOM_ROLES).then(function (data) {
          return _this4.set('customRoles', !data.errors ? data : []);
        });
        this.set('openUserCreateDialog', true);
      },
      onUserCreate: function onUserCreate(user) {
        var _this5 = this;

        this.usersService.getQuotas().then(function (quotas) {
          _this5.set('quotas', quotas);

          _this5.usersService.getPropertyUsers().then(function (users) {
            if (quotas.userCountUsage >= quotas.tariffPlan.userCountLimit || users.length >= quotas.tariffPlan.userCountLimit) {
              _this5.paperToaster.show('Sorry. Users limit is reached. Update your tariff plan.');
            } else {
              _this5.usersService.save(user).then(function (user) {
                _this5.set('openUserCreateDialog', false);

                _this5.refreshRoute();

                if (user.resetPasswordLink) {
                  _this5.set('resetHeader', _this5.intl.t('user_created'));

                  _this5.set('resetUser', user);

                  _this5.set('resetLink', user.resetPasswordLink);

                  _this5.set('resetPasswordText', _this5.intl.t('new_user_without_email', {
                    link: user.resetPasswordLink,
                    user: "".concat(user.firstName, " ").concat(user.lastName)
                  }));

                  _this5.set('openUserPasswordResetDialog', true);
                }
              }).catch(function (err) {
                _this5.paperToaster.show(_this5.intl.t('email_exists'));
              });
              /**/

            }

            _this5.set('allUsers', users);
          });
        });
      },
      saveUserStatus: function saveUserStatus(user, status) {
        var _this6 = this;

        var hash = {
          userId: user.id,
          isActive: status
        };
        this.ajax.mutation(_mutation.default.UPDATE_USER_ADMIN, 'updateUserAdmin', hash, function () {
          _this6.paperToaster.show(_this6.intl.t(status ? 'user_activated' : 'user_deactivated'));

          Ember.set(user, 'isActive', status);

          _this6.set('openUserDeactivateDialog', false);
        });
      }
    },
    refreshRoute: function refreshRoute() {
      var route = Ember.getOwner(this).lookup("route:".concat(this.router.currentRoute.name));
      route.refresh();
    }
  });

  _exports.default = _default;
});