define("inboxfront/routes/organizations/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    sliceData: Ember.inject.service(),
    contacts: Ember.inject.service(),
    users: Ember.inject.service(),
    model: function model() {
      var organizations = this.ajax.query(_query.default.ORGANIZATIONS);
      return Ember.RSVP.all([organizations, this.users.getPropertyUsers(), this.contacts.getOrganizationFields()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            organizations = _ref2[0],
            users = _ref2[1],
            fields = _ref2[2];

        return {
          organizations: !organizations.errors ? organizations : [],
          users: users,
          settings: fields.settings,
          fields: fields
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('organizations', this.get('sliceData').getSliceData(model.organizations, 1));
      controller.set('rangeNum', this.get('sliceData').calculateRange(model.organizations)); //TODO move calculations to pagination component?

      controller.set('pageNum', this.get('sliceData').getPageNum());
      controller.set('cloneOrganizations', model.organizations);
      var columns = [{
        id: 'phone',
        title: 'phone'
      }, {
        id: 'country',
        title: 'country'
      }, {
        id: 'company',
        title: 'company'
      }, {
        id: 'state',
        title: 'state'
      }, {
        id: 'contactOwner',
        title: 'owner'
      }, {
        id: 'industry',
        title: 'industry'
      }, {
        id: 'created',
        title: 'created',
        fieldType: 'DATE'
      }]; //TODO move column manipulations to component "column-select"

      var fieldsSettings = model.settings.fields;
      model.fields.forEach(function (cf) {
        if (!cf.isSystem) {
          columns.pushObject({
            id: cf.id,
            title: cf.label,
            fieldType: cf.fieldType,
            isCustomField: true
          });
        }
      });
      var columnSettings;

      try {
        columnSettings = JSON.parse(fieldsSettings);
      } catch (e) {
        columnSettings = [];
      }

      if (!Array.isArray(columnSettings)) {
        columnSettings = [];
      }

      columns.forEach(function (c) {
        var data = columnSettings.findBy('id', c.id) || {
          pos: 0,
          vis: true
        };
        Ember.set(c, 'position', +data.pos);
        Ember.set(c, 'visible', !!data.vis);
      });
      columns = columns.sortBy('position');
      /**/

      controller.set('model.columns', columns);
      controller.set('listSelectedOrganizations', []);
      controller.set('properties', []);
      controller.set('organization', {});
      controller.set('listMergeSelectedOrganizations', []);
      controller.set('titleMergeOrganizations', [{
        title: 'Name',
        key: 'name'
      }, {
        title: 'Fax Number',
        key: 'fax'
      }, {
        title: 'Phone Number',
        key: 'phone'
      }, {
        title: 'Industry',
        key: 'industry'
      }, {
        title: 'Website',
        key: 'website'
      }, {
        title: 'Home Address Line 1',
        key: 'address.line1'
      }, {
        title: 'Home Address Line 2',
        key: 'address.line2'
      }, {
        title: 'Home Postal Code',
        key: 'address.city'
      }, {
        title: 'Home State',
        key: 'address.state'
      }, {
        title: 'Home Country',
        key: 'address.country'
      }
      /*{ title: 'WhatsApp', key: 'whatsapp', isSocial: true },
      { title: 'Line', key: 'line', isSocial: true },
      { title: 'Telegram', key: 'telegram', isSocial: true },
      { title: 'Facebook', key: 'facebook', isSocial: true },
      { title: 'Twitter', key: 'twitter', isSocial: true },
      { title: 'WeChat', key: 'wechat', isSocial: true },*/
      ]);
      controller.set('listFilterFields', [{
        name: this.intl.t('name'),
        param: 'name'
      }, {
        name: this.intl.t('phone'),
        param: 'phone'
      }, {
        name: this.intl.t('fax'),
        param: 'fax'
      }, {
        name: this.intl.t('country'),
        param: 'country'
      }, {
        name: this.intl.t('tag'),
        param: 'tag'
      }, {
        name: this.intl.t('industry'),
        param: 'industry'
      }, //{ name: this.intl.t('company'), param: 'company' },
      {
        name: this.intl.t('owner'),
        param: 'owner'
      }]);
      controller.set('listFilterLookup', [{
        name: this.intl.t('filters.startswith'),
        param: 'startswith'
      }, {
        name: this.intl.t('filters.contains'),
        param: 'contains'
      }, {
        name: this.intl.t('filters.exact'),
        param: 'exact'
      }, {
        name: this.intl.t('filters.endswith'),
        param: 'endswith'
      }, {
        name: this.intl.t('filters.exclude'),
        param: 'exclude'
      }]);
      controller.set('selectedFilterField', controller.get('listFilterFields')[0]);
      controller.set('selectedFilterLookup', controller.get('listFilterLookup')[0]);
    }
  });

  _exports.default = _default;
});