define("inboxfront/templates/components/integrations/channel-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b5KZdO6b",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ecommerce-tab\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"onSelectIntegration\"]],[24,[\"name\"]]],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-space-between\"],[8],[0,\"\\n        \"],[1,[28,\"svg-icon\",[[24,[\"logo\"]]],null],false],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"status \",[28,\"if\",[[24,[\"isConnected\"]],\"connected\",\"\"],null]]]],[8],[0,\"\\n            \"],[1,[28,\"if\",[[24,[\"isConnected\"]],\"Connected\",\"Connect\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tip\"],[8],[0,\"Built by Raincheck\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"info\"],[8],[0,\"\\n        Some info about \"],[1,[22,\"title\"],false],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"channelName\"]]],null,{\"statements\":[[0,\"            Name: \"],[1,[22,\"channelName\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/integrations/channel-tab.hbs"
    }
  });

  _exports.default = _default;
});