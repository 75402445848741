define("inboxfront/controllers/group/index", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    validation: Ember.inject.service(),
    showAddGroup: false,
    showDeleteGroupDialog: false,
    actions: {
      toggleShowPopup: function toggleShowPopup() {
        this.clearForm();
        this.set('showAddGroup', !this.get('showAddGroup'));
      },
      deselectCompany: function deselectCompany(company) {
        this.get('selectedProperties').removeObject(company);
      },
      deselectUser: function deselectUser(user) {
        this.get('selectedUsers').removeObject(user);
      },
      createGroup: function createGroup() {
        var _this = this;

        if (!this.get('selectedProperties').length) {
          return this.get('paperToaster').show(this.intl.t('add_one_company'));
        }

        var properties = _toConsumableArray(this.get('selectedProperties')).map(function (prop) {
          return Number(prop.id);
        });

        var users = _toConsumableArray(this.get('selectedUsers')).map(function (user) {
          return Number(user.id);
        });

        var hash = {
          name: this.get('companyName'),
          description: this.get('companyDescription') || '',
          properties: properties,
          users: users
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_COMPANY_GROUP, 'createUpdateCompanyGroup', hash, function () {
          _this.refreshRoute();

          _this.set('showAddGroup', false);

          _this.clearForm();

          _this.get('paperToaster').show(_this.intl.t('company_group_created'));
        }, function () {
          _this.get('paperToaster').show(_this.intl.t('company_group_exists', {
            name: hash.name
          }));
        });
      },
      showDeleteDialog: function showDeleteDialog(group) {
        this.set('selectedGroup', group);
        this.set('showDeleteGroupDialog', true);
      },
      onCloseDialog: function onCloseDialog() {
        this.set('selectedGroup', null);
        this.set('showDeleteGroupDialog', false);
      },
      deleteGroup: function deleteGroup() {
        var _this2 = this;

        var group = this.get('selectedGroup');

        if (group) {
          this.ajax.mutation(_mutation.default.DELETE_COMPANY_GROUP, 'deleteCompanyGroup', {
            companyGroupId: group.id
          }, function () {
            _this2.get('groups').removeObject(group);

            _this2.get('paperToaster').show(_this2.intl.t('company_group_deleted'));

            _this2.set('selectedGroup', null);

            _this2.set('showDeleteGroupDialog', false);
          });
        }
      }
    },
    clearForm: function clearForm() {
      this.set('companyName', '');
      this.set('companyDescription', '');
      this.set('selectedProperties', []);
      this.set('selectedUsers', []);
    },
    refreshRoute: function refreshRoute() {
      var route = Ember.getOwner(this).lookup("route:".concat(this.router.currentRoute.name));
      route.refresh();
    }
  });

  _exports.default = _default;
});