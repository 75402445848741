define("inboxfront/templates/components/common/buttons/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wFstz4Lq",
    "block": "{\"symbols\":[\"&default\",\"@disabled\",\"@class\"],\"statements\":[[4,\"paper-button\",null,[[\"iconButton\",\"class\",\"disabled\",\"onClick\"],[true,[23,3,[]],[23,2,[]],[24,[\"onClick\"]]]],{\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"svg-icon\",[[24,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[4,\"paper-tooltip\",null,[[\"position\"],[\"bottom\"]],{\"statements\":[[14,1]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/buttons/icon.hbs"
    }
  });

  _exports.default = _default;
});