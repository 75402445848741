define("inboxfront/services/contacts", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Contact = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.id = undefined;
      this._original = {};
    },
    setData: function setData(fields, data) {
      var _data$selectedCountry,
          _data$selectedContact,
          _data$selectedOrganiz,
          _data$selectedPropert,
          _this = this;

      this.id = data.id;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.primaryEmail = data.primaryEmail;
      this.personalPhone = data.personalPhone;
      this.country = (_data$selectedCountry = data.selectedCountry) === null || _data$selectedCountry === void 0 ? void 0 : _data$selectedCountry.name;
      this.contactOwner = (_data$selectedContact = data.selectedContactOwner) === null || _data$selectedContact === void 0 ? void 0 : _data$selectedContact.id;
      this.organizationId = (_data$selectedOrganiz = data.selectedOrganization) === null || _data$selectedOrganiz === void 0 ? void 0 : _data$selectedOrganiz.id;
      this.propertyId = (_data$selectedPropert = data.selectedProperty) === null || _data$selectedPropert === void 0 ? void 0 : _data$selectedPropert.id;

      if (!data.customFields) {
        data.customFields = [];
      }

      this._fields = fields;
      fields && fields.forEach(function (f) {
        var v = data.customFields.findBy('field.id', f.id);

        if (v) {
          _this[f.id] = v.value;
          f.sections.forEach(function (s) {
            var active = s.conditionFields.indexOf(+v.value) >= 0;
            Ember.set(s, 'active', active);
            s.subfields.forEach(function (sf) {
              var v = data.customFields.findBy('field.id', sf.id);

              if (v) {
                _this[sf.id] = v.value;
              } else {
                _this[sf.id] = '';
              }

              if (sf.fieldType === 'DATE' && !_this[sf.id]) {
                _this[sf.id] = new Date();
              }
            });
          });
        } else {
          _this[f.id] = '';
        }

        if (f.fieldType === 'DATE' && !_this[f.id]) {
          _this[f.id] = new Date();
        }
      });
    },
    updateData: function updateData(data) {
      var _this2 = this;

      this._fields && this._fields.forEach(function (f) {
        if (data[f.id] === undefined) {
          return;
        }

        Ember.set(_this2, f.id, data[f.id]);

        if (!f.isSystem) {
          f.sections.forEach(function (s) {
            s.active && s.subfields.forEach(function (sf) {
              if (data[sf.id] === undefined) {
                return;
              }

              Ember.set(_this2, sf.id, data[sf.id]);
            });
          });
        }
      });
    },
    getData: function getData() {
      var _this$selectedCountry,
          _this$selectedContact,
          _this$selectedOrganiz,
          _this$selectedPropert,
          _this3 = this;

      var hash = {
        id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
        primaryEmail: this.primaryEmail,
        personalPhone: this.personalPhone,
        country: (_this$selectedCountry = this.selectedCountry) === null || _this$selectedCountry === void 0 ? void 0 : _this$selectedCountry.name,
        contactOwner: (_this$selectedContact = this.selectedContactOwner) === null || _this$selectedContact === void 0 ? void 0 : _this$selectedContact.id,
        organizationId: (_this$selectedOrganiz = this.selectedOrganization) === null || _this$selectedOrganiz === void 0 ? void 0 : _this$selectedOrganiz.id,
        propertyId: (_this$selectedPropert = this.selectedProperty) === null || _this$selectedPropert === void 0 ? void 0 : _this$selectedPropert.id
      };
      var customFields = [];

      this._fields.forEach(function (f) {
        var value = _this3[f.id];

        if (value !== _this3._original[f.id]) {
          if (f.fieldType === 'DATE') {
            value = new Date(value).toISOString();
          }

          customFields.addObject({
            id: f.id,
            value: value
          });
          f.sections.forEach(function (s) {
            s.active && s.subfields.forEach(function (sf) {
              var value = _this3[sf.id];

              if (value !== _this3._original[sf.id]) {
                if (sf.fieldType === 'DATE') {
                  value = new Date(value).toISOString();
                }

                customFields.addObject({
                  id: sf.id,
                  value: value
                });
              }
            });
          });
        }
      });

      if (customFields.length) {
        hash.customFields = customFields;
      }

      Object.keys(hash).forEach(function (key) {
        return hash[key] === undefined && delete hash[key];
      });
      return hash;
    },
    commit: function commit() {
      var _this4 = this;

      this._fields.forEach(function (f) {
        _this4._original[f.id] = _this4[f.id];
        f.sections.forEach(function (s) {
          s.subfields.forEach(function (sf) {
            _this4._original[sf.id] = _this4[sf.id];
          });
        });
      });
    }
  });

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    customfields: Ember.inject.service(),

    get contactSystemFields() {
      return [{
        id: 'firstName',
        label: 'First Name',
        description: "Contact's first name",
        fieldType: 'SINGLE_LINE',
        required: true
      }, {
        id: 'lastName',
        label: 'Last Name',
        description: "Contact's last name",
        fieldType: 'SINGLE_LINE',
        required: true
      }, {
        id: 'email',
        label: 'Email',
        description: "Contact's email",
        fieldType: 'SINGLE_LINE',
        required: true
      }, {
        id: 'personal_phone',
        label: 'Phone Number',
        description: "Contact's phone number",
        fieldType: 'SINGLE_LINE',
        required: false
      }, {
        id: 'mobile_phone',
        label: 'Mobile Number',
        description: "Contact's mobile number",
        fieldType: 'SINGLE_LINE',
        required: false,
        updateable: true
      }, {
        id: 'homeAddress.line1',
        label: 'Address Line 1',
        description: "Home address",
        fieldType: 'SINGLE_LINE',
        required: false,
        updateable: true
      }, {
        id: 'homeAddress.line2',
        label: 'Address Line 2',
        description: "Home address",
        fieldType: 'SINGLE_LINE',
        required: false,
        updateable: true
      }, {
        id: 'homeAddress.state',
        label: 'State',
        description: "Home address",
        fieldType: 'SELECTION',
        required: false,
        updateable: true
      }, {
        id: 'homeAddress.country',
        label: 'Country',
        description: "Home address",
        fieldType: 'SELECTION',
        required: false,
        updateable: true
      }, {
        id: 'officeAddress.line1',
        label: 'Office Address Line 1',
        description: "Office address",
        fieldType: 'SINGLE_LINE',
        required: false,
        updateable: true
      }, {
        id: 'officeAddress.line2',
        label: 'Office Address Line 2',
        description: "Office address",
        fieldType: 'SINGLE_LINE',
        required: false,
        updateable: true
      }, {
        id: 'officeAddress.state',
        label: 'Office State',
        description: "Office address",
        fieldType: 'SELECTION',
        required: false,
        updateable: true
      }, {
        id: 'officeAddress.country',
        label: 'Office Country',
        description: "Office address",
        fieldType: 'SELECTION',
        required: false,
        updateable: true
      }, {
        id: 'socialType',
        label: 'Social Type',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'socialProfile',
        label: 'Social Profile',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'organization',
        label: 'Organisation Name',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'language',
        label: 'Language',
        description: "",
        fieldType: 'SELECTION',
        required: true
      }, {
        id: 'contactOwner',
        label: 'Contact Owner',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'created',
        label: 'Create Date/Time',
        description: "",
        fieldType: 'DATE',
        required: false
      }, {
        id: 'modified',
        label: 'Update Date/Time',
        description: "",
        fieldType: 'DATE',
        required: false
      }];
    },

    get organizationSystemFields() {
      return [{
        id: 'name',
        label: 'Organisation Name',
        description: "",
        fieldType: 'SINGLE_LINE',
        required: true
      }, {
        id: 'phone',
        label: 'Phone Number',
        description: "",
        fieldType: 'SINGLE_LINE',
        required: false
      }, {
        id: 'fax',
        label: 'Fax Number',
        description: "",
        fieldType: 'SINGLE_LINE',
        required: false
      }, {
        id: 'address.line1',
        label: 'Address Line 1',
        description: "",
        fieldType: 'SINGLE_LINE',
        required: false
      }, {
        id: 'address.line2',
        label: 'Address Line 2',
        description: "",
        fieldType: 'SINGLE_LINE',
        required: false
      }, {
        id: 'address.state',
        label: 'State',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'address.country',
        label: 'Country',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'industry',
        label: 'Industry',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'website',
        label: 'Website',
        description: "",
        fieldType: 'SINGLE_LINE',
        required: false
      }, {
        id: 'contactOwner',
        label: 'Organisation Owner',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'socialType',
        label: 'Social Type',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'socialProfile',
        label: 'Social Profile',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'contactName',
        label: 'Contact Name',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'parent',
        label: 'Parent Company',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'children',
        label: 'Children Company',
        description: "",
        fieldType: 'SELECTION',
        required: false
      }, {
        id: 'createdAt',
        label: 'Create Date/Time',
        description: "",
        fieldType: 'DATE',
        required: false
      }, {
        id: 'modified',
        label: 'Update Date/Time',
        description: "",
        fieldType: 'DATE',
        required: false
      }];
    },

    getContactFields: function getContactFields() {
      var _this5 = this;

      var includeSystem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return new Promise(function (resolve) {
        _this5.ajax.query(_query.default.GUEST_SETTINGS).then(function (settings) {
          resolve(_this5.customfields.prepare(includeSystem ? _this5.contactSystemFields : [], settings));
        });
      });
    },
    getOrganizationFields: function getOrganizationFields() {
      var _this6 = this;

      var includeSystem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return new Promise(function (resolve) {
        _this6.ajax.query(_query.default.ORGANIZATION_SETTINGS).then(function (settings) {
          resolve(_this6.customfields.prepare(includeSystem ? _this6.organizationSystemFields : [], settings));
        });
      });
    },
    create: function create(fields, data) {
      var contact = Contact.create();
      contact.setData(fields, data);
      return contact;
    },
    deserialize: function deserialize(fields, data) {
      var contact = Contact.create();
      contact.setData(fields, data);
      contact.commit();
      return contact;
    },
    saveGuest: function saveGuest(contact, callback) {
      var hash = contact.getData();

      if (contact.id !== undefined) {
        hash.guestId = contact.id;
      }

      this.ajax.mutation(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash, function (result) {
        contact.commit();
        callback && callback(result);
      });
    },
    saveOrganization: function saveOrganization(contact, callback) {
      var hash = contact.getData();

      if (contact.id !== undefined) {
        hash.organizationId = contact.id;
      }

      this.ajax.mutation(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash, function (result) {
        contact.commit();
        callback && callback(result);
      });
    }
  });

  _exports.default = _default;
});