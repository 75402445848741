define("inboxfront/routes/settings", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var locales = this.ajax.query(_query.default.LOCALES);
      var timezones = this.ajax.query(_query.default.TIMEZONES);
      var companySettings = this.ajax.query(_query.default.COMPANY_SETTINGS);
      return Ember.RSVP.all([locales, timezones, companySettings]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            locales = _ref2[0],
            timezones = _ref2[1],
            companySettings = _ref2[2];

        companySettings = !companySettings.errors ? companySettings : {};
        return {
          locales: locales,
          timezones: timezones,
          companySettings: companySettings
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var _model$companySetting = model.companySettings,
          timezone = _model$companySetting.timezone,
          locale = _model$companySetting.locale,
          dateformat = _model$companySetting.dateformat,
          timeformat = _model$companySetting.timeformat,
          employeeCount = _model$companySetting.employeeCount,
          company = _model$companySetting.company;
      model.locales = JSON.parse(model.locales);
      model.timezones = JSON.parse(model.timezones);
      controller.set('timeFormats', [{
        id: 1,
        name: '24 hours'
      }, {
        id: 2,
        name: '12 hours'
      }]);
      controller.set('dateFormats', [{
        id: 1,
        name: 'DD-MM-YYYY'
      }, {
        id: 2,
        name: 'MM-DD-YYYY'
      }, {
        id: 3,
        name: 'MM-DD-YY'
      }]);
      controller.set('employeeCounts', [{
        value: '0 - 10',
        id: '0_10'
      }, {
        value: '11 - 50',
        id: '11_50'
      }, {
        value: '51 - 100',
        id: '51_100'
      }, {
        value: '101 - 500',
        id: '101_500'
      }, {
        value: '501 - 1000',
        id: '501_1000'
      }, {
        value: '1001+',
        id: '1001_MAX'
      }]);
      controller.set('industries', [{
        id: 1,
        name: 'Education'
      }, {
        id: 2,
        name: 'Energy'
      }, {
        id: 3,
        name: 'Entertainment & Gaming'
      }, {
        id: 4,
        name: 'Financial & Insurance Services'
      }, {
        id: 5,
        name: 'Government & Non-profit'
      }, {
        id: 6,
        name: 'Healthcare'
      }, {
        id: 7,
        name: 'IT Services & Consultancy'
      }, {
        id: 8,
        name: 'Manufacturing & Computer Hardware'
      }, {
        id: 9,
        name: 'Media & Telecommunications'
      }, {
        id: 10,
        name: 'Marketing & Advertising'
      }, {
        id: 11,
        name: 'Professional & Business Support Services'
      }, {
        id: 12,
        name: 'Real Estate'
      }, {
        id: 13,
        name: 'Retail'
      }, {
        id: 14,
        name: 'Social Media'
      }, {
        id: 15,
        name: 'Software'
      }, {
        id: 16,
        name: 'Travel, Hospitality, & Tourism'
      }, {
        id: 17,
        name: 'Web Applications'
      }, {
        id: 18,
        name: 'Web Hosting'
      }, {
        id: 19,
        name: 'Other'
      }]);
      controller.set('selectedIndustry', controller.get('industries')[15]);
      controller.set('selectedTimezone', model.timezones.find(function (t) {
        return t.name === timezone;
      }));
      controller.set('selectedLocale', model.locales.find(function (l) {
        return l.name === locale;
      }));
      controller.set('selectedDateFormat', controller.get('dateFormats').find(function (d) {
        return d.name === dateformat;
      }));
      controller.set('selectedTimeFormat', controller.get('timeFormats').find(function (t) {
        return t.name === timeformat;
      }));
      controller.set('selectedEmployeeCount', controller.get('employeeCounts').find(function (e) {
        return e.id === employeeCount;
      }));
      controller.set('companyName', company === null || company === void 0 ? void 0 : company.title);
    }
  });

  _exports.default = _default;
});