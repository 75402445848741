define("inboxfront/routes/organizations/edit", ["exports", "inboxfront/routes/common", "inboxfront/config/environment", "inboxfront/gql/query"], function (_exports, _common, _environment, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    contacts: Ember.inject.service(),
    users: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var id = _ref.id;

      if (!id) {
        this.transitionTo('organizations');
      } else {
        var organization = this.ajax.query(_query.default.ORGANIZATION, 'organization', {
          organizationId: id
        });
        var guests = this.ajax.query(_query.default.GUEST_CONTACTS_LIST);
        var properties = this.ajax.query(_query.default.PROPERTY_LIST);
        var organizations = this.ajax.query(_query.default.ORGANIZATIONS_SELECT);
        return Ember.RSVP.all([organization, this.users.getPropertyUsers(), guests, properties, organizations, this.contacts.getOrganizationFields()]).then(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 6),
              organization = _ref3[0],
              users = _ref3[1],
              guests = _ref3[2],
              properties = _ref3[3],
              organizations = _ref3[4],
              orgFields = _ref3[5];

          return {
            organization: organization,
            users: users,
            guests: guests,
            properties: properties,
            organizations: organizations,
            orgFields: orgFields
          };
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _organization$address, _organization$address2;

      this._super(controller, model);

      var arr = [];
      var organization = model.organization;
      var children = organization.children;
      var parent = organization.parent;
      var original = model.organizations;
      parent !== null && arr.push(parent);
      children.length && arr.push.apply(arr, _toConsumableArray(children));
      controller.set('channels', [{
        icon: 'source-FACEBOOK',
        type: 'facebook'
      }, {
        icon: 'source-WHATSAPP',
        type: 'whatsapp'
      }, {
        icon: 'source-LINE',
        type: 'line'
      }, {
        icon: 'source-WECHAT',
        type: 'wechat'
      }, {
        icon: 'source-TWITTER',
        type: 'twitter'
      }, {
        icon: 'source-TELEGRAM',
        type: 'telegram'
      }]);
      controller.set('filterOrganizations', original.filter(function (obj) {
        return !arr.find(function (g) {
          return g.id === obj.id;
        });
      }));
      controller.set('organization', organization);
      controller.set('selectedContactOwner', model.users.find(function (u) {
        var _organization$contact;

        return u.id === ((_organization$contact = organization.contactOwner) === null || _organization$contact === void 0 ? void 0 : _organization$contact.id);
      }));
      controller.set('selectedGuest', model.guests.find(function (g) {
        return g.id === organization.guestSet.length && organization.guestSet[0].id;
      }));
      controller.set('selectedProperty', model.properties.find(function (p) {
        var _organization$company;

        return p.id === ((_organization$company = organization.companyProperty) === null || _organization$company === void 0 ? void 0 : _organization$company.id);
      }));
      controller.set('addressOrganization', {
        id: 0,
        name: ((_organization$address = organization.address) === null || _organization$address === void 0 ? void 0 : _organization$address.countryString) || ((_organization$address2 = organization.address) === null || _organization$address2 === void 0 ? void 0 : _organization$address2.country)
      });
      controller.set('cloneOrganization', _objectSpread({}, organization));
      controller.set('cloneOrganizationAddress', _objectSpread({}, organization.address));
      controller.set('customFieldsData', this.contacts.deserialize(model.orgFields, organization || {}));
      var url = organization.logo !== null ? "".concat(_environment.default.mediaUrl).concat(organization.logo.datafile) : null;
      controller.set('urlAttachment', url);
      controller.set('attachmentId', organization.logo !== null ? organization.logo.id : null);
      controller.loadOpportunities();
    }
  });

  _exports.default = _default;
});