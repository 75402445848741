define("inboxfront/components/chat/assign-agent", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    searchAgents: '',
    availableAgents: Ember.computed('agents', function () {
      var agents = this.get('agents');
      return agents ? agents.filter(function (el) {
        return el.active;
      }).map(function (agent) {
        return _objectSpread(_objectSpread({}, agent.agentProfile.user), {}, {
          id: agent.id,
          status: agent.status.toLowerCase()
        });
      }) : [];
    }),
    assignee: Ember.computed('selectedThread', 'agents', function () {
      var thread = this.get('selectedThread');
      return thread.agent ? thread.agent.agentProfile.user : null;
    }),
    filteredAgents: Ember.computed('availableAgents', 'searchAgents', function () {
      var agents = this.get('availableAgents') || [];
      var str = this.get('searchAgents').toLowerCase();
      return agents.filter(function (u) {
        return (u._fullName || (u._fullName = u.firstName + ' ' + u.lastName)).toLowerCase().indexOf(str) >= 0;
      });
    }),
    actions: {
      onclickOutside: function onclickOutside() {
        this.onclickOutside();
        this.set('searchAgents', '');
      },
      assignUserThread: function assignUserThread(agent) {
        var _this = this;

        var hash = {
          threadId: this.get('selectedThread').id,
          newAgentId: agent.id,
          application: this.get('application')
        };
        this.ajax.mutation(_mutation.default.REASSIGN_THREAD, 'reassignThread', hash, function () {
          _this.get('paperToaster').show(_this.intl.t('agent_selected'));

          _this.reAssignAgent(agent);
        });
      }
    }
  });

  _exports.default = _default;
});