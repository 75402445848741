define("inboxfront/controllers/issuetypes", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    validation: Ember.inject.service(),
    actions: {
      changeIssueTypeStatus: function changeIssueTypeStatus(type) {
        var _this = this;

        var status = !type.isActive;
        var hash = {
          id: type.id,
          isActive: status
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_ISSUE_TYPE, 'createUpdateIssueType', hash, function () {
          if (status) {
            _this.get('paperToaster').show(_this.intl.t('issue_type_activated'));
          } else {
            _this.get('paperToaster').show(_this.intl.t('issue_type_deactivated'));
          }

          Ember.set(type, 'isActive', status);
        });
      },
      showCreateDialog: function showCreateDialog() {
        this.clearField();
        this.set('showCreateType', true);
        this.set('showEditType', false);
      },
      closeCreateDialog: function closeCreateDialog() {
        this.set('showCreateType', false);
      },
      createIssueType: function createIssueType() {
        var _this2 = this;

        var hash = {
          issueType: this.get('typeName'),
          description: this.get('typeDescription'),
          isActive: this.get('typeStatus')
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_ISSUE_TYPE, 'createUpdateIssueType', hash, function (res) {
          _this2.get('model.issueTypes').addObject(res.issue);

          _this2.get('paperToaster').show(_this2.intl.t('issue_type_created'));

          _this2.set('showCreateType', false);
        });
      },
      showEditDialog: function showEditDialog(type) {
        this.set('selectedType', type);
        this.set('typeName', type.issueType);
        this.set('typeDescription', type.description);
        this.set('typeStatus', type.isActive);
        this.set('typeIsSystem', type.isSystem);
        this.set('showCreateType', false);
        this.set('showEditType', true);
      },
      closeEditDialog: function closeEditDialog() {
        this.set('showEditType', false);
      },
      updateIssueType: function updateIssueType() {
        var _this3 = this;

        var selectedType = this.get('selectedType');
        var issueType = this.get('typeName');
        var description = this.get('typeDescription');
        var isActive = this.get('typeStatus');

        if (issueType !== selectedType.issueType || description !== selectedType.description || isActive !== selectedType.isActive) {
          var hash = {
            id: this.get('selectedType.id'),
            issueType: issueType,
            description: description,
            isActive: isActive
          };
          this.ajax.mutation(_mutation.default.CREATE_UPDATE_ISSUE_TYPE, 'createUpdateIssueType', hash, function () {
            _this3.get('paperToaster').show(_this3.intl.t('issue_type_updated'));

            _this3.set('selectedType.issueType', issueType);

            _this3.set('selectedType.description', description);

            _this3.set('selectedType.isActive', isActive);

            _this3.set('showEditType', false);
          });
        } else {
          this.set('showEditType', false);
        }
      },
      showDeleteDialog: function showDeleteDialog(type) {
        this.set('selectedType', type);
        this.set('showDeleteIssueTypeDialog', true);
      },
      deleteIssueType: function deleteIssueType() {
        var _this4 = this;

        this.ajax.mutation(_mutation.default.DELETE_ISSUE_TYPE, 'deleteIssueType', {
          id: this.get('selectedType.id')
        }, function () {
          _this4.get('model.issueTypes').removeObject(_this4.get('selectedType'));

          _this4.set('showDeleteIssueTypeDialog', false);

          _this4.get('paperToaster').show(_this4.intl.t('issue_type_deleted'));
        });
      }
    },
    clearField: function clearField() {
      this.set('typeName', '');
      this.set('typeDescription', '');
      this.set('typeStatus', false);
    }
  });

  _exports.default = _default;
});