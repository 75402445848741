define("inboxfront/components/common/form-phone", ["exports", "inboxfront/code-phone"], function (_exports, _codePhone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('selectCodePhones', _codePhone.codePhones);
      var phone = this.get('phone') ? this.get('phone').replace(/\D/g, '') : '';
      var index = 4;

      if (phone) {
        var _loop = function _loop() {
          var numberPhone = phone.slice(0, index);

          var selectedCode = _codePhone.codePhones.find(function (_ref) {
            var dial_code = _ref.dial_code;
            return dial_code === '+' + numberPhone;
          });

          index--;

          if (selectedCode) {
            _this.set('selectedCode', _objectSpread(_objectSpread({}, selectedCode), {}, {
              name: ''
            }));

            return "break";
          }
        };

        while (index >= 0) {
          var _ret = _loop();

          if (_ret === "break") break;
        }
      }

      this.numberPhone = phone.slice(index + 1, -1);
      /*
      const splitPhone = this.get('phone') ? this.get('phone').split('-') : [];
      this.numberPhone = splitPhone[1] || splitPhone[0];
      this.codePhone = splitPhone.length === 2 ? splitPhone[0] : '';
       if (this.codePhone) {
          const selectedCode = codePhones.find(({ dial_code }) => dial_code === this.codePhone);
          this.set('selectedCode', { ...selectedCode, name: '' });
      }
       */
    },
    actions: {
      selectCodePhone: function selectCodePhone(codePhone) {
        var phone = codePhone.dial_code + '-' + this.get('numberPhone');
        this.set('selectedCode', _objectSpread(_objectSpread({}, codePhone), {}, {
          name: ''
        }));
        this.set('phone', phone);

        if (this.onChange) {
          this.onChange(this.get('key'), phone);
        }
      },
      onKeyDown: function onKeyDown(e) {
        var val = e.target.value;
        var code = e.which ? e.which : e.keyCode;

        if (code !== 46 && code > 31 && (code < 48 || code > 57) || code === 46 && val.indexOf('.') > -1) {
          return false;
        }

        this.set('numberPhone', val);
        var phone = this.get('selectedCode').dial_code + '-' + val;
        this.set('phone', phone);
      },
      onKeyUp: function onKeyUp(e) {
        var val = e.target.value;

        if (val.length > 14) {
          val = val.substr(0, 14);
        }

        this.set('numberPhone', val);
        var phone = this.get('selectedCode').dial_code + '-' + val;
        this.set('phone', phone);
      },
      onBlurInputPhone: function onBlurInputPhone() {
        if (this.onChange) {
          this.onChange(this.get('key'), this.get('phone'));
        }
      }
    }
  });

  _exports.default = _default;
});