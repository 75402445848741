define("inboxfront/controllers/tasks/edit", ["exports", "inboxfront/config/environment", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _environment, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    tasks: Ember.inject.service(),
    validation: Ember.inject.service(),
    isEditMode: false,
    queryParams: ['id'],
    id: null,
    showReply: false,
    showNote: false,
    showDeleteDialog: false,
    userDateTimeFormat: Ember.computed('model.companySettings', function () {
      var settings = this.get('model.companySettings');

      if (settings) {
        var dateformat = settings.dateformat;

        if (settings.timeformat === "24 hours") {
          return dateformat.toUpperCase() + " HH:mm A";
        } else {
          return dateformat.toUpperCase() + " hh:mm A";
        }
      }

      return "DD-MM-YYYY";
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        var attachment = result.data.file.attachment;
        controller.set('attachmentId', attachment.id);
        controller.updateAttachment(controller.get('task.id'), attachment);
      };
    }),
    options: Ember.computed(function () {
      var variables = '{"appLabel": "helpdesk", "model": "ticket"}';
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      return {
        paramName: "file",
        url: _environment.default.apollo.apiURL,
        clickable: true,
        autoProcessQueue: true,
        dictDefaultMessage: this.intl.t('drop_files', {
          count: 16
        }),
        method: "POST",
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': "uploadAttachment",
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': variables
        }
      };
    }),
    actions: {
      deleteTask: function deleteTask() {
        var _this = this;

        this.get('tasks').delete([this.get('task')]).then(function () {
          _this.transitionToRoute('tasks.index');
        });
      },
      deleteAttachment: function deleteAttachment(attachment) {
        var _this2 = this;

        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
          attachmentId: attachment.id
        }).then(function () {
          _this2.get('task.attachments').removeObject(attachment);
        });
      },
      onAddTag: function onAddTag() {
        this.saveTask();
      },
      onRemoveTag: function onRemoveTag() {
        this.saveTask();
      },
      updateDepartmentUsers: function updateDepartmentUsers() {
        var _this3 = this;

        var propertyId = this.get('task.propertyId');
        var departmentId = this.get('task.department.id');

        if (!propertyId || !departmentId) {
          return;
        }

        var hash = {
          departments: [departmentId],
          propertyIds: [propertyId]
        };
        this.ajax.query(_query.default.HAVE_TASK_ACCESS, 'haveAccess', hash).then(function (result) {
          result.unshift({
            id: 0,
            firstName: 'Unassigned',
            lastName: ''
          });

          _this3.set('departmentUsers', result);

          var selectedUser = _this3.get('task.assignedTo');

          if (selectedUser && !result.findBy('id', selectedUser.id)) {
            _this3.set('task.assignedTo', {
              id: 0,
              firstName: 'Unassigned',
              lastName: ''
            });
          }
        });
        this.get('task').validate();
      },
      updateAssignedTo: function updateAssignedTo(value) {
        this.set('task.assignedTo', value);
        this.get('task').validate();
      },
      checkTaskForm: function checkTaskForm() {
        this.get('task').validate();
      },
      editTask: function editTask() {
        this.set('isEditMode', true);
        this.get('task').validate();
      },
      endEditTask: function endEditTask() {
        this.set('isEditMode', false);
        this.get('task').restore();
      },
      onSaveTask: function onSaveTask() {
        this.saveTask();
      },
      showReplyEditor: function showReplyEditor() {
        this.set('newComment', '');
        this.set('showReply', true);
        this.set('showNote', false);
      },
      showNoteEditor: function showNoteEditor() {
        this.set('newComment', '');
        this.set('showReply', false);
        this.set('showNote', true);
      },
      hideEditor: function hideEditor() {
        this.set('showReply', false);
        this.set('showNote', false);
      },
      sendReply: function sendReply() {
        var _this4 = this;

        var comment = this.get('newComment');

        if (this.get('validation').trimValidation[0].validate(comment)) {
          this.set('showReply', false);
          var hash = {
            ticketId: this.get('task.id'),
            comment: comment,
            isNote: false
          };
          this.ajax.mutation(_mutation.default.ADD_COMMENT, 'addComment', hash, function (res) {
            _this4.get('task.comments').addObject(res.ticketComment);

            _this4.set('newComment', '');
          });
        }
      },
      sendNote: function sendNote() {
        var _this5 = this;

        var comment = this.get('newComment');

        if (this.get('validation').trimValidation[0].validate(comment)) {
          this.set('showNote', false);
          var hash = {
            ticketId: this.get('task.id'),
            comment: comment,
            isNote: true
          };
          this.ajax.mutation(_mutation.default.ADD_COMMENT, 'addComment', hash, function (res) {
            _this5.get('task.comments').addObject(res.ticketComment);

            _this5.set('newComment', '');
          });
        }
      }
    },
    saveTask: function saveTask() {
      var _this6 = this;

      this.get('tasks').save(this.get('task'), function () {
        _this6.get('paperToaster').show(_this6.get('task.category') === 'TICKET' ? 'Ticket updated' : 'Task updated');

        _this6.set('isEditMode', false);
      });
    },
    updatePopupUsers: function updatePopupUsers() {
      var _this7 = this;

      var hash = {
        propertyIds: [this.get('task.propertyId')]
      };
      this.ajax.query(_query.default.HAVE_TASK_ACCESS, 'haveAccess', hash).then(function (result) {
        var currentUser = _this7.get('sessionAccount.loggeduser.username');

        var filterUsers = (!result.errors ? result : []).filter(function (el) {
          el.id = el.pk; //'add-comment' uses in 'mentioned' for request 'addComment' pk, instead of id

          return el.username !== currentUser;
        });

        _this7.set('popupUsers', filterUsers);
      });
    },
    updateAttachment: function updateAttachment(taskId, attachment) {
      var _this8 = this;

      var hash = {
        appLabel: 'helpdesk',
        model: 'ticket',
        objectId: taskId,
        id: this.get('attachmentId')
      };
      this.ajax.query(_mutation.default.UPLOAD_ATTACHMENT, 'uploadAttachment', hash).then(function () {
        _this8.set('attachmentId', undefined);

        _this8.get('task.attachments').pushObject(attachment);

        _this8.saveTask();
      });
    }
  });

  _exports.default = _default;
});