define("inboxfront/controllers/chatslimit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      onChangeSelectCompany: function onChangeSelectCompany(val) {
        this.set('selectedProperty', val);
      }
    }
  });

  _exports.default = _default;
});