define("inboxfront/routes/companies/edit", ["exports", "inboxfront/routes/common", "inboxfront/helpers/countries-list", "inboxfront/helpers/timezones-list", "inboxfront/config/environment", "inboxfront/gql/query"], function (_exports, _common, _countriesList, _timezonesList, _environment, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('companies');
      } else {
        var properties = this.ajax.query(_query.default.COMPANY_PROPERTY);
        var groups = this.ajax.query(_query.default.CUSTOM_GROUPS);
        return Ember.RSVP.all([properties, groups]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              properties = _ref2[0],
              groups = _ref2[1];

          if (properties.errors) {
            return _this.transitionTo('companies');
          }

          var companies = properties;
          companies.forEach(function (company) {
            if (company.parent) {
              company.parent = companies.findBy('id', company.parent.id);
            }

            var children = [];
            company.children.forEach(function (child) {
              var modelCompany = companies.findBy('id', child.id);
              modelCompany && children.push(modelCompany);
            });
            company.children = children;
          });
          var company = companies.findBy('id', params.id);
          companies.removeObject(company);
          groups = !groups.errors ? groups : [];
          var companyGroupSet = [];
          company.companygroupSet.forEach(function (group) {
            var modelGroup = groups.findBy('id', group.id);
            modelGroup && companyGroupSet.push(modelGroup);
          });
          company.companygroupSet = companyGroupSet;

          var country = _countriesList.COUNTRIES_LIST.findBy('iso2', company.country);

          company.countryName = country ? country.name : company.country;
          var hours = [{
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: false,
            from: '08:00',
            to: '17:30'
          }, {
            on: false,
            from: '08:00',
            to: '17:30'
          }];

          if (company.workingHours) {
            try {
              hours = JSON.parse(company.workingHours);
            } catch (e) {
              console.error('ERROR PARSING WORKING HOURS', e);
            }
          }

          company.hours = hours;
          company.workingHours = JSON.stringify(hours);
          return {
            companies: companies,
            company: company,
            groups: groups
          };
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var url = model.company.logo !== null ? "".concat(_environment.default.mediaUrl).concat(model.company.logo.datafile) : '';
      controller.set('urlAttachment', url);
      controller.set('attachmentId', model.company.logo !== null ? model.company.logo.id : null);

      var country = _countriesList.COUNTRIES_LIST.findBy('iso2', model.company.country);

      var edit = {};
      ['title', 'code', 'address', 'postalCode', 'phoneNumber', 'website'].forEach(function (field) {
        edit[field] = model.company[field];
      });
      edit.country = country;
      edit.timezone = _timezonesList.TIMEZONES_LIST.findBy('tzCode', model.company.timezone);
      controller.set('edit', edit);
      model.company.accounts.edges.forEach(function (channel) {
        var node = channel.node;
        var temp;

        switch (node.application) {
          case 'TELEGRAM':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'telegram_contact_url';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'source-TELEGRAM';
            break;

          case 'WHATSAPP':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'whatsapp_number';
            });
            node.accountPhone = temp ? temp.node.value : '';
            node.icon = 'source-WHATSAPP';
            break;

          case 'FACEBOOK':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'facebook_contact_url';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'source-FACEBOOK';
            break;

          case 'TWITTER':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'twitter_contact_url';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'source-TWITTER';
            break;

          case 'LINE':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'line_contact_url';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'source-LINE';
            break;

          case 'BIGCOMMERCE':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'bc_store_domain';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'EC-bigcommerce-logo';
            break;

          case 'SHOPIFY':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'shop_name';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'EC-shopify-logo';
            break;

          case 'PIPEDRIVE':
            node.icon = 'CRM-pipedrive-logo';
            break;

          case 'WECHAT':
            node.icon = 'source-WECHAT';
            break;
        }
      });
    }
  });

  _exports.default = _default;
});