define("inboxfront/routes/companies/index", ["exports", "inboxfront/routes/common", "inboxfront/helpers/countries-list", "inboxfront/gql/query"], function (_exports, _common, _countriesList, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var properties = this.ajax.query(_query.default.COMPANY_PROPERTY);
      return Ember.RSVP.all([properties]).then(function (res) {
        if (!res[0].errors) {
          res[0].forEach(function (company) {
            var country = _countriesList.COUNTRIES_LIST.findBy("iso2", company.country);

            company.countryName = country ? country.name : company.country;
          });
        } else {
          res[0] = [];
        }

        return {
          companies: res[0]
        };
      });
    }
  });

  _exports.default = _default;
});