define("inboxfront/controllers/chats", ["exports", "moment", "inboxfront/config/environment", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _moment, _environment, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    emoji: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    chats: Ember.inject.service(),
    panelActions: Ember.inject.service(),
    kb: Ember.inject.service(),
    validation: Ember.inject.service(),
    isCreateDialogInserted: false,
    showCreateDialog: false,
    panelActive: 'assigned',
    noteUser: '',
    msgBody: '',
    messageToEdit: null,
    dateNotification: '',
    isOpenUserPopup: false,
    isOpenReply: false,
    isOpenReAssign: false,
    replyTab: true,
    isStarred: false,
    showSearch: false,
    showVisitorInfo: true,
    isChat: true,
    isLoadMessages: false,
    showReplyInput: false,
    isMessageEmpty: true,
    filterGuests: null,
    filterMessages: null,
    selectedBasicTab: 'Media',
    numberOfMsgs: 10,
    assignee: Ember.computed('selectedThread', function () {
      var thread = this.get('selectedThread');
      return thread.agent ? thread.agent.agentProfile.user : null;
    }),
    rooms: Ember.computed('chats.rooms.{[],status}', function () {
      return this.chats.rooms;
    }),
    selectedRoom: Ember.computed('chats.selectedRoom', function () {
      return this.chats.selectedRoom;
    }),
    placeholderText: Ember.computed('replyTab', 'selectedThread', function () {
      var nameGuest = this.get('selectedThread.guestContact.guest.firstName') || '';

      if (this.get('replyTab')) {
        return "".concat(this.intl.t('ur_msg_here'), " ").concat(nameGuest, "...");
      } else {
        return this.intl.t('mention_member');
      }
    }),
    isReAssign: Ember.computed('sessionAccount.loggeduser', 'selectedThread', function () {
      var group = this.get('sessionAccount.loggeduser.systemGroup.name');
      var name = this.get('sessionAccount.loggeduser.username');
      var agent = this.get('selectedThread.agent.agentProfile.user.username');
      return group === 'admin' || group === 'owner' || name === agent;
    }),
    agentInternal: Ember.computed('selectedRoom', function () {
      var selectedRoom = this.get('selectedRoom');

      if (selectedRoom) {
        if (selectedRoom.isGroup) {
          return selectedRoom;
        } else {
          if (selectedRoom.username === 'raincheck.bot') {
            return {
              _fullName: 'Braincheck',
              username: 'raincheck.bot'
            };
          }

          var username = this.get('sessionAccount.loggeduser.username');
          return (selectedRoom.participants || []).filter(function (user) {
            return user.username !== username;
          })[0];
        }
      }

      return '';
    }),
    agentNameGroupInternal: Ember.computed('selectedRoom', function () {
      var selectedRoom = this.get('selectedRoom');
      if (selectedRoom) return selectedRoom.participants;
      return '';
    }),
    leftCountAgentsChat: Ember.computed('agentNameGroupInternal', function () {
      var selectedRoom = this.get('selectedRoom');

      if (selectedRoom) {
        return selectedRoom.participants.length > 5 ? selectedRoom.participants.length - 5 : '';
      }

      return '';
    }),
    agentName: Ember.computed('selectedThread', function () {
      if (this.get('selectedThread.id')) {
        var agent = this.get('selectedThread').agent;

        if (agent) {
          return agent.agentProfile.user.firstName + " " + agent.agentProfile.user.lastName;
        } else {
          return 'Unassigned';
        }
      }

      return '';
    }),
    updateAttachments: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        var attachment = result.data.file.attachment;
        controller.set('msgBody', _environment.default.mediaUrl + attachment.datafile);
        controller.sendNewMessage(controller.get('isChat') ? attachment.id : attachment.pk);
      };
    }),
    optionsExternal: Ember.computed('isChat', function () {
      var variables = '{"appLabel": "message", "model": "message"}';
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      return {
        paramName: "file",
        url: _environment.default.apollo.apiURL,
        addRemoveLinks: false,
        autoProcessQueue: true,
        previewsContainer: "#upload-preview",
        clickable: "#addAttachment",
        acceptedFiles: "image/*,text/csv,application/pdf",
        method: "POST",
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': "uploadAttachment",
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': variables
        }
      };
    }),
    optionsInternal: Ember.computed('isChat', function () {
      var variables = '{"appLabel": "chans", "model": "chatmessage"}';
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      return {
        paramName: "file",
        url: _environment.default.apollo.apiURL,
        addRemoveLinks: false,
        autoProcessQueue: true,
        previewsContainer: "#upload-preview",
        clickable: "#addAttachment",
        acceptedFiles: "image/*,text/csv,application/pdf",
        method: "POST",
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': "uploadAttachment",
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': variables
        }
      };
    }),
    userLastLogin: Ember.computed('selectedRoom', function () {
      if (!this.get('selectedRoom')) return '';
      var userID = this.get('sessionAccount.loggeduser.pk');
      var selectedRoom = this.get('selectedRoom.participants').find(function (u) {
        return u.id !== userID;
      });
      var user = this.get('filterUsers').find(function (u) {
        return u.pk === selectedRoom.id;
      });
      var date = new Date();

      if (user && user.lastLogin !== null) {
        date = new Date(user.lastLogin);
      } else {
        date.setFullYear(date.getFullYear() - 1);
      }

      return date;
    }),
    knowledgeBases: Ember.computed('kb.bases.[]', function () {
      var bases = [{
        id: '*',
        name: 'All Knowledge Bases'
      }];
      bases.addObjects(this.get('kb.bases'));
      return bases;
    }),
    actions: {
      toggleVisitorInfo: function toggleVisitorInfo() {
        this.toggleProperty('showVisitorInfo');
      },
      scrollMessages: function scrollMessages() {
        this.triggerScrollMessages();
      },
      deleteUser: function deleteUser(user) {
        var room = this.get('selectedRoom');
        var hash = {
          action: 'delete',
          roomId: room.id,
          userId: user.id
        };
        this.ajax.query(_mutation.default.ADD_DELETE_ROOM_PARTICIPANT, 'addDeleteRoomParticipant', hash).then(function () {
          return room.participants.removeObject(user);
        });
      },
      addUser: function addUser(user) {
        var room = this.get('selectedRoom');
        var hash = {
          action: 'add',
          roomId: room.id,
          userId: user.id
        };
        this.ajax.query(_mutation.default.ADD_DELETE_ROOM_PARTICIPANT, 'addDeleteRoomParticipant', hash).then(function () {
          return room.participants.addObject(user);
        });
        this.set('showAddMemberDialog', false);
      },
      deleteChatMessage: function deleteChatMessage() {
        var messageId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        this.get('chats').deleteRoomMessage(this.get('selectedRoom'), messageId);
      },
      onDeleteEmoji: function onDeleteEmoji(data) {
        var messageId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        this.get('chats').deleteEmoji(this.get('selectedRoom'), data, messageId);
      },
      onEmojiSelect: function onEmojiSelect(emoji) {
        var messageId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        this.get('chats').addEmoji(this.get('selectedRoom'), emoji, messageId);
      },
      prepareToEdit: function prepareToEdit(message) {
        var txtInput = document.querySelector('.txt-input');

        if (txtInput) {
          var _message$node, _message$node2;

          txtInput.innerHTML = (_message$node = message.node) === null || _message$node === void 0 ? void 0 : _message$node.text;
          this.set('msgBody', (_message$node2 = message.node) === null || _message$node2 === void 0 ? void 0 : _message$node2.text);
        }

        this.set('messageToEdit', message);
      },
      updateDateRange: function updateDateRange(date) {
        this.set('dateNotification', new Date(date).toISOString());
      },
      openPopupUsers: function openPopupUsers() {
        //this.set('msgBody', '@');
        this.set('isOpenUserPopup', true);
      },
      openReply: function openReply() {
        this.openReplyTrigger();
      },
      openKBSearch: function openKBSearch() {
        this.set('showKBSearch', !this.get('showKBSearch'));

        if (!this.get('showKBSearch')) {
          this.set('showArticlePreview', false);
        }
      },
      searchGuest: function searchGuest(e) {
        var _this = this;

        var val = e.target.value;

        if (val.length > 2) {
          var hash = {
            page: 'GUEST',
            properties: [this.get('selectedThread.account.companyProperty.id')],
            q: val
          };
          this.ajax.query(_query.default.SEARCH_GUEST, 'search', hash).then(function (data) {
            return _this.set('filterGuests', data);
          });
        } else {
          this.set('filterGuests', []);
        }
      },
      changeGuest: function changeGuest(guest) {
        var currentGuest = this.get('selectedThread.guestContact.guest');
        var hash = {
          guestId: parseInt(currentGuest.id),
          propertyId: parseInt(currentGuest.companyProperty.id),
          esGuestId: guest.id,
          firstName: guest.firstName,
          lastName: guest.lastName,
          email: guest.email,
          phone: guest.phone
        };
        this.set('filterGuests', []);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash);
      },
      onMoveLink: function onMoveLink() {
        this.set('filterGuests', []);
      },
      searchMessage: function searchMessage() {
        var _this2 = this;

        var msg = this.get('searchMessage');

        if (msg.length > 2) {
          this.set('showSearch', true);
          var hash = {
            page: 'MESSAGE',
            properties: this.get('sessionAccount.loggeduser.properties').map(function (el) {
              return Number(el.id);
            }),
            q: msg
          };
          this.ajax.query(_query.default.SEARCH_MESSAGE, 'search', hash).then(function (data) {
            return _this2.set('filterMessages', data);
          });
        } else {
          this.set('filterMessages', []);
          this.set('showSearch', false);
        }
      },
      chooseMessage: function chooseMessage(msg) {
        this.set('showSearch', false);
        var username = this.get('sessionAccount.loggeduser.username');
        var filterThread = this.get('threads').findBy('node.pk', Number(msg.thread.id));
        var currentTab = 'unassigned';

        if (filterThread.node.agent !== null && username === filterThread.node.agent.agentProfile.user.username) {
          currentTab = 'assigned';
        } else if (filterThread.node.agent !== null && username !== filterThread.node.agent.agentProfile.user.username) {
          currentTab = 'other';
        }

        if (this.get('selectedThread.pk') && this.get('selectedThread.pk') === Number(msg.thread.id)) {
          this.scrollToMessage(msg.id);
        } else {
          this.callQueryThread(msg.thread.id, msg.id);
          this.get('panelActions').toggle('assigned');
          if (this.get('panelActive') !== currentTab) this.set('panelActive', currentTab);
        }
      },
      showStarredBlock: function showStarredBlock() {
        var _this3 = this;

        if (this.get('isStarred')) {
          this.set('isStarred', false);
          return;
        }

        this.set('isStarred', true);
        this.ajax.query(_query.default.STARRED_MESSAGES).then(function (messages) {
          _this3.set('starredList', messages);
        });
      },
      hideStarredBlock: function hideStarredBlock() {
        this.set('isStarred', false);
      },
      sendStatusStar: function sendStatusStar(message) {
        var _this4 = this;

        var hash = {
          messageId: message.id,
          starred: false
        };
        this.ajax.query(_mutation.default.STARRED_MESSAGE, 'starredMessage', hash).then(function () {
          if (_this4.get('selectedThread.id') === message.thread.id) {
            var filterThread = _this4.get('messages').findBy('id', message.id);

            Ember.set(filterThread, 'node.starredUser', false);
            Ember.set(filterThread, 'node.starred', false);
          }

          _this4.get('starredList').removeObject(message);
        });
      },
      //TODO rework 'chat-note-user-popup', try to integrate it to component 'input-chat'
      chooseUser: function chooseUser(user) {
        //const msg = this.get('msgBody');
        var this_ = this; //const username = msg + user.firstName + ' ';

        if (this.get('isChat')) {
          this.set('delimiter', '@' + user.firstName);
          this.set('noteUser', user); //this.set('msgBody', username);
        }

        var span = document.createElement('span');
        span.className = 'wrap-user-group';
        span.setAttribute('contenteditable', 'true');
        span.innerHTML = "<span class=\"user-group\" data-id=\"".concat(user.id, "\" contenteditable=\"true\">").concat(user.firstName, "</span>");
        document.querySelector('.txt-input').appendChild(span);
        document.querySelector('.txt-input').innerHTML += '&nbsp;,';
        document.querySelector('.txt-input').dispatchEvent(new Event('input'));
        document.querySelector('.user-group').addEventListener('input', function (event) {
          event.stopPropagation();

          if (event.inputType === 'deleteContentBackward') {
            this_.get('mentioned').removeObject(event.target.getAttribute('data-id'));
            event.target.parentNode.remove();
          }
        });
        this.get('mentioned').pushObject(user.id);
        this.set('isOpenUserPopup', false);
      },
      chooseText: function chooseText(text) {
        this.set('isOpenReply', false);
        this.set('msgBody', text);
      },
      moveThread: function moveThread(note) {
        var _this5 = this;

        var user = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        if (user.id) {
          var filterRoom = this.chats.rooms.filter(function (el) {
            return el.participants.length === 2 && el.participants.some(function (el) {
              return el.id === user.id;
            });
          });
          this.get('panelActions').toggle('direct');

          if (filterRoom.length) {
            this.triggerToggleChat(filterRoom[0].id);
          } else {
            var hash = {
              isPrivate: true,
              isGroup: false,
              participants: user.id.split()
            };
            this.ajax.query(_mutation.default.CREATE_CHAT_ROOM, 'createChatRoom', hash).then(function (data) {
              _this5.triggerAddRoom(data.room);

              _this5.triggerToggleChat(data.room.id);
            });
          }
        } else {
          this.get('panelActions').toggle('assigned');
          this.set('isChat', true);
          this.set('selectedRoom', null);
          this.set('panelActive', 'assigned');
          this.callQueryThread(note.verb, note.actionObjectObjectId);
        }
      },
      moveStarredThread: function moveStarredThread(msg) {
        var username = this.get('sessionAccount.loggeduser.username');
        var filterThread = this.get('threads').findBy('node.id', msg.thread.id);
        var currentTab = 'unassigned';

        if (filterThread.node.agent !== null && username === filterThread.node.agent.agentProfile.user.username) {
          currentTab = 'assigned';
        } else if (filterThread.node.agent !== null && username !== filterThread.node.agent.agentProfile.user.username) {
          currentTab = 'other';
        }

        if (this.get('selectedThread.id') === msg.thread.id) {
          this.scrollToMessage(msg.pk);
        } else {
          this.callQueryThread(msg.thread.id, msg.pk);
          this.get('panelActions').toggle('assigned');
          if (this.get('panelActive') !== currentTab) this.set('panelActive', currentTab);
        }
      },
      closeGroupChat: function closeGroupChat() {
        this.set('showGroupDialog', false);
      },
      showGroupChat: function showGroupChat(bool, e) {
        e.stopPropagation();
        e.preventDefault();
        this.set('isGroupPopup', bool);
        this.set('showGroupDialog', true);
      },
      showAssignDialog: function showAssignDialog() {
        this.set('isOpenReAssign', true);
      },
      pickThread: function pickThread(thread) {
        if (this.get('selectedThread.id') === thread.node.id) {
          return;
        }

        this.set('bookmarksShown', false);
        this.set('replyTab', true);
        this.set('isChat', true);
        this.set('selectedRoom', null);
        this.callQueryThread(thread.node.id);
        thread.node.chatsession && this.callHintMessage(thread.node.chatsession.id);
        var userID = this.get('sessionAccount.loggeduser.pk');

        if (thread.node.unreadCount !== 0 && thread.node.agent !== null && thread.node.agent.agentProfile.user.pk === userID) {
          this.ajax.mutation(_mutation.default.READ_THREAD, 'readThread', {
            threadId: thread.node.id
          });
        }
      },
      selectRoom: function selectRoom(room) {
        this.set('bookmarksShown', false);
        this.triggerToggleChat(room.id);
      },
      keyUpEventInput: function keyUpEventInput(keyCode, key) {
        var _this6 = this;

        /*if (this.get('selectedThread.chatsession.id')) {
            if (event.target.outerText === 1 || event.target.outerText % 4 === 0) {
                this.ajax.queryTyping(this.get('selectedThread.chatsession.id'), true)
            }
             if (event.target.outerText === 0) {
                this.ajax.queryTyping(this.get('selectedThread.chatsession.id'), false);
            }
        }*/
        if (!this.get('isChat')) {
          if (key === '@') {
            var filterUsers = this.get('selectedRoom.participants').filter(function (el) {
              return el.username !== _this6.get('sessionAccount.loggeduser.username');
            });
            this.set('popupUsers', filterUsers);
            this.set('isOpenUserPopup', true);
          }

          if (keyCode === 13) {
            this.sendNewMessage();
          } else {
            this.get('chats').typing(this.get('selectedRoom'));
          }
        } else {
          if (key === '@' && !this.get('replyTab')) {
            this.set('isOpenUserPopup', true);
          }

          if (key === '/') {
            this.openReplyTrigger();
          }

          if (keyCode === 13) {
            this.sendNewMessage();
          }
        }
      },
      sendMessage: function sendMessage() {
        this.sendNewMessage();
      },
      onGuestCreate: function onGuestCreate() {
        var currentGuest = this.get('selectedThread.guestContact.guest');
        var guestInfo = this.get('guestInfo');
        var hash = {
          guestId: currentGuest.id,
          propertyId: currentGuest.companyProperty.id,
          firstName: guestInfo.firstName,
          lastName: guestInfo.lastName,
          primaryEmail: guestInfo.primaryEmail,
          personalPhone: guestInfo.personalPhone
        };
        this.set('filterGuests', []);
        this.countGuestForm;
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash);
      },
      toggleTabReply: function toggleTabReply() {
        this.toggleProperty('replyTab');
      },
      updateThreadQuery: function updateThreadQuery(id) {
        this.updateThreadQueryTrigger(id);
      },
      updateMessagesThread: function updateMessagesThread(data) {
        this.get('messages').pushObject(data.message);
        this.scrollToMessage(data.message.pk);
      },
      deleteRoom: function deleteRoom(room) {
        var _this7 = this;

        this.get('chats').deleteRoom(room).then(function () {
          _this7.get('paperToaster').show(_this7.intl.t('room_deleted'));

          _this7.set('showDeleteRoomDialog', false);
        });
      },
      addRoom: function addRoom(room) {
        this.triggerAddRoom(room);
      },
      updateChatDescription: function updateChatDescription() {
        var data = {
          roomId: this.get('selectedRoom.id'),
          description: this.get('selectedRoom.description')
        };
        this.ajax.query(_mutation.default.UPDATE_CHAT_DESCRIPTION, 'updateChatRoom', data);
      },
      showAddMember: function showAddMember() {
        this.set('showAddMemberDialog', true);
      },
      closeAddMember: function closeAddMember() {
        this.set('showAddMemberDialog', false);
      },
      pinMessage: function pinMessage(msgId, msg) {
        var _this8 = this;

        this.ajax.query(_mutation.default.PIN_MESSAGE, 'pinItem', {
          messageId: msgId
        }).then(function () {
          var room;

          if (msg) {
            room = _this8.get('rooms').findBy('id', msg.room.id);
            Ember.set(msg, 'pinned', !msg.pinned);
          }

          if (!room) {
            room = _this8.get('selectedRoom');
          }

          var message = room.messages.edges.findBy('node.id', msgId);
          message && Ember.set(message, 'node.pinned', !message.node.pinned);
        });
      },
      bookmarkMessage: function bookmarkMessage(msg) {
        var _this9 = this;

        if (msg.bookmarked) {
          this.ajax.mutation(_mutation.default.DELETE_BOOKMARK, 'deleteBookmark', {
            messageId: msg.id
          }, function () {
            var bookmarks = _this9.get('chats.bookmarks');

            var bookmark = bookmarks.findBy('message.id', msg.id);
            bookmark && bookmarks.removeObject(bookmark);
            Ember.set(msg, 'bookmarked', false);
          });
        } else {
          this.ajax.mutation(_mutation.default.ADD_BOOKMARK, 'bookmarkMessage', {
            messageId: msg.id
          }, function () {
            _this9.get('chats.bookmarks').addObject({
              message: msg
            });

            Ember.set(msg, 'bookmarked', true);
            Ember.set(msg, 'room', _this9.get('selectedRoom') || {});
          });
        }
      },
      setReminder: function setReminder(msg, period) {
        var _this10 = this;

        return;
        var time = (0, _moment.default)().add(1, 'm');
        var hash = {
          // isActive: $isActive
          messageId: msg.id,
          remindAt: time.toISOString() // reminderId: $reminderId
          // status: $status

        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_MESSAGE_REMINDER, 'createUpdateMessageReminder', hash, function (res) {
          _this10.ajax.query(_query.default.GET_MESSAGE_REMINDERS, 'reminders').then(function (res) {
            var reminders = {};
            (Array.isArray(res) ? res : []).forEach(function (reminder) {
              if (!reminders[reminder.message.room.id]) {
                reminders[reminder.message.room.id] = [];
              }

              reminders[reminder.message.room.id].addObject(reminder);
            });

            _this10.set('model.reminders', reminders);

            _this10.set('model.remindersById', Array.isArray(res) ? res : []);

            if (_this10.get('selectedRoom')) {
              _this10.set('selectedRoom.reminders', reminders[_this10.get('selectedRoom.id')]);
            }
          });
        });
        /**/
      },
      deleteMessageReminder: function deleteMessageReminder(reminder) {
        // this.ajax.mutation(Mutation.DELETE_MESSAGE_REMINDER, 'deleteMessageReminder', {reminderId: +reminder.id}, (res) => {
        //     console.log('DELETE REM', res);
        //     let reminders = this.get('model.reminders');
        //     if (reminders[reminder.message.room.id]) {
        //         reminders[reminder.message.room.id].removeObject(reminder);
        //     }
        // });/**/
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_MESSAGE_REMINDER, 'createUpdateMessageReminder', {
          reminderId: reminder.id,
          status: 'DELETED'
        }, function (res) {
          Ember.set(reminder, 'status', 'DELETED');
        });
        /**/
      },
      deactivateMessageReminder: function deactivateMessageReminder(reminder) {
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_MESSAGE_REMINDER, 'createUpdateMessageReminder', {
          reminderId: reminder.id,
          status: 'EDITED',
          isActive: false
        }, function (res) {
          Ember.set(reminder, 'status', 'EDITED');
          Ember.set(reminder, 'isActive', false);
        });
        /**/
      },
      onEmojiPickerToggle: function onEmojiPickerToggle(pickerId) {
        if (this.get('chosenPicker') === pickerId) {
          this.set('chosenPicker', '');
        } else {
          this.set('chosenPicker', pickerId);
        }
      },
      showBookmarks: function showBookmarks() {
        this.set('bookmarksShown', true);
      },
      showCreateTaskDialog: function showCreateTaskDialog() {
        var _this11 = this;

        var selectedThread = this.get('selectedThread');

        if (!selectedThread) {
          return;
        }

        var additionalData = {
          category: 'TICKET'
        };
        var readOnlyFields = {
          category: true
        };
        var sources = ['NA', 'PHONE', 'EMAIL', 'LIVECHAT', 'WECHAT', 'TELEGRAM', 'LINE', 'TWITTER', 'WHATSAPP', 'FACEBOOK'];

        if (sources.indexOf(selectedThread.account.application) >= 0) {
          additionalData.source = selectedThread.account.application;
          readOnlyFields.source = true;
        }

        additionalData.guest = selectedThread.guestContact.guest;
        additionalData.companyProperty = selectedThread.guestContact.guest.companyProperty;
        readOnlyFields.property = true;

        if (additionalData.guest) {
          readOnlyFields.guest = true; //disable search contact

          readOnlyFields.firstName = !!additionalData.guest.firstName;
          readOnlyFields.lastName = !!additionalData.guest.lastName;
          readOnlyFields.email = !!additionalData.guest.primaryEmail;
          readOnlyFields.phone = !!additionalData.guest.personalPhone;
        }

        this.set('additionalData', additionalData);
        this.set('readOnlyFields', readOnlyFields);
        this.set('isCreateDialogInserted', true);
        setTimeout(function () {
          return _this11.set('showCreateDialog', true);
        }, 300);
      },
      onTaskCreate: function onTaskCreate(task) {
        this.get('paperToaster').show(this.intl.t('task_created'));
        this.set('showCreateDialog', false); // this.transitionToRoute('tasks.edit', {queryParams: {id: task.id}});
      },
      createRedirectChat: function createRedirectChat(link, isShowPopup, guest) {
        this.set('showCreateRedirectChat', false);

        if (isShowPopup) {
          this.set('showRedirectChatDialog', true);
          this.set('chatLink', link);
        } else {
          this.set('msgBody', link);
          this.set('selectedThread.guestContact.guest', guest);
          this.set('guestInfo', guest);
        }
      },
      redirectToContact: function redirectToContact(isShown, isThread) {
        this.set('showCreateRedirectChat', isShown);
        this.set('isThread', isThread);
      },
      createCancellation: function createCancellation(data) {
        var _this12 = this;

        var additionalData = {
          category: 'TICKET',
          source: 'NA',
          guest: data.guest,
          companyProperty: data.guest.companyProperty,
          title: "Order ".concat(data.order.name, " cancellation"),
          description: "Reason for cancellation: ".concat(data.reason)
        };

        if (!data.order.name || data.order.name === '-no-name-') {
          additionalData.title = "Order #".concat(data.order.id, " cancellation");
        }

        var readOnlyFields = {
          category: true,
          property: true
        };

        if (additionalData.guest) {
          readOnlyFields.guest = true; //disable search contact

          readOnlyFields.firstName = !!additionalData.guest.firstName;
          readOnlyFields.lastName = !!additionalData.guest.lastName;
          readOnlyFields.email = !!additionalData.guest.primaryEmail;
          readOnlyFields.phone = !!additionalData.guest.personalPhone;
        }

        this.set('additionalData', additionalData);
        this.set('readOnlyFields', readOnlyFields);
        this.set('isCreateDialogInserted', true);
        setTimeout(function () {
          return _this12.set('showCreateDialog', true);
        }, 300);
        this.updateECommerceOrders(this.get('selectedThread'));
      },
      createRefund: function createRefund(data) {
        var _this13 = this;

        var additionalData = {
          category: 'TICKET',
          source: 'NA',
          guest: data.guest,
          companyProperty: data.guest.companyProperty,
          title: "Order ".concat(data.order.name, " refund"),
          description: "Reason for refund: ".concat(data.reason)
        };

        if (!data.order.name || data.order.name === '-no-name-') {
          additionalData.title = "Order #".concat(data.order.id, " refund");
        }

        var readOnlyFields = {
          category: true,
          property: true
        };

        if (additionalData.guest) {
          readOnlyFields.guest = true; //disable search contact

          readOnlyFields.firstName = !!additionalData.guest.firstName;
          readOnlyFields.lastName = !!additionalData.guest.lastName;
          readOnlyFields.email = !!additionalData.guest.primaryEmail;
          readOnlyFields.phone = !!additionalData.guest.personalPhone;
        }

        this.set('additionalData', additionalData);
        this.set('readOnlyFields', readOnlyFields);
        this.set('isCreateDialogInserted', true);
        setTimeout(function () {
          return _this13.set('showCreateDialog', true);
        }, 300);
        this.updateECommerceOrders(this.get('selectedThread'));
      },
      loadECommerceCatalog: function loadECommerceCatalog() {
        this.updateECommerceCatalog(this.get('selectedThread'));
      },
      sendECommerceProductCardsToChat: function sendECommerceProductCardsToChat(cards) {
        var _this14 = this;

        var hash = {
          subject: 'shopify_product_card',
          body: JSON.stringify(cards),
          threadId: this.get('selectedThread.id'),
          forwarded: false,
          application: this.get('selectedThread.account.application')
        };
        this.ajax.query(_mutation.default.CREATE_MESSAGE, 'createMessage', hash).then(function (res) {
          var cards;

          try {
            cards = JSON.parse(res.message.body);
          } catch (e) {
            cards = [];
          }

          if (!Array.isArray(cards)) {
            cards = [];
          }

          res.message.productCards = cards;

          _this14.get('messages').pushObject(res.message);

          _this14.scrollToMessage(res.message.pk);

          _this14.set('dateNotification', '');
        });
      },
      updateKbSearch: function updateKbSearch(value) {
        this.set('kbSearchText', value);

        this._searchKb();
      },
      updateKbSearchBase: function updateKbSearchBase(base) {
        this.set('kbSearchBase', base);

        this._searchKb();
      },
      showPreview: function showPreview(article) {
        var body = '';

        try {
          body = JSON.parse(article.body);
        } catch (e) {}

        this.set('selectedArticle', article);
        this.set('selectedArticleBody', body);
        this.set('showArticlePreview', true);
        this.set('selectedBase', article.categories[0].section.base);
      },
      closePreview: function closePreview() {
        this.set('selectedArticle', null);
        this.set('selectedBase', null);
        this.set('selectedArticleBody', '');
        this.set('showArticlePreview', false);
      },
      sendLinkToChat: function sendLinkToChat(article) {
        var _this15 = this;

        var base = article.categories[0].section.base;
        var hash = {
          subject: 'kb_article',
          body: "http://".concat(base.domainName, ".raincheck.co/").concat(article.categories[0].slug, "/").concat(article.slug),
          threadId: this.get('selectedThread.id'),
          forwarded: false,
          application: this.get('selectedThread.account.application')
        };
        this.set('searchArticles', []);
        this.set('kbSearchText', '');
        this.ajax.query(_mutation.default.CREATE_MESSAGE, 'createMessage', hash).then(function (res) {
          var data;

          try {
            data = JSON.parse(res.message.body);
          } catch (e) {
            data = {};
          }

          res.message.articleData = data;

          _this15.get('messages').pushObject(res.message);

          _this15.scrollToMessage(res.message.pk);

          _this15.set('dateNotification', '');
        });
      },
      replyInThread: function replyInThread(message) {
        this._replyInThread(this.get('selectedRoom'), message);
      },
      goToMessage: function goToMessage(msg) {
        this.set('bookmarksShown', false);
        this.triggerToggleChat(msg.room.id, msg);
      },
      reAssignAgent: function reAssignAgent(agent) {
        this.set('isOpenReAssign', false);
        this.set('selectedThread.isAssigned', agent.pk === this.sessionAccount.loggeduser.pk);
      }
    },
    _replyInThread: function _replyInThread(room, msg) {
      this.set('replyToMessage', msg);
      this.set('replyToRoom', room);
      this.set('isShowReplyInThread', true);
    },
    _searchKb: function _searchKb() {
      var _this16 = this;

      var base = this.get('kbSearchBase');
      var text = this.get('kbSearchText').toLowerCase();
      var companyId = this.get('sessionAccount.loggeduser.staffprofile.company.pk');
      var hash = {
        page: 'KB',
        level: 'article',
        q: text,
        companyId: companyId
      };

      if (base.id !== '*') {
        hash.domain = base.domainName;
      }

      var result = [];
      this.set('searchLoading', '');

      if (text.length > 1) {
        this.set('searchLoading', 'Loading...');
        setTimeout(function () {
          (base.id !== '*' ? [base] : _this16.get('kb.bases')).forEach(function (base) {
            if (result.length >= 5) {
              return;
            }

            base.articles.forEach(function (article) {
              if (result.length >= 5) {
                return;
              }

              if (article.title.toLowerCase().indexOf(text) >= 0) {
                result.addObject(article);
              }
            });
          });

          if (result.length) {
            _this16.set('searchLoading', '');
          } else {
            _this16.set('searchLoading', 'No articles found.');
          }

          _this16.set('searchArticles', result);
        }, 500);
        this.ajax.query(_query.default.SEARCH_ARTICLE, 'search', hash).then(function (data) {// this.set('searchArticles', data);
          // if (data.length) {
          // this.set('searchLoading', '');
          // } else {
          //     this.set('searchLoading', 'No articles found.');
          // }
        });
        /**/
      } else {
        this.set('searchArticles', []);
      }
    },
    triggerAddRoom: function triggerAddRoom(room) {
      this.chats.addRoom(room);
    },
    triggerScrollMessages: function triggerScrollMessages() {
      var _this17 = this;

      var trigger = document.querySelectorAll('.loader-trigger');

      if (trigger.length) {
        if (this.isElementInViewport(trigger[0])) {
          this.set('showMsgLoader', true);
          setTimeout(function () {
            _this17.set('showMsgLoader', false);

            _this17.set('numberOfMsgs', _this17.get('numberOfMsgs') + 5);
          }, 2000);
        }
      }

      var elements = document.querySelectorAll('.unread.message-sent'); //TODO rework scroll check

      if (elements.length) {
        elements.forEach(function (item) {
          if (_this17.isElementInViewport(item)) {
            var id = item.id.substr(3);

            _this17.readMessage(id, item);
          }
        });
      }
    },
    triggerToggleChat: function triggerToggleChat(roomId) {
      var _this18 = this;

      var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (this.get('selectedRoom.id') === roomId) {
        if (msg) {
          //TODO remove duplication
          if (msg.inReplyTo) {
            var room = this.get('chats.rooms').findBy('id', roomId);
            var inReplyTo = room.messagesNew.findBy('id', msg.inReplyTo.id);

            this._replyInThread(room, inReplyTo);
          }

          setTimeout(function () {
            _this18.scrollToMessage(msg.id);
          }, 200);
        }

        return;
      }

      this.set('isLoadMessages', true);
      this.get('chats').loadRoom(roomId).then(function (room) {
        _this18.set('isLoadMessages', false);

        _this18.set('numberOfMsgs', 10);

        _this18.set('isChat', false);

        _this18.set('selectedThread', null);

        _this18.set('roomId', room.id);

        _this18.set('noteUser', '');

        _this18.set('dateNotification', '');

        _this18.set('selectedRoom', room);

        if (msg) {
          //TODO remove duplication
          if (msg.inReplyTo) {
            var _room = _this18.get('chats.rooms').findBy('id', roomId);

            var _inReplyTo = _room.messagesNew.findBy('id', msg.inReplyTo.id);

            _this18._replyInThread(_room, _inReplyTo);
          }

          setTimeout(function () {
            _this18.scrollToMessage(msg.id);
          }, 200);
        } else if (room.firstUnreadMessage) {
          setTimeout(function () {
            document.getElementById('new-messages-divider') && document.getElementById('new-messages-divider').scrollIntoView({
              behavior: 'smooth'
            });
          }, 500);
        } else {
          _this18.scrollToMessage(room.lastMessage ? room.lastMessage.id : null);

          setTimeout(function () {
            _this18.triggerScrollMessages();
          }, 1000);
        }
      });
    },
    callQueryThread: function callQueryThread(threadId, messageId) {
      var _this19 = this;

      this.set('isLoadMessages', true);
      this.ajax.query(_query.default.THREAD, 'thread', {
        threadId: threadId
      }).then(function (thread) {
        _this19.stopUpdateECommerceStoreInfo();

        if (thread.eCommerce === 'shopify' || thread.eCommerce === 'bigcommerce') {
          _this19.updateECommerceStoreInfo(thread);
        }

        var userID = _this19.get('sessionAccount.loggeduser.pk');

        thread.isAssigned = thread.agent !== null && thread.agent.agentProfile.user.pk === userID;

        _this19.set('selectedThread', thread);

        _this19.set('guestInfo', thread.guestContact.guest);

        _this19.updatePopupUsersThread(thread.account);
      });
      this.ajax.query(_query.default.MESSAGES, 'messages', {
        threadId: threadId
      }).then(function (data) {
        var lastMessageId = data.length ? data[0].pk : null;

        _this19.set('isLoadMessages', false);

        data.forEach(function (m) {
          if (m.subject === 'shopify_product_card') {
            var cards;

            try {
              cards = JSON.parse(m.body);
            } catch (e) {
              cards = [];
            }

            if (!Array.isArray(cards)) {
              cards = [];
            }

            m.productCards = cards;
          } else if (m.subject === 'kb_article') {
            var _data;

            try {
              _data = JSON.parse(m.body);
            } catch (e) {
              _data = {};
            }

            m.articleData = _data;
          }
        });

        _this19.set('messages', data.reverse());

        _this19.scrollToMessage(messageId || lastMessageId);
      });
    },
    updateECommerceStoreInfo: function updateECommerceStoreInfo(thread) {
      var _this20 = this;

      var hash = {
        page: 'INFORMATION',
        q: thread.guestContact.guest.companyProperty.id,
        properties: this.get('sessionAccount.loggeduser.properties').map(function (p) {
          return p.id;
        }),
        platform: thread.eCommerce
      };
      this.ajax.query(_query.default.SEARCH_SHOP_INFO, 'search', hash).then(function (data) {
        _this20.set('storeInfo', data && data[0] || {});

        _this20.updateECommerceOrders(thread);

        _this20.updateECommerceCart(thread);
      });
    },
    stopUpdateECommerceStoreInfo: function stopUpdateECommerceStoreInfo() {
      this.get('ECommerceOrdersTimer') && clearTimeout(this.get('ECommerceOrdersTimer'));
      this.get('ECommerceCartTimer') && clearTimeout(this.get('ECommerceCartTimer'));
    },
    updateECommerceOrders: function updateECommerceOrders(thread) {
      var _this21 = this;

      var hash = {
        page: 'ORDER',
        q: thread.guestContact.guest.id,
        properties: this.get('sessionAccount.loggeduser.properties').map(function (p) {
          return p.id;
        }),
        platform: thread.eCommerce
      };
      this.ajax.query(_query.default.SEARCH_ORDERS, 'search', hash).then(function (data) {
        var orders = data || [];
        orders.forEach(function (o) {
          if (!o) {
            return;
          }

          o.status = 'open';

          if (thread.eCommerce === 'shopify') {
            if (o.closedAt && o.closedAt !== '0001-01-01T00:00:00') {
              //elastic search returns '0001-01-01T00:00:00' instead of null
              o.status = 'closed';
            } else if (o.cancelledAt && o.cancelledAt !== '0001-01-01T00:00:00') {
              //elastic search returns '0001-01-01T00:00:00' instead of null
              o.status = 'cancelled';
            }
          } else if (thread.eCommerce === 'bigcommerce') {
            if (o.financialStatus === 'Completed') {
              o.status = 'closed';
              o.financialStatus = '';
            } else if (o.financialStatus === 'Cancelled') {
              o.status = 'cancelled';
              o.financialStatus = '';
            }
          }

          o.products.forEach(function (p) {
            p.quantity = +p.quantity;
            p.totalPrice = +p.price * p.quantity;
          });
        });

        _this21.set('guestOrders', orders);
      });
      hash = {
        page: 'SHIPPING',
        q: thread.guestContact.guest.id,
        properties: this.get('sessionAccount.loggeduser.properties').map(function (p) {
          return p.id;
        }),
        platform: thread.eCommerce
      };
      this.ajax.query(_query.default.SEARCH_SHIPPING, 'search', hash).then(function (data) {});
      this.get('ECommerceOrdersTimer') && clearTimeout(this.get('ECommerceOrdersTimer'));
      this.set('ECommerceOrdersTimer', setTimeout(function () {
        return _this21.updateECommerceOrders(thread);
      }, 5000));
    },
    updateECommerceCart: function updateECommerceCart(thread) {
      var _this22 = this;

      var hash = {
        page: 'CART',
        q: thread.guestContact.guest.id,
        properties: this.get('sessionAccount.loggeduser.properties').map(function (p) {
          return p.id;
        }),
        platform: thread.eCommerce
      };
      this.ajax.query(_query.default.SEARCH_CART, 'search', hash).then(function (data) {
        _this22.set('guestCart', data[0] || {});
      });
      this.get('ECommerceCartTimer') && clearTimeout(this.get('ECommerceCartTimer'));
      this.set('ECommerceCartTimer', setTimeout(function () {
        return _this22.updateECommerceCart(thread);
      }, 5000));
    },
    updateECommerceCatalog: function updateECommerceCatalog(thread) {
      var _this23 = this;

      var hash = {
        page: 'PRODUCT',
        q: this.get('storeInfo.domain') || 'unknown-shop-domain',
        properties: this.get('sessionAccount.loggeduser.properties').map(function (p) {
          return p.id;
        }),
        platform: thread.eCommerce
      };
      this.ajax.query(_query.default.SEARCH_PRODUCTS, 'search', hash).then(function (data) {
        _this23.set('shopProducts', data);
      });
    },
    updatePopupUsersThread: function updatePopupUsersThread(account) {
      var _this24 = this;

      var hash = {
        propertyId: account.companyProperty.id,
        application: account.application
      };
      this.ajax.query(_query.default.HAVE_INBOX_ACCESS, 'haveInboxAccess', hash).then(function (res) {
        var currentUser = _this24.get('sessionAccount.loggeduser.username');

        var filterUsers = (!res.errors ? res : []).filter(function (el) {
          return el.username !== currentUser;
        });

        _this24.set('popupUsers', filterUsers);
      });
    },
    callHintMessage: function callHintMessage(sessionId) {
      var _this25 = this;

      this.ajax.queryHint(sessionId).then(function (data) {
        if (data.hint) {
          var _document$querySelect;

          _this25.set('chats.typingUser', data.hint);

          (_document$querySelect = document.querySelector('.typing-text')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList.add('blink');
        } else {
          var _document$querySelect2;

          (_document$querySelect2 = document.querySelector('.typing-text')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.classList.remove('blink');
        }

        if (_this25.target.currentPath === 'chats') Ember.run.later(function () {
          return _this25.callHintMessage(sessionId);
        }, 1000);
      });
    },
    openReplyTrigger: function openReplyTrigger() {
      var _this26 = this;

      this.set('isOpenReply', true);
      this.ajax.query(_query.default.QUICK_RESPONSE).then(function (res) {
        _this26.set('replyList', !res.errors ? res.edges : []);
      });
    },
    scrollToMessage: function scrollToMessage(id) {
      if (id !== null) {
        if (this.get('isChat')) {
          setTimeout(function () {
            document.getElementById('message' + id) && document.getElementById('message' + id).scrollIntoView({
              behavior: 'smooth'
            });
          }, 500);
        } else {
          setTimeout(function () {
            document.getElementById('mes' + id) && document.getElementById('mes' + id).scrollIntoView({
              behavior: 'smooth'
            });
          }, 500);
        }
      }
    },
    sendNewMessage: function sendNewMessage() {
      var _this27 = this;

      var attachmentId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var input = document.querySelector('.txt-input');
      var delimiter = this.get('delimiter');
      var innerText = input.innerHTML.replace(delimiter, '').trim();
      var filterText = innerText.replaceAll(' contenteditable="true"', '');

      try {
        if (this.get('isChat')) {
          var hash = {
            body: filterText,
            threadId: this.get('selectedThread.id'),
            forwarded: false,
            application: this.get('selectedThread.account.application')
          };
          this.set('isLoadingMessage', true);

          if (attachmentId != null) {
            hash.attachmentId = attachmentId;
            hash.msgType = 'img';
          }

          if (this.get('previewLink')) {
            hash.extraData = JSON.stringify(this.get('previewLink'));
          }

          if (!this.get('replyTab') && this.get('dateNotification') !== '') {
            hash.date = this.get('dateNotification');
          }

          if (!this.get('replyTab') && this.get('noteUser.id')) {
            hash.mentionedUserId = this.get('noteUser.id');
          }

          if (this.get('showReplyInput')) {
            hash.inReplyTo = this.get('selectedMessage.id');
            hash.forwarded = true;
          }

          if (!this.get('replyTab')) hash.internal = true;
          this.ajax.query(_mutation.default.CREATE_MESSAGE, 'createMessage', hash).then(function (res) {
            _this27.get('messages').pushObject(res.message);

            _this27.scrollToMessage(res.message.pk); //this.set('msgBody', '');


            input.innerHTML = '';

            _this27.set('isMessageEmpty', true);

            _this27.set('showReplyInput', false);

            _this27.set('dateNotification', '');
          }).finally(function () {
            _this27.set('isLoadingMessage', false);

            _this27.set('previewLink', false);
          });
        } else {
          var roomId = this.get('roomId');
          this.set('isLoadingMessage', true);
          var messages = this.get('selectedRoom.messages.edges');
          var msg = attachmentId === null ? window.linkify(filterText, {
            callback: function callback(text, href) {
              if (href) {
                return "<a target=\"_blank\" class=\"link\" href=\"".concat(href, "\" title=\"").concat(href, "\">").concat(text, "</a>");
              } else {
                return text;
              }
            }
          }) : filterText;
          var data = {
            id: Math.ceil(Math.random() * 100000 + 10000),
            text: msg,
            created: new Date(),
            modified: new Date(),
            user: this.get('sessionAccount.loggeduser'),
            read: false,
            extraText: '',
            status: '',
            pinned: false
          };
          messages.pushObject({
            node: data
          });

          if (data.inReplyTo) {
            var message = messages.findBy('node.id', data.inReplyTo.id);

            if (message) {
              if (!Array.isArray(message.node.replies)) {
                Ember.set(message.node, 'replies', []);
              }

              message.node.replies.addObject(data);
            }
          }

          this.scrollToMessage(data.id);
          var room = this.get('chats.rooms').findBy('id', roomId);

          if (room) {
            Ember.set(room, 'lastMessage', data);
            this.set('chats.rooms', this.get('chats.rooms').sortBy('lastMessage.modified').reverse());
            var filteredWebSocket = room.webSocket;

            if (this.get('messageToEdit') !== null) {
              var packet = JSON.stringify({
                type: 'edit',
                message_id: this.get('messageToEdit').node.id,
                message: this.get('msgBody')
              });

              if (filteredWebSocket.readyState === 1) {
                filteredWebSocket.send(packet);
                this.set('messageToEdit', {});
              }
            } else {
              var _msg = attachmentId === null ? window.linkify(filterText, {
                callback: function callback(text, href) {
                  if (href) {
                    return "<a target=\"_blank\" class=\"link\" href=\"".concat(href, "\" title=\"").concat(href, "\">").concat(text, "</a>");
                  } else {
                    return text;
                  }
                }
              }) : filterText;

              var newMessagePacket = {
                type: 'message',
                message: _msg
              }; //TODO get mentioned users from 'input-chat'

              var wrapUsers = document.querySelectorAll('.wrap-user-group');
              wrapUsers && wrapUsers.forEach(function (i) {
                _this27.get('mentioned').pushObject(i.children[0].dataset.id);
              });

              if (this.get('mentioned').length) {
                newMessagePacket.mentioned = this.get('mentioned');
              }

              if (attachmentId != null) {
                newMessagePacket.attachment_id = attachmentId;
              }

              if (filteredWebSocket.readyState === 1) {
                filteredWebSocket.send(JSON.stringify(newMessagePacket));
              }
            }
          } //this.set('msgBody', '');


          input.innerHTML = '';
          this.set('isLoadingMessage', false);
          this.set('isMessageEmpty', true);
          this.set('mentioned', []);
        }
      } catch (e) {
        console.error(e);
      }
    },
    updateThreadQueryTrigger: function updateThreadQueryTrigger() {
      var _this28 = this;

      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var threadId = this.get('selectedThread.id');
      var selectedMessages = this.get('messages');
      this.ajax.query(_query.default.THREADS).then(function (res) {
        var threads = !res.errors ? res : {
          edges: []
        };
        threads.edges.forEach(function (e) {
          if (!e.node) {
            return;
          }

          if (e.node.lastMessage && e.node.lastMessage.subject === 'shopify_product_card') {
            //TODO remove duplication
            var cards;

            try {
              cards = JSON.parse(e.node.lastMessage.body);
            } catch (e) {
              cards = [];
            }

            if (!Array.isArray(cards)) {
              cards = [];
            }

            e.node.lastMessage.body = cards[0] && cards[0].title || e.node.lastMessage.body;
          }

          if (e.node.lastMessage && e.node.lastMessage.subject === 'kb_article') {
            var data;

            try {
              data = JSON.parse(e.node.lastMessage.body);
            } catch (e) {
              data = {};
            }

            e.node.lastMessage.body = data.title;
          }
        });

        if (threadId && selectedMessages && selectedMessages.length) {
          var selectedId = selectedMessages[selectedMessages.length - 1].pk;
          var lastMessageId = res.edges.findBy('node.id', threadId).node.lastMessage.pk;

          if (selectedId !== lastMessageId) {
            _this28.callQueryThread(threadId);
          }
        }

        _this28.set('threads', !res.errors ? res.edges : []);

        if (id !== null) _this28.scrollToMessage(id);
        if (_this28.target.currentPath === 'chats') Ember.run.later(function () {
          return _this28.updateThreadQueryTrigger();
        }, 5000);
      });
    },
    isElementInViewport: function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    },
    readMessage: function readMessage(id, item) {
      this.get('chats').readMessage(this.get('selectedRoom'), id, item);
    }
  });

  _exports.default = _default;
});