define("inboxfront/components/tags-picker", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var KEY_CODES = {
    BACKSPACE: 8,
    COMMA: 188,
    ENTER: 13,
    SPACE: 32
  };

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: '',
    tag: '',
    isOpen: false,
    isShowInput: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.arrayColors = [{
        color: '#2b78e4',
        backGroundColor: '#ddebff'
      }, {
        color: '#34a853',
        backGroundColor: '#effaff'
      }, {
        color: '#ff9900',
        backGroundColor: '#fff0da'
      }, {
        color: '#e24b4b',
        backGroundColor: '#fdecee'
      }];
      this.ajax.query(_query.default.TAGS).then(function (tags) {
        _this.set('copyTags', tags);

        _this.set('filteredTags', tags);

        if (!_this.get('selectedTags')) {
          _this.showSelectedTags();
        } else {
          var arr = _toConsumableArray(_this.get('selectedTags')).map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              colors: _this.get('arrayColors')[Math.floor(Math.random() * _this.get('arrayColors').length)]
            });
          });

          _this.set('selectedTags', arr);
        }
      });
    },
    actions: {
      onclickOutside: function onclickOutside() {
        this.set('isOpen', false);
      },
      selectTag: function selectTag(item) {
        this.addTag(item.tag);
      },
      createTag: function createTag(tag) {
        this.addTag(tag);
        this.set('isShowInput', false);
      },
      onFocusInput: function onFocusInput() {
        this.set('isOpen', true);
      },
      onKeyDown: function onKeyDown(e) {
        var tags = this.get('copyTags');
        var newTag = e.target.value.trim();
        var newTags = [];

        if (e.which === KEY_CODES.COMMA || e.which === KEY_CODES.SPACE || e.which === KEY_CODES.ENTER) {
          if (newTag.length > 0 && newTag.length < 11) {
            this.addTag(newTag);
          }

          e.preventDefault();
          return;
        }

        if (newTag.length) {
          tags.forEach(function (obj) {
            if (obj.tag.toUpperCase().indexOf(newTag.toUpperCase()) !== -1) {
              newTags.push(obj);
            }
          });
          this.set('filteredTags', newTags);
          this.set('isOpen', true);
        } else {
          this.set('filteredTags', tags);
        }
      },
      showInputTag: function showInputTag() {
        this.set('isShowInput', true);
      },
      removeTagItem: function removeTagItem(item) {
        var _this2 = this;

        if (item.tag === 'shopify') {
          //prevent removing tag 'shopify'
          return;
        }

        if (this.get('objectId')) {
          var hash = {
            tagId: item.id
          };
          this.ajax.query(_mutation.default.REMOVE_TAG, 'deleteTag', hash).then(function () {
            _this2.get('copyTags').pushObject(item);

            _this2.onRemoveTag();
          });
        }

        this.get('selectedTags').removeObject(item);
      }
    },
    addTag: function addTag(tag) {
      var _this3 = this;

      var objectId = this.get('objectId');

      if (objectId) {
        var hash = {
          appLabel: this.get('label') || 'message',
          objectModel: this.get('model'),
          objectId: objectId,
          tag: tag
        };
        this.ajax.query(_mutation.default.CREATE_TAG, 'createTag', hash).then(function (result) {
          var tags = _this3.get('copyTags').filter(function (obj) {
            return obj.tag !== tag;
          });

          result.tag.colors = _this3.get('arrayColors')[Math.floor(Math.random() * _this3.get('arrayColors').length)];

          _this3.get('selectedTags').pushObject(result.tag);

          _this3.set('copyTags', tags);

          _this3.set('filteredTags', tags);

          _this3.onAddTag && _this3.onAddTag();
        });
      } else {
        this.get('selectedTags').pushObject({
          tag: tag
        });
        this.set('filteredTags', this.get('copyTags'));
      }

      this.set('tag', '');
      this.set('isOpen', false);
    },
    showSelectedTags: function showSelectedTags() {
      var _this4 = this;

      var objectId = this.get('objectId');

      if (objectId) {
        var hash = {
          objectId: objectId,
          model: this.get('model'),
          appLabel: this.get('label') || 'message'
        };
        this.ajax.query(_query.default.SELECTED_TAGS, 'tag', hash).then(function (result) {
          result.map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              colors: _this4.get('arrayColors')[Math.floor(Math.random() * _this4.get('arrayColors').length)]
            });
          });

          _this4.set('selectedTags', result);

          var _loop = function _loop(i) {
            var tags = _this4.get('copyTags').filter(function (obj) {
              return obj.tag !== result[i].tag;
            });

            _this4.set('copyTags', tags);
          };

          for (var i = 0; i < result.length; i++) {
            _loop(i);
          }
        });
      }
    }
  });

  _exports.default = _default;
});