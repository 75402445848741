define("inboxfront/services/users", ["exports", "inboxfront/config/environment", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _environment, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DEFAULT_QUOTAS = {
    tariffPlan: {
      userCountLimit: 25
    },
    userCountUsage: 0
  };

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    widgetSettings: function widgetSettings() {
      var widget = _objectSpread({}, this.get('sessionAccount.loggeduser.staffprofile.estates')[0].widgetSettings);

      if (widget.logo && widget.logo.datafile) {
        widget.logoUrl = _environment.default.mediaUrl + widget.logo.datafile;
      }

      if (widget.background && widget.background.datafile) {
        widget.backgroundUrl = _environment.default.mediaUrl + widget.background.datafile;
      }

      if (widget.launcher && widget.launcher.datafile) {
        widget.launcherUrl = _environment.default.mediaUrl + widget.launcher.datafile;
      }

      return widget;
    },
    getPropertyUsers: function getPropertyUsers() {
      var _this = this;

      return new Promise(function (resolve) {
        _this.ajax.query(_query.default.PROPERTY_USERS).then(function (res) {
          _this.set('propertyUsers', !res.errors ? res : []);

          resolve(_this.get('propertyUsers'));
        });
      });
    },
    getQuotas: function getQuotas() {
      var _this2 = this;

      return new Promise(function (resolve) {
        _this2.ajax.query(_query.default.QUOTAS).then(function (res) {
          if (res) {
            resolve(res);
          } else {
            resolve(DEFAULT_QUOTAS);
          }
        });
      });
    },

    get systemRoles() {
      return [{
        id: 'admin',
        name: 'user_role_admin'
      }, {
        id: 'owner',
        name: 'user_role_owner'
      }, {
        id: 'member',
        name: 'user_role_member'
      }];
    },

    create: function create() {
      return {
        firstName: '',
        lastName: '',
        withEmail: true,
        email: '',
        userDep: {},
        userProp: {},
        isAgent: true,
        systemRole: this.systemRoles.findBy('id', 'member'),
        selectedRoles: []
      };
    },
    save: function save(user) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        var hash = {
          firstName: user.firstName,
          lastName: user.lastName,
          department: user.userDep.id,
          propertiesId: user.userProp.id,
          isAgent: user.isAgent,
          systemGroup: user.systemRole.id
        };

        if (user.withEmail) {
          hash.email = user.email;
        } else {
          hash.defaultPassword = _this3.generatePassword();
        }

        if (user.systemRole.id === 'member') {
          if (user.selectedRoles.length) {
            hash.customRoles = user.selectedRoles.map(function (role) {
              return role.id;
            });
          }
        }

        _this3.ajax.mutation(_mutation.default.CREATE_USER, 'createUser', hash, function (res) {
          resolve(res.profile.user);
        }, function () {
          reject();
        });
      });
    },
    validateUser: function validateUser(user) {
      var valid = user && user.userProp.id !== undefined;

      if (valid && !user.userDep.id) {
        valid = false;
      }

      user && Ember.set(user, 'isValid', valid);
    },
    generatePassword: function generatePassword() {
      var chars = 'abcdefghijklmnopqrstuvwxyz'.split('');
      var res = [];
      res.push(chars[Math.floor(Math.random() * chars.length)].toUpperCase());

      for (var i = 0; i < 6; i++) {
        res.push(chars[Math.floor(Math.random() * chars.length)]);
      }

      res.push(Math.floor(Math.random() * 10));
      return res.join('');
    }
  });

  _exports.default = _default;
});