define("inboxfront/controllers/ticketfields", ["exports", "inboxfront/controllers/customfields"], function (_exports, _customfields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customfields.default.extend({
    actions: {
      showCreateTaskDialog: function showCreateTaskDialog(status) {
        var _this = this;

        this.set('additionalData', status ? {
          status: status
        } : {});
        this.set('isCreateDialogInserted', true);
        setTimeout(function () {
          return _this.set('openCreateTaskDialog', true);
        }, 300);
      },
      onTaskCreate: function onTaskCreate(task) {
        this.set('openCreateTaskDialog', false);
      }
    }
  });

  _exports.default = _default;
});