define("inboxfront/controllers/profile", ["exports", "inboxfront/config/environment", "inboxfront/gql/mutation"], function (_exports, _environment, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    languages: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    selectedLocale: Ember.computed('profile.staffprofile.language', function () {
      var _this = this;

      if (this.get('locales')) {
        return this.get('locales').find(function (_ref) {
          var code = _ref.code;
          return code === _this.get('profile.staffprofile.language');
        });
      }
    }),
    onAddedFile: Ember.computed(function () {
      var controller = this;
      return function () {
        controller.set('isLoading', true);
      };
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var _JSON$parse = JSON.parse(response.xhr.response),
            data = _JSON$parse.data;

        this.removeAllFiles();
        controller.set('attachmentId', data.file.attachment.id);
        controller.set('urlAttachment', _environment.default.mediaUrl + data.file.attachment.datafile);
        controller.get('sessionAccount').update(false, function () {
          controller.set('isLoading', false);
        });
      };
    }),
    options: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token');
      var userId = this.get('profile.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        clickable: '.edit-icon',
        dictDefaultMessage: this.intl.t('upload_image'),
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "auth", "model": "user", "objectId": "' + userId + '"}'
        }
      };
    }),
    actions: {
      resetFields: function resetFields() {
        var _this2 = this;

        if (JSON.stringify(this.get('cloneProfile')) !== JSON.stringify(this.get('profile'))) {
          this.set('selectedLocale', this.get('locales').find(function (_ref2) {
            var code = _ref2.code;
            return code === _this2.get('cloneProfile.staffprofile.language');
          }));
          this.set('profile', Ember.copy(this.get('cloneProfile'), true));
        }
      },
      saveProfile: function saveProfile() {
        var _this3 = this;

        var _this$get = this.get('profile'),
            id = _this$get.id,
            firstName = _this$get.firstName,
            lastName = _this$get.lastName,
            _this$get$staffprofil = _this$get.staffprofile,
            phone = _this$get$staffprofil.phone,
            position = _this$get$staffprofil.position,
            department = _this$get$staffprofil.department;

        var hash = {
          userId: id,
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          position: position
        };

        if (department) {
          hash.department = department.id;
        }

        if (this.get('selectedLocale')) {
          hash.language = this.get('selectedLocale').code;
        }

        this.ajax.query(_mutation.default.UPDATE_USER_PROFILE, 'updateProfile', hash).then(function () {
          if (hash.language !== _this3.get('profile.staffprofile.language')) {
            _this3.set('profile.staffprofile.language', hash.language);

            _this3.get('languages').update(hash.language);
          }

          _this3.paperToaster.show(_this3.intl.t('profile_updated'));

          _this3.set('cloneProfile', Ember.copy(_this3.get('profile'), true));
        });
      },
      deleteAvatar: function deleteAvatar(attachmentId) {
        var _this4 = this;

        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
          attachmentId: attachmentId
        }).then(function () {
          _this4.set('urlAttachment', null);

          _this4.get('sessionAccount').update();
        });
      }
    }
  });

  _exports.default = _default;
});