define("inboxfront/templates/components/assistants/workflows/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FGiH9Qgf",
    "block": "{\"symbols\":[\"property\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label alignCenter\"],[8],[1,[28,\"svg-icon\",[\"admin-company-settings\"],null],false],[1,[28,\"t\",[\"company\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"searchPlaceholder\",\"searchEnabled\",\"searchField\",\"options\",\"selected\",\"disabled\",\"onChange\"],[[28,\"t\",[\"search\"],null],true,\"title\",[24,[\"properties\"]],[24,[\"flow\",\"properties\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"onSelectProperty\"],null]]],{\"statements\":[[0,\"            \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label alignCenter\"],[8],[1,[28,\"svg-icon\",[\"description\"],null],false],[1,[28,\"t\",[\"description\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"paper-input\",null,[[\"placeholder\",\"value\",\"textarea\",\"maxlengthTextArea\",\"disabled\",\"passThru\",\"onChange\",\"onBlur\"],[[28,\"t\",[\"description\"],null],[28,\"or\",[[24,[\"flow\",\"templateDescription\"]],[24,[\"flow\",\"description\"]]],null],true,200,[24,[\"disabled\"]],[28,\"hash\",null,[[\"rows\",\"maxRows\"],[3,3]]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"flow\",\"description\"]]],null]],null],[28,\"action\",[[23,0,[]],\"onBlurInputFlow\",\"description\",[24,[\"flow\",\"description\"]]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/workflows/settings.hbs"
    }
  });

  _exports.default = _default;
});