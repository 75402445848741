define("inboxfront/components/user-status-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    profile: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.profile.getStatusUser();
      this.menu = [{
        title: 'profile',
        icon: 'profile2',
        url: 'profile'
      }, {
        title: 'security',
        icon: 'lock3',
        url: 'security'
      }, {
        title: 'preferences',
        icon: 'group',
        url: 'preference.notification'
      }];
      this.statusList = ['online', 'away', 'offline'];
    },
    user: Ember.computed('sessionAccount.loggeduser', function () {
      return this.sessionAccount.loggeduser;
    }),
    userStatus: Ember.computed('profile.userStatus', function () {
      return this.profile.userStatus;
    }),
    noteSettings: Ember.computed('profile.notificationSettings', function () {
      return this.get('profile.notificationSettings');
    }),
    snooze: Ember.computed('noteSettings', function () {
      return this.get('noteSettings.snooze') ? 'snooze' : '';
    }),
    actions: {
      changeOnlineStatus: function changeOnlineStatus(status) {
        this.profile.changeStatusUser(status);
      },
      onChooseTimeSnooze: function onChooseTimeSnooze(_ref) {
        var value = _ref.value;

        if (value === 'custom') {
          this.set('showPopupCustomDate', true);
        } else {
          this.profile.chooseTimeSnooze(value, this.get('noteSettings'));
        }
      },
      offSnooze: function offSnooze() {
        var hash = _objectSpread(_objectSpread({}, this.get('noteSettings')), {}, {
          type: 0,
          snooze: null
        });

        this.profile.updateProfile(hash, 'snooze_off');
      },
      togglePopupCustomDate: function togglePopupCustomDate() {
        this.set('showPopupCustomDate', false);
      },
      confirmDate: function confirmDate() {
        var hash = _objectSpread(_objectSpread({}, this.get('noteSettings')), {}, {
          snooze: new Date(this.get('snoozeCustomDate')).toISOString()
        });

        this.profile.updateProfile(hash);
      },
      onClickOutside: function onClickOutside() {
        this.set('isShowPopup', false);
      },
      logout: function logout() {
        this.get('session').invalidate();
      },
      toggleUserMenuPopup: function toggleUserMenuPopup() {
        this.toggleProperty('isShowPopup');
      }
    }
  });

  _exports.default = _default;
});