define("inboxfront/templates/components/common/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SmwkcsXg",
    "block": "{\"symbols\":[\"panel\",\"&default\"],\"statements\":[[4,\"cp-panel\",null,[[\"open\",\"disabled\",\"class\"],[[24,[\"isOpen\"]],[24,[\"disabled\"]],[28,\"concat\",[\"common-panel \",[24,[\"class\"]]],null]]],{\"statements\":[[0,\"    \"],[14,2,[[28,\"hash\",null,[[\"toggle\"],[[28,\"component\",[\"common/panel-toggle\"],[[\"panel\"],[[23,1,[]]]]]]]]]],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"body\"],[[23,1,[\"body\"]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/panel.hbs"
    }
  });

  _exports.default = _default;
});