define("inboxfront/controllers/users/edit", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    openUserDeactivateDialog: false,
    openUserDeleteDialog: false,
    openUserPasswordResetDialog: false,
    queryParams: ['id'],
    systemRole: Ember.computed('user.systemGroup.name', function () {
      return this.systemRoles.findBy('id', this.get('user.systemGroup.name'));
    }),
    availableCustomRoles: Ember.computed('user.customGroups.length', function () {
      var userCustomGroups = this.get('user.customGroups');
      return this.get('customRoles').filter(function (companyRole) {
        return !userCustomGroups.find(function (userRole) {
          return userRole.id === companyRole.id;
        });
      });
    }),
    actions: {
      resendInvite: function resendInvite() {
        var _this = this;

        this.ajax.mutation(_mutation.default.RESEND_INVITE, 'resendActivation', {
          userId: this.get('user.id')
        }, function () {
          _this.get('paperToaster').show(_this.intl.t('invite_sent'));
        });
      },
      deleteUserDialog: function deleteUserDialog() {
        var user = this.get('user');
        var users = [];
        this.get('model.users').forEach(function (profile) {
          if (profile.user.id !== user.id) {
            users.push({
              id: profile.user.id,
              name: "".concat(profile.user.firstName, " ").concat(profile.user.lastName)
            });
          }
        });
        this.set('assigneeUser', null);
        this.set('assigneeUsers', users);
        this.set('openUserDeleteDialog', true);
      },
      deleteUser: function deleteUser() {
        var _this2 = this;

        var data = {
          userId: this.get('user.id')
        };

        if (this.get('assigneeUser')) {
          data.assignee = this.get('assigneeUser.id');
        }

        this.ajax.mutation(_mutation.default.DELETE_USER, 'deleteUser', data, function () {
          _this2.set('openUserDeleteDialog', false);

          _this2.get('paperToaster').show(_this2.intl.t('user_deleted'));

          _this2.transitionToRoute('users.index');
        });
      },
      resetPasswordDialog: function resetPasswordDialog() {
        var _this3 = this;

        var user = this.get('user');
        this.set('resetHeader', this.intl.t('actions.reset_password'));
        this.set('resetUser', user);
        this.set('resetLink', '');
        this.set('resetPasswordText', this.intl.t('status.loading'));
        this.set('openUserPasswordResetDialog', true);
        this.ajax.mutation(_mutation.default.RESET_PASSWORD, 'resetPassword', {
          userId: this.get('user.id')
        }, function (result) {
          if (result.resetLink) {
            _this3.set('resetLink', result.resetLink);

            _this3.set('resetPasswordText', _this3.intl.t('reset_user_password', {
              link: result.resetLink,
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));
          } else {
            _this3.set('resetPasswordText', _this3.intl.t('reset_user_password_email', {
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));
          }
        }, function () {
          _this3.set('resetPasswordText', _this3.intl.t('internal_error'));
        });
      },
      changeUserStatus: function changeUserStatus() {
        if (this.get('user.isActive')) {
          this.set('openUserDeactivateDialog', true);
        } else {
          this.saveUserStatus(!this.get('user.isActive'));
        }
      },
      deactivateUser: function deactivateUser() {
        this.saveUserStatus(!this.get('user.isActive'));
      },
      changeAccess: function changeAccess(field) {
        var _this4 = this;

        var modelKey = "user.staffprofile.".concat(field);
        var value = !this.get(modelKey);
        var hash = {};
        hash[field] = value;
        this.saveUserAdmin(function () {
          _this4.paperToaster.show(_this4.intl.t(value ? 'user_access_enabled' : 'user_access_revoked'));

          _this4.set(modelKey, value);
        }, hash);
      },
      changeSystemRole: function changeSystemRole(group) {
        var _this5 = this;

        var value = group.id;
        var hash = {
          systemGroup: value
        };

        if (value === 'admin' || value === 'owner') {
          hash.hasHelpdeskAccess = true;
          hash.hasInboxAccess = true;
        }

        this.saveUser(function () {
          _this5.get('paperToaster').show(_this5.intl.t('user_product_role_changed'));

          _this5.set('user.systemGroup.name', value);

          if (hash.hasHelpdeskAccess) {
            _this5.set('user.staffprofile.hasHelpdeskAccess', true);

            _this5.set('user.staffprofile.hasInboxAccess', true);
          }
        }, hash);
      },
      addCustomRole: function addCustomRole() {
        var _this6 = this;

        if (!this.get('selectedCustomRole')) {
          return;
        }

        var hash = {
          roleId: this.get('selectedCustomRole.id'),
          users: [this.get('user.id')]
        };
        this.ajax.mutation(_mutation.default.ADD_USERS, 'addUsers', hash, function () {
          _this6.get('user.customGroups').pushObject(_this6.get('selectedCustomRole'));

          _this6.set('selectedCustomRole', null);

          _this6.get('paperToaster').show(_this6.intl.t('custom_role_added'));
        });
      },
      removeCustomRole: function removeCustomRole(group) {
        var _this7 = this;

        var hash = {
          roleId: group.id,
          userId: [this.get('user.id')]
        };
        this.ajax.mutation(_mutation.default.REMOVE_USER, 'removeUser', hash, function () {
          _this7.get('user.customGroups').removeObject(group);

          _this7.paperToaster.show(_this7.intl.t('custom_role_removed'));
        });
      }
    },
    saveUserStatus: function saveUserStatus(status) {
      var _this8 = this;

      var hash = {
        isActive: status
      };
      this.saveUserAdmin(function () {
        _this8.paperToaster.show(_this8.intl.t(status ? 'user_activated' : 'user_deactivated'));

        _this8.set('user.isActive', status);

        _this8.set('openUserDeactivateDialog', false);
      }, hash);
    },
    saveUser: function saveUser(callback, hash) {
      hash.userId = this.get('user.id');
      this.ajax.mutation(_mutation.default.UPDATE_USER_PROFILE, 'updateProfile', hash, callback);
    },
    saveUserAdmin: function saveUserAdmin(callback, hash) {
      hash.userId = this.get('user.id');
      this.ajax.mutation(_mutation.default.UPDATE_USER_ADMIN, 'updateUserAdmin', hash, callback);
    }
  });

  _exports.default = _default;
});