define("inboxfront/controllers/response", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    showAdd: false,
    isEdit: false,
    message: '',
    actions: {
      onSelectChange: function onSelectChange(value) {
        this.set('selectedProperty', value);
      },
      showCreateDialog: function showCreateDialog() {
        this.set('isEdit', false);
        this.set('showAdd', true);
      },
      showEditDialog: function showEditDialog(item) {
        var currentProp = item.node.properties.length === 1 ? item.node.properties[0] : this.get('selectedProperty');
        this.set('isEdit', true);
        this.set('message', item.node.text);
        this.set('shortcut', item.node.shortcut);
        this.set('selectedProperty', currentProp);
        this.set('title', item.node.title);
        this.set('replyId', item.node.id);
        this.set('currentTags', item.node.tags);
        this.set('showAdd', true);
      },
      closeDialog: function closeDialog() {
        this.clearForm();
      },
      createResponse: function createResponse() {
        var _this = this;

        var toaster = this.get('paperToaster');
        var isEdit = this.get('isEdit');
        var hash = {
          shortcut: this.get('shortcut'),
          text: this.get('message'),
          title: 'Default'
        };

        if (this.get('selectedProperty').id !== '') {
          hash.propertyId = this.get('selectedProperty').id;
        }

        if (isEdit) hash.replyId = this.get('replyId');
        this.ajax.query(_mutation.default.CREATE_UPDATE_REPLY, 'createOrUpdateReply', hash).then(function (res) {
          if (!res.errors) {
            if (isEdit) {
              var filter = _this.get('model.replyTemplates.edges').findBy('node.id', hash.replyId);

              Ember.set(filter, 'node', res.replyTemplate);
            } else {
              _this.get('model.replyTemplates.edges').pushObject({
                node: res.replyTemplate
              });
            }

            _this.addMutationTag(res.replyTemplate.id);

            toaster.show(_this.intl.t(isEdit ? 'response_updated' : 'response_created', {
              shortcut: hash.shortcut
            }));

            _this.clearForm();
          } else {
            toaster.show(res.errors[0].message);
          }
        });
      },
      removeResponse: function removeResponse(item) {
        var _this2 = this;

        var toaster = this.get('paperToaster');
        this.ajax.query(_mutation.default.DELETE_REPLY, 'deleteReply', {
          replyId: item.node.id
        }).then(function (result) {
          if (!result.errors) {
            _this2.get('model.replyTemplates.edges').removeObject(item);

            toaster.show(_this2.intl.t('response_removed', {
              shortcut: item.node.shortcut
            }));
          } else {
            toaster.show(result.errors[0].message);
          }
        });
      },
      addTag: function addTag(tag) {
        this.get('currentTags').pushObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get('currentTags').removeAt(tag);
      }
    },
    addMutationTag: function addMutationTag(id) {
      var _this3 = this;

      var tags = this.get('currentTags');
      var hashTags = {
        appLabel: 'shortcuts',
        objectModel: 'replytemplate',
        objectId: id
      };
      var filter = this.get('model.replyTemplates.edges').findBy('node.id', id);
      tags.length && tags.forEach(function (tag) {
        hashTags.tag = tag;

        _this3.ajax.query(_mutation.default.CREATE_TAG, 'createTag', hashTags).then(function (res) {
          return filter.node.tags.pushObject(res.tag);
        });
      });
    },
    clearForm: function clearForm() {
      this.set('shortcut', '');
      this.set('message', '');
      this.set('selectedProperty', {
        id: '',
        title: this.intl.t('all_companies')
      });
      this.set('currentTags', []);
      this.set('showAdd', false);
    }
  });

  _exports.default = _default;
});