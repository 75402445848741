define("inboxfront/services/auth-google", ["exports", "inboxfront/utils/jwtdecode", "inboxfront/gql/mutation"], function (_exports, _jwtdecode, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      window.googleSignInCallback = function (_ref) {
        var credential = _ref.credential;

        _this.ajax.query(_mutation.default.GOOGLE_AUTH, 'googleJwtAuthentication', {
          token: credential
        }).then(function (_ref2) {
          var okay = _ref2.okay;

          if (okay === 'true') {
            var responsePayload = (0, _jwtdecode.default)(credential);
            var email = responsePayload.email;

            _this.session.authenticate('authenticator:oauth2', email, credential).catch(function (_ref3) {
              var responseJSON = _ref3.responseJSON;

              if (responseJSON.error_description) {
                _this.get('paperToaster').show(_this.intl.t('forms.login_failed'));
              }
            });
          }
        });
      };
    }
  });

  _exports.default = _default;
});