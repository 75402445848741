define("inboxfront/components/kb/section-editor-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    actions: {
      onDragStart: function onDragStart(category, event) {
        Ember.set(category.section, 'hilighted', true);
        event.dataTransfer.setData('categoryId', category.id);
      },
      onDragEnd: function onDragEnd(category) {
        Ember.set(category.section, 'hilighted', false);
      }
    }
  });

  _exports.default = _default;
});