define("inboxfront/controllers/roles/index", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    showAddGroup: false,
    isNextStep: false,
    showDeleteRoleDialog: false,
    currentStep: 0,
    roleId: null,
    isDisableBtn: Ember.computed('isNextStep', 'name', function () {
      return !this.get('isNextStep') || !this.get('name');
    }),
    actions: {
      toggleShowPopup: function toggleShowPopup() {
        this.callToggleShowPopup();
      },
      createRole: function createRole() {
        this.callCreateRole();
      },
      showPopupDeleteRole: function showPopupDeleteRole(role) {
        this.set('selectedRole', role);
        this.set('showDeleteRoleDialog', true);
      },
      deleteRole: function deleteRole() {
        var _this = this;

        this.ajax.query(_mutation.default.DELETE_CUSTOM_ROLE, 'deleteCustomRole', {
          roleId: this.get('selectedRole.id')
        }).then(function () {
          _this.get('roles').removeObject(_this.get('selectedRole'));

          _this.set('showDeleteRoleDialog', false);
        });
      },
      statusChanged: function statusChanged(value) {
        this.set('isActiveRule', value);
      },
      roleChangeStatus: function roleChangeStatus(role, value) {
        var _this2 = this;

        var hash = {
          roleId: role.id,
          isActive: value
        };
        Ember.set(role, 'isActive', value);
        this.ajax.query(_mutation.default.CREATE_UPDATE_CUSTOM_ROLE, 'createUpdateCustomRole', hash).then(function () {
          return _this2.updateRoles();
        });
      },
      onNextStep: function onNextStep() {
        this.callCreateRole(true);
        this.set('currentStep', 1);
      },
      createUsersRole: function createUsersRole() {
        var _this3 = this;

        var usersId = this.get('usersId');
        var roleId = this.get('roleId');
        var users = Array.from(new Set(usersId.map(function (a) {
          return a.id;
        })));
        this.ajax.query(_mutation.default.ADD_USERS, 'addUsers', {
          roleId: roleId,
          users: users
        }).then(function () {
          _this3.callToggleShowPopup();

          _this3.updateRoles();
        });
      }
    },
    callToggleShowPopup: function callToggleShowPopup() {
      this.clearForm();
      this.set('showAddGroup', !this.get('showAddGroup'));
    },
    callCreateRole: function callCreateRole() {
      var _this4 = this;

      var notClose = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var hash = {
        name: this.get('name'),
        description: this.get('description'),
        isActive: this.get('isActiveRule')
      };
      this.ajax.query(_mutation.default.CREATE_UPDATE_CUSTOM_ROLE, 'createUpdateCustomRole', hash).then(function (res) {
        _this4.set('roleId', res.role.id);

        if (notClose) return;

        _this4.callToggleShowPopup();

        _this4.updateRoles();
      });
    },
    clearForm: function clearForm() {
      this.set('currentStep', 0);
      this.set('name', '');
      this.set('description', '');
      this.set('isActiveRule', false);
      this.set('isNextStep', false);
      this.set('usersId', []);
    },
    updateRoles: function updateRoles() {
      var _this5 = this;

      this.ajax.query(_query.default.COMPANY_CUSTOM_ROLES, 'companyCustomRoles').then(function (res) {
        return _this5.set('roles', !res.errors ? res : []);
      });
    }
  });

  _exports.default = _default;
});