define("inboxfront/components/settings-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.menuItems = [{
        title: 'account_settings',
        icon: 'settings',
        link: 'settings',
        class: 'first'
      }, {
        title: 'company_settings',
        icon: 'admin-company-settings',
        subMenu: [{
          title: 'company',
          icon: 'company',
          link: 'companies.index',
          type: 'companies'
        }, {
          title: 'company_group',
          icon: 'group',
          link: 'group.index',
          type: 'group'
        }, {
          title: 'departments',
          icon: 'departments',
          link: 'departments'
        }]
      }, {
        title: 'contact_settings',
        icon: 'admin-contacts-settings',
        subMenu: [{
          title: 'contact_fields',
          icon: 'contact-fields',
          link: 'contactfields'
        }, {
          title: 'org_fields',
          icon: 'organization-fields',
          link: 'orgfields'
        }]
      }, {
        title: 'task_settings',
        icon: 'admin-task-settings',
        subMenu: [{
          title: 'issue_types',
          icon: 'issue-types',
          link: 'issuetypes'
        }, {
          title: 'ticket_fields',
          icon: 'ticket-fields',
          link: 'ticketfields'
        }]
      }, {
        title: 'security',
        icon: 'admin-security-settings',
        subMenu: [{
          title: 'users',
          icon: 'users',
          link: 'users.index',
          type: 'users'
        }, {
          title: 'roles',
          icon: 'roles',
          link: 'roles.index',
          type: 'roles'
        }, {
          title: 'permissions',
          icon: 'lock3',
          link: 'permissions'
        }]
      }, // {
      //     title: 'channel_settings',
      //     icon: 'admin-channels-settings',
      //     subMenu: [
      //         {title: 'widget_cfg', icon: 'configure', link: 'channels', type: 'widget', query: 'widget'},
      //         {title: 'channels', icon: 'channels2', link: 'channels', query: 'channels'},
      //     ]
      // },
      {
        title: 'widget_cfg',
        icon: 'configure',
        link: 'channels',
        type: 'widget',
        query: 'widget',
        class: 'first'
      }, {
        title: 'chatslimit',
        icon: '',
        link: 'chatslimit',
        class: 'first'
      }, {
        title: 'Templates',
        icon: 'admin-templates-settings',
        subMenu: [{
          title: 'quick_response',
          icon: 'quick-response',
          link: 'response'
        }]
      }, {
        title: 'marketplace',
        icon: 'admin-marketplace-settings',
        link: 'ecommerce',
        class: 'last'
      }];
    }
  });

  _exports.default = _default;
});