define("inboxfront/components/assistants/flows/new-canvas", ["exports", "jquery", "inboxfront/utils/new-canvas-objects", "inboxfront/utils/new-canvas-editor"], function (_exports, _jquery, _newCanvasObjects, _newCanvasEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chatbots: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this._onresize = function (event) {};

      this.commands = [];
    },
    ws: Ember.observer('triggers.length', function () {
      var _this$editor, _this$editor2;

      //eslint-disable-line
      (_this$editor = this.editor) === null || _this$editor === void 0 ? void 0 : _this$editor.triggersPanel.setTriggers(this.get('triggers'));
      (_this$editor2 = this.editor) === null || _this$editor2 === void 0 ? void 0 : _this$editor2.render();
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var editor = _newCanvasEditor.Editor.create(document.getElementById('canvas_flows'));

      this.set('editor', editor);

      this._resizeHandler = function (e) {
        var block = (0, _jquery.default)('.canvas-block');

        _this.editor.setSize({
          width: block.width(),
          height: block.height()
        });
      };

      this.editor.addCommand = function (c) {
        _this.addCommand(c);
      };

      this.editor.onSaveBlock = function (b) {
        b.save(_this.chatbots);
      };

      this.editor.onSaveConnection = function (c) {
        if (c.inConnect) {
          c.inConnect.parentBlock.save(_this.chatbots);
        }

        c.outConnect.parentBlock.save(_this.chatbots);
      };

      this.editor.onRemoveConnection = function (c) {
        if (c.outConnect.parentBlock.isTriggerPanel) {
          _this.editor.onSaveSettings();
        }

        c.save(_this.chatbots);
      };

      this.editor.onSaveSettings = function () {
        var panel = _this.editor.triggersPanel;
        var coords = panel.getMiddlePoint();

        var settings = _this.editor.getCanvasSettings();

        var connections = panel.getOutConnect().connections;
        var blockId = 0;

        if (connections[0]) {
          blockId = connections[0].inConnect.parentBlock.block.id;
        }

        var extraData = {
          zoom: settings.zoom,
          position: settings.position,
          coords: coords,
          firstBlock: blockId
        };

        _this.chatbots.saveFlowSettings(extraData, _this.get('flow'));
      };

      (0, _jquery.default)(window).on('resize', this._resizeHandler);
      this.editor.openListComponents = this.openListComponents;
      var extraData = this.get('flow.extraData');
      var begin = {
        x: 150,
        y: 20
      };
      var coords = extraData.coords;

      if (coords) {
        begin.x = coords.x - 75;
        begin.y = coords.y - 40;
      }

      var end = {
        x: begin.x + 75,
        y: begin.y + 40
      }; // let panel = new TriggersPanel(extraData.coords || {x: 150, y: 20}, {x: 300, y: 100}, editor);

      var panel = new _newCanvasObjects.TriggersPanel(begin, end, editor);
      panel.onSelect = this.showTriggers;
      panel.onSelectTrigger = this.openTriggerConfigure;
      panel.openListComponents = this.openListComponents;
      this.editor.triggersPanel = panel;
      panel.setTriggers(this.get('triggers'));
      this.editor.setCanvasSettings(extraData.zoom, extraData.position); // this.editor.setCanvasSettings(50, Point(200, 200));

      this.editor.setSize({
        width: this.element.clientWidth,
        height: this.element.clientHeight
      });
      this.renderBlocks();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(window).off('resize', this._resizeHandler);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _console;

      this._super.apply(this, arguments);

      (_console = console).error.apply(_console, ['DID RECEVIEVE ATTR'].concat(Array.prototype.slice.call(arguments), [this.get('triggers'), this.get('blocks')]));

      if (!this.editor) {
        return;
      }

      this.renderBlocks();
    },
    renderBlocks: function renderBlocks() {
      var _this2 = this;

      var blocks = [];

      var _this$editor$getSize = this.editor.getSize(),
          width = _this$editor$getSize.width,
          height = _this$editor$getSize.height;

      this.get('blocks').forEach(function (b) {
        var block = new _newCanvasObjects.Block(b);

        block.onSelect = function () {
          _this2.openConfigure(b);
        };

        block.onRemove = function (e) {
          var permanent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

          _this2.editor.removeBlock(block, !e.ctrlKey);

          if (!e.ctrlKey) {
            _this2.chatbots.removeNewBlock(b);

            _this2.get('blocks').removeObject(b);
          }
        };

        var BLOCK_WIDTH = _newCanvasObjects.IS_DEMO ? 110 : 200;
        var BLOCK_HEIGHT = 110;
        var multiple = true;

        if (b.type === 'random') {
          multiple = true;
        }

        if (b.extraData) {
          try {
            var _extraData = JSON.parse(b.extraData);

            b.extraData = _extraData;

            if (_extraData.type === 'random') {
              multiple = true;
            }
          } catch (e) {// console.error(e);
          }
        }

        if (b.name === 'Options (Single select)') {
          if (!b.extraData.options) {
            b.extraData.options = [];
          }
        } else if (b.name === 'Data Collector (form)') {
          if (!b.extraData.options) {
            b.extraData.options = [];
          }
        }

        var begin = {
          x: (width - BLOCK_WIDTH) / 2,
          y: (height - BLOCK_HEIGHT) / 2
        };
        var coords = b.extraData.coords;

        if (coords) {
          begin.x = coords.x - BLOCK_WIDTH / 2;
          begin.y = coords.y - BLOCK_HEIGHT / 2;
        }

        var end = {
          x: begin.x + BLOCK_WIDTH,
          y: begin.y + BLOCK_HEIGHT
        };
        block.setNewCoords(begin, end);

        if (b.icon === 'options-single') {
          multiple = true;
        }

        block.removed = !!b.extraData.removed;
        block.setInConnect(new _newCanvasObjects.Connector({
          direction: 'IN',
          top: true,
          onSelect: _this2.openListComponents
        }, block));
        block.setOutConnect(new _newCanvasObjects.Connector({
          direction: 'OUT',
          bottom: true,
          multiple: multiple,
          onSelect: _this2.openListComponents
        }, block));
        blocks.addObject(block);
      });
      this.editor.setBlocks(blocks);
      blocks.forEach(function (b) {
        if (b.block.extraData.connections) {
          b.block.extraData.connections.forEach(function (c) {
            var block = blocks.findBy('block.id', c);

            if (c === -1) {
              block = _this2.editor.triggersPanel;
            }

            if (block) {
              var conn = new _newCanvasObjects.Connection(b.outConnect, block.inConnect);
              conn.removed = b.block.extraData.removed;

              _this2.editor._objects.addObject(conn);
            }
          });
        }
      });
      var extraData = this.get('flow.extraData');

      if (extraData.firstBlock) {
        var block = this.editor._objects.findBy('block.id', extraData.firstBlock);

        if (block) {
          var conn = new _newCanvasObjects.Connection(this.editor.triggersPanel.outConnect, block.inConnect);

          this.editor._objects.addObject(conn);
        }
      }

      this.editor.render();
    },
    addBlock: function addBlock(newBlock) {
      var _this3 = this,
          _this$editor3;

      var coords = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var block = new _newCanvasObjects.Block(newBlock);
      newBlock.flow = {
        id: this.get('flowId')
      };

      block.onSelect = function () {
        _this3.openConfigure(newBlock);
      };

      block.onRemove = function (e) {
        var permanent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        _this3.editor.removeBlock(block, !e.ctrlKey);

        if (!e.ctrlKey) {
          _this3.chatbots.removeNewBlock(block.newBlock);

          _this3.get('blocks').removeObject(block.newBlock);
        }
      };

      newBlock.extraData = '{}';
      var BLOCK_WIDTH = _newCanvasObjects.IS_DEMO ? 130 : 200;
      var BLOCK_HEIGHT = 110;

      var _this$editor$getSize2 = this.editor.getSize(),
          width = _this$editor$getSize2.width,
          height = _this$editor$getSize2.height;

      var begin = {
        x: (width - BLOCK_WIDTH) / 2,
        y: (height - BLOCK_HEIGHT) / 2
      };

      if (coords) {
        newBlock.extraData = JSON.stringify({
          coords: coords
        });
        begin.x = coords.x - BLOCK_WIDTH / 2;
        begin.y = coords.y - BLOCK_HEIGHT / 2;
      }

      var end = {
        x: begin.x + BLOCK_WIDTH,
        y: begin.y + BLOCK_HEIGHT
      };
      block.setNewCoords(begin, end);
      block.setInConnect(new _newCanvasObjects.Connector({
        direction: 'IN',
        top: true,
        onSelect: this.openListComponents
      }, block));
      var multiple = false;

      if (newBlock.type === 'random') {
        multiple = true;
      }

      if (newBlock.extraData) {
        try {
          var extraData = JSON.parse(newBlock.extraData);
          newBlock.extraData = extraData;

          if (extraData.type === 'random') {
            multiple = true;
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (newBlock.name === 'Options (Single select)') {
        newBlock.extraData.options = [{
          mainTitle: ''
        }, {
          mainTitle: ''
        }];
      } else if (newBlock.name === 'Subscribe for mailing') {
        newBlock.extraData.subscribeResponse = 'Emoji: Thumbs Up/Thumbs Down';
      }

      if (newBlock.icon === 'options-single') {
        multiple = true;
      }

      block.setOutConnect(new _newCanvasObjects.Connector({
        direction: 'OUT',
        bottom: true,
        multiple: multiple,
        onSelect: this.openListComponents
      }, block));
      (_this$editor3 = this.editor) === null || _this$editor3 === void 0 ? void 0 : _this$editor3.addBlock(block);
      block.save(this.chatbots); // this.editor?.render();
    },
    actions: {
      OnMouseWheel: function OnMouseWheel(event) {
        event.preventDefault();

        if (event.deltaY < 0) {
          if (event.ctrlKey) {
            this.editor.zoomIn();
          } else if (event.shiftKey) {
            this.editor.scrollLeft();
          } else {
            this.editor.scrollUp();
          }
        } else {
          if (event.ctrlKey) {
            this.editor.zoomOut();
          } else if (event.shiftKey) {
            this.editor.scrollRight();
          } else {
            this.editor.scrollDown();
          }
        }
      },
      onDragEnter: function onDragEnter(event) {
        event.preventDefault();
      },
      onDragOver: function onDragOver(event) {
        event.preventDefault();
      },
      onDrop: function onDrop(event) {
        event.preventDefault();

        if (event.dataTransfer.getData('component')) {
          var component = JSON.parse(event.dataTransfer.getData('component'));
          component.id = null; // component.id = Math.ceil(Math.random() * 100000 + 10000);

          component.mainTitle = '';

          var coords = this.editor._getCoords(event);

          this.get('blocks').addObject(component);
          this.addBlock(component, coords);
        }
      },
      onDragEnd: function onDragEnd(event) {}
    }
  });

  _exports.default = _default;
});