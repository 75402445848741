define("inboxfront/routes/flows/edit", ["exports", "inboxfront/routes/common", "inboxfront/helpers/countries-list", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _common, _countriesList, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var WORKFLOW = 2;

  var _default = _common.default.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    emoji: Ember.inject.service(),
    chatbots: Ember.inject.service(),
    users: Ember.inject.service(),
    tasks: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('flows');
      } else {
        // const test = this.ajax.testQuery(Query.TEST_QUERY);
        var chatbot = this.ajax.query(_query.default.CHATBOT, 'chatbot', {
          flowId: params.id
        });
        var ruleOptions = this.ajax.query(_query.default.OPTIONS);
        var rooms = this.ajax.query(_query.default.ROOMS_FLOW);
        this.emoji.initEmojis();
        return Ember.RSVP.all([chatbot, this.users.getPropertyUsers(), ruleOptions, rooms, this.tasks.getIssueTypes()]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 5),
              chatbot = _ref2[0],
              propertyUsers = _ref2[1],
              ruleOptions = _ref2[2],
              rooms = _ref2[3],
              issueTypes = _ref2[4];

          if (chatbot.errors) {
            _this.transitionTo('flows');
          } else {
            return {
              flow: chatbot,
              propertyUsers: propertyUsers,
              ruleOptions: ruleOptions,
              issueTypes: issueTypes,
              rooms: rooms
            };
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model); // console.log('FLOW TRIGER OPTIONS', controller.get('chatbots.triggerOptions'));
      // console.log('RULES', JSON.parse(model.ruleOptions));


      controller.set('widget', this.users.widgetSettings());
      var DUMMY_CHATBOT = {
        "id": "37",
        "title": "Flow_test",
        "companyProperty": {
          "id": "1",
          "title": "TestCompany HQ",
          "company": {
            "pk": "ae05e9d0-8cca-41ad-a901-8fcf70736237",
            "id": "Q29tcGFueVR5cGU6YWUwNWU5ZDAtOGNjYS00MWFkLWE5MDEtOGZjZjcwNzM2MjM3",
            "title": "TestCompany"
          }
        },
        "extraData": "{\"isDisabled\":true}",
        "auto": true,
        "type": 1,
        "warningMessage": "Hi, we have not heard anything from you for the past 20 seconds, please type something to remain active in chat",
        "idleTime": 20,
        "timeOut": 20,
        "timeOutMessage": "This chat session has been terminated due to time-out, we are still here with you if you need anything",
        "triggers": [{
          "id": "168",
          "app": "LIVE_CHAT",
          "name": "Flow_test",
          "description": "",
          "rules": "{\"conditions\":{\"all\":[{\"name\":\"return_visitor\"}]},\"actions\":[{\"name\":\"send_chat_flow\",\"params\":{\"flow_id\":37}}]}",
          "isActive": false,
          "livechatTriggerLimit": "A_24_HOURS"
        }, {
          "id": "163",
          "app": "LIVE_CHAT",
          "name": "Flow_test",
          "description": "",
          "rules": "{\"conditions\":{\"all\":[{\"name\":\"first_visit\"}]},\"actions\":[{\"name\":\"send_chat_flow\",\"params\":{\"flow_id\":37}}]}",
          "isActive": false,
          "livechatTriggerLimit": "A_12_HOURS"
        }, {
          "id": "162",
          "app": "LIVE_CHAT",
          "name": "Flow_test",
          "description": "",
          "rules": "{\"conditions\":{\"all\":[{\"name\":\"scrolling_scroll_percentage\",\"operator\":\"greater_than\",\"value\":10,\"extraData\":\"{\\\"pageMeasure\\\":\\\"scroll\\\",\\\"pageTimeValue\\\":30,\\\"pageTimeMeasure\\\":\\\"seconds\\\",\\\"pageUrlRule\\\":\\\"exact\\\",\\\"pageScroll\\\":\\\"50%\\\",\\\"isForPage\\\":true,\\\"pageUrl\\\":\\\"qwe\\\"}\"}]},\"actions\":[{\"name\":\"send_chat_flow\",\"params\":{\"flow_id\":37}}]}",
          "isActive": false,
          "livechatTriggerLimit": "ONCE"
        }, {
          "id": "169",
          "app": "LIVE_CHAT",
          "name": "Flow_test",
          "description": "",
          "rules": "{\"conditions\":{\"all\":[{\"name\":\"unassigned_conversation\",\"extraData\":\"{\\\"idleTimeValue\\\":\\\"2\\\",\\\"idleTimeMeasure\\\":\\\"minutes\\\"}\"}]},\"actions\":[{\"name\":\"send_chat_flow\",\"params\":{\"flow_id\":37}}]}",
          "isActive": false,
          "livechatTriggerLimit": "A_12_HOURS"
        }],
        "description": "",
        "isActive": false,
        "isSystem": false,
        "newBlocks": [{
          "id": "38",
          "flow": {
            "id": "37"
          },
          "name": "Subscribe for mailing",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":-148,\"y\":843},\"connections\":[\"48\"]}",
          "blockType": "TEXT",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "30",
          "flow": {
            "id": "37"
          },
          "name": "Message",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":749.5,\"y\":1072.5},\"connections\":[]}",
          "blockType": "TEXT",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "29",
          "flow": {
            "id": "37"
          },
          "name": "Message",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":1068.5,\"y\":399.5},\"connections\":[\"9\",\"47\"]}",
          "blockType": "TEXT",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "22",
          "flow": {
            "id": "37"
          },
          "name": "Options (Single select)",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":418,\"y\":767.5},\"connections\":[\"30\",\"47\"]}",
          "blockType": "TEXT",
          "firstBlock": true,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "48",
          "flow": {
            "id": "37"
          },
          "name": "Data Collector (form)",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":376,\"y\":1082},\"connections\":[]}",
          "blockType": "FORM",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "31",
          "flow": {
            "id": "37"
          },
          "name": "Message",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":-109,\"y\":420.5},\"connections\":[\"38\"]}",
          "blockType": "TEXT",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "47",
          "flow": {
            "id": "37"
          },
          "name": "Data Collector (form)",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":704,\"y\":798},\"connections\":[\"48\"]}",
          "blockType": "FORM",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "9",
          "flow": {
            "id": "37"
          },
          "name": "Message",
          "mainTitle": "123",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":1140,\"y\":991.5},\"connections\":[]}",
          "blockType": "TEXT",
          "firstBlock": true,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "24",
          "flow": {
            "id": "37"
          },
          "name": "Options (Single select)",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":930,\"y\":765.5},\"connections\":[\"30\",\"9\"]}",
          "blockType": "TEXT",
          "firstBlock": true,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "18",
          "flow": {
            "id": "37"
          },
          "name": "Message",
          "mainTitle": "new block 2",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":802,\"y\":348.5},\"connections\":[\"29\"]}",
          "blockType": "TEXT",
          "firstBlock": true,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "40",
          "flow": {
            "id": "37"
          },
          "name": "Randomize",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":416,\"y\":441},\"connections\":[\"41\"]}",
          "blockType": "TEXT",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "21",
          "flow": {
            "id": "37"
          },
          "name": "Message",
          "mainTitle": "new block 2",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":506,\"y\":252.5},\"connections\":[\"18\"]}",
          "blockType": "TEXT",
          "firstBlock": true,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "28",
          "flow": {
            "id": "37"
          },
          "name": "Options (Single select)",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":84.5,\"y\":334.5},\"connections\":[\"31\",\"21\",\"36\",\"20\",\"40\"]}",
          "blockType": "TEXT",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "20",
          "flow": {
            "id": "37"
          },
          "name": "Message",
          "mainTitle": "new block 2",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":67,\"y\":715.5},\"connections\":[\"22\"]}",
          "blockType": "TEXT",
          "firstBlock": true,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "41",
          "flow": {
            "id": "37"
          },
          "name": "Message",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":675,\"y\":516},\"connections\":[\"24\",\"47\"]}",
          "blockType": "TEXT",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }, {
          "id": "36",
          "flow": {
            "id": "37"
          },
          "name": "Randomize",
          "mainTitle": "",
          "subtitle": null,
          "extraData": "{\"coords\":{\"x\":261,\"y\":590.5},\"connections\":[\"22\"]}",
          "blockType": "TEXT",
          "firstBlock": false,
          "actions": null,
          "childBlockIds": [],
          "attachments": [],
          "childBlocks": []
        }],
        "triggered": 0,
        "complited": 0,
        "engaged": 0
      };
      var flow = model.flow; // set(flow, 'triggers', []);

      try {
        flow.extraData = JSON.parse(flow.extraData);
      } catch (e) {
        flow.extraData = {};
      }

      flow.extraData = flow.extraData || {}; // console.log('EXTRA', flow.extraData);

      controller.set('dataCollectors', [{
        id: 1,
        type: 'email',
        label: 'Email',
        text: 'Your email please?',
        icon: 'email'
      }, {
        id: 2,
        type: 'firstName',
        label: 'First Name',
        text: 'Your first name please?',
        icon: 'user-circle'
      }, {
        id: 3,
        type: 'lastName',
        label: 'Last Name',
        text: 'Your last name please?',
        icon: 'user-circle'
      }, {
        id: 4,
        type: 'number',
        label: 'Phone Number',
        text: 'Your number please?',
        icon: 'phone'
      }, {
        id: 5,
        type: 'mobile',
        label: 'Mobile Number',
        text: 'Your mobile please?',
        icon: 'mobile'
      }]); // controller.updateDataCollectorsSelect(flow.blocks);

      var ruleOptions = JSON.parse(model.ruleOptions);
      controller.set('ruleOptions', ruleOptions);
      controller.set('flow', flow);
      controller.set('flowId', flow.id);
      controller.set('blocks', this.chatbots.deserializeFlow(flow));
      controller.set('isActiveFlow', flow.isActive);
      controller.set('propertyUsers', model.propertyUsers);
      controller.set('subscribeResponses', ['Emoji: Thumbs Up/Thumbs Down', 'Text: Sure. / No, thanks.']);
      controller.set('flowName', flow.title);
      controller.set('rulesConditions', []);
      this.chatbots.triggerOptions.forEach(function (o) {
        Ember.set(o, 'disabled', false);
      });
      var triggers = [];

      if (flow.triggers) {
        console.log('TRIGGERS', flow.triggers);
        var triggerOptions = this.chatbots.triggerOptions;
        var limits = this.chatbots.triggerLimitations;
        flow.triggers.forEach(function (t) {
          if (t.rules) {
            try {
              t.rules = JSON.parse(t.rules);
            } catch (e) {
              console.error(e);
            }
          }

          if (t.rules.conditions && t.rules.conditions.all && t.rules.conditions.all[0]) {
            var trig = triggerOptions.findBy('name', t.rules.conditions.all[0].name);

            if (trig) {
              Ember.set(trig, 'disabled', true);

              var trigger = _objectSpread({}, trig);

              trigger.ruleId = t.id;

              if (trigger.limited) {
                var limit = limits.findBy('enum', t.livechatTriggerLimit);
                trigger.livechatTriggerLimit = (limit === null || limit === void 0 ? void 0 : limit.value) || '12_HOURS'; //TODO use value from triggerLimitations instead of constant
              }

              var extraData;

              switch (t.rules.conditions.all[0].name) {
                case 'scrolling_scroll_percentage':
                  extraData = JSON.parse(t.rules.conditions.all[0].extraData || '{}');
                  trigger.pageMeasure = extraData['pageMeasure'] || 'time';
                  trigger.pageTimeValue = extraData['pageTimeValue'] || 30;
                  trigger.pageTimeMeasure = extraData['pageTimeMeasure'] || 'seconds';
                  trigger.pageScroll = extraData['pageScroll'] || '10%';
                  trigger.isForPage = extraData['isForPage'] || false;
                  trigger.pageUrlRule = extraData['pageUrlRule'] || 'exact';
                  trigger.pageUrl = extraData['pageUrl'] || '';
                  break;

                case 'current_page':
                  extraData = JSON.parse(t.rules.conditions.all[0].extraData || '{}');
                  trigger.pageUrlRule = extraData['pageUrlRule'] || 'exact';
                  trigger.pageUrl = extraData['pageUrl'] || '';
                  break;

                case 'agent_is_idle':
                  extraData = JSON.parse(t.rules.conditions.all[0].extraData || '{}');
                  trigger.idleTimeValue = extraData['idleTimeValue'] || '';
                  trigger.idleTimeMeasure = extraData['idleTimeMeasure'] || 'minutes';
                  trigger.idleTimeoutValue = extraData['idleTimeoutValue'] || '';
                  trigger.idleTimeoutMeasure = extraData['idleTimeoutMeasure'] || 'minutes';
                  break;

                case 'unassigned_conversation':
                  extraData = JSON.parse(t.rules.conditions.all[0].extraData || '{}');
                  trigger.idleTimeValue = extraData['idleTimeValue'] || '';
                  trigger.idleTimeMeasure = extraData['idleTimeMeasure'] || 'seconds';
                  break;

                default:
              } // console.log('TR', trigger);


              triggers.addObject(trigger);
            }
          }
        });
      }

      controller.set('triggers', triggers);

      if (flow.rules) {
        (function () {
          console.log('FLOW RULES', flow.rules);
          var rules = JSON.parse(flow.rules.rules);
          controller.set('checkCondition', rules.checkCondition);
          rules.conditions.all.forEach(function (item) {
            Object.keys(item).forEach(function (key) {
              if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
            });
          });

          var _loop = function _loop(i) {
            var rulesConditions = {};
            var fieldType = ruleOptions.variables.filter(function (el) {
              return el.name === rules.conditions.all[i].name.replace(' ', '_');
            });

            if (!fieldType[0]) {
              return "continue";
            }

            ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
              if (val.name === rules.conditions.all[i].operator.replace(' ', '_')) {
                rulesConditions.selectedCondition = val;
              }
            });
            rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
            ruleOptions.variables.forEach(function (val) {
              if (val.name === rules.conditions.all[i].name.replace(' ', '_')) {
                rulesConditions.selectedColumn = val;
              }
            });
            rulesConditions.selectedConditionValue = rules.conditions.all[i].value;
            controller.rulesConditions.pushObject(rulesConditions);
          };

          for (var i = 0; i < rules.conditions.all.length; i++) {
            var _ret = _loop(i);

            if (_ret === "continue") continue;
          }
        })();
      }

      controller.set('checkConditions', [{
        id: 1,
        title: this.intl.t('matches_all_conditions')
      }, {
        id: 2,
        title: this.intl.t('matches_any_conditions')
      }]);
      controller.set('checkCondition', {
        id: 1,
        title: this.intl.t('matches_all_conditions')
      });
      controller.set('ruleStruct', {
        conditions: {
          all: []
        },
        actions: []
      });
      controller.set('rule', {});
      controller.set('commentModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      controller.set('chatModules', [{
        id: 'rooms',
        label: 'Teams'
      }, {
        id: 'users',
        label: 'Direct Messages'
      }]);
      controller.set('tagModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      controller.set('optionsEditor', {
        actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'olist', 'ulist']
      });
      controller.set('actionsFlow', [{
        type: 'assign',
        label: 'Assign to'
      }, {
        type: 'tag',
        label: 'Tag the visitor'
      }, {
        type: 'contacts',
        label: 'Add visitor to Contacts'
      }, {
        type: 'close',
        label: 'Close the conversation'
      }, {
        type: 'create_ticket',
        label: 'Create ticket'
      }]);
      controller.set('triggerOptions', {
        variables: ['Ticket/Task', 'Conversation']
      });
      controller.set('someText', '{{..}}');
      controller.set('subjVariables', ['Ticket Subject', 'Order ID', 'Ticket Issue Type', 'Ticket Source']);
      controller.set('bodyVariables', ['Customer Name', 'Customer Email', 'Issue Type', 'Source', 'Assignee']);
      controller.set('toVariables', ['Customer Email']);
      controller.set('periodTimes', ['days', 'hours', 'minutes']);
      controller.set('conditionRules', {
        'Ticket/Task': ['Status', 'Priority', 'Issue Type', 'Source', 'Department', 'Assignee', 'Attachment'],
        'Conversation': ['Channel/source', 'Assignee', 'Country', 'Refund', 'Cancel', 'Profile qualification']
      });
      controller.set('conditionOperators', ['AND', 'OR']);
      controller.set('isShowTriggers', true);
      controller.set('isActiveStartBlockFlow', true); // setTimeout(() => {
      //     controller.dragCanvas()
      // }, 2000);
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.controller.resetView && this.controller.resetView();

        this._super(transition);
      }
    }
  });

  _exports.default = _default;
});