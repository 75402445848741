define("inboxfront/controllers/workflows/index", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    sliceData: Ember.inject.service(),
    FLOWSPERPAGE: 15,
    observerPage: Ember.observer('pageNum', function () {
      if (this.get('allFlows')) {
        this.set('flowsOnPage', this.get('sliceData').getSliceData(this.get('allFlows'), this.get('pageNum'), this.FLOWSPERPAGE));
      }
    }),
    selectedFlowTab: 'settings',
    actions: {
      sortFlow: function sortFlow(fieldName) {
        var sortedFlows;
        var flows = this.get('flowsOnPage');
        var isReverse = this.get('isReverse');

        if (fieldName === "Assistant Name") {
          if (isReverse.title) {
            sortedFlows = flows.sortBy("title").reverse();
            this.toggleProperty("isReverse.title");
          } else {
            sortedFlows = flows.sortBy("title");
            this.toggleProperty("isReverse.title");
          }
        }

        if (fieldName === "Sent") {
          if (isReverse.triggered) {
            sortedFlows = flows.sortBy("triggered").reverse();
            this.toggleProperty("isReverse.triggered");
          } else {
            sortedFlows = flows.sortBy("triggered");
            this.toggleProperty("isReverse.triggered");
          }
        }

        if (fieldName === "Engaged") {
          if (isReverse.engaged) {
            sortedFlows = flows.sortBy("engaged").reverse();
            this.toggleProperty("isReverse.engaged");
          } else {
            sortedFlows = flows.sortBy("engaged");
            this.toggleProperty("isReverse.engaged");
          }
        }

        if (fieldName === "Completed") {
          if (isReverse.completed) {
            sortedFlows = flows.sortBy("completed").reverse();
            this.toggleProperty("isReverse.completed");
          } else {
            sortedFlows = flows.sortBy("completed");
            this.toggleProperty("isReverse.completed");
          }
        }

        if (fieldName === "Modified On") {
          if (isReverse.updatedDate) {
            sortedFlows = flows.sortBy("updatedDate").reverse();
            this.toggleProperty("isReverse.updatedDate");
          } else {
            sortedFlows = flows.sortBy("updatedDate");
            this.toggleProperty("isReverse.updatedDate");
          }
        }

        this.set('flowsOnPage', sortedFlows);
      },
      createWorkflow: function createWorkflow() {
        var _this = this;

        var hash = {
          name: "Workflow".concat(String(Math.random()).slice(2)),
          properties: [this.get('companyId')],
          description: '',
          isActive: false,
          trigger: '',
          objectType: 'TICKET'
        };
        this.set('awaitCreateFlow', true);
        this.ajax.mutationN(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', hash).then(function (_ref) {
          var workflow = _ref.workflow;

          _this.paperToaster.show(_this.intl.t('flow_created'));

          _this.transitionToRoute('workflows.edit', {
            queryParams: {
              id: workflow.id
            }
          });
        }).catch(function (e) {
          console.error(e);
        }).finally(function () {
          _this.set('awaitCreateFlow', false);
        });
      },
      showDeleteFlowDialog: function showDeleteFlowDialog(flow) {
        this.set('selectedFlow', flow);
        this.set('openDeleteFlowDialog', true);
      },
      deleteFlow: function deleteFlow() {
        var _this2 = this;

        var selectedFlow = this.get('selectedFlow');
        this.set('isLoader', true);
        this.ajax.query(_mutation.default.DELETE_WORKFLOW, 'deleteWorkflow', {
          workflowId: selectedFlow.id
        }).then(function () {
          _this2.paperToaster.show(_this2.intl.t('delete_flow', {
            name: selectedFlow.title
          }));
        }).finally(function () {
          _this2.queryFlows();
        });
      },
      statusChangedItemFlow: function statusChangedItemFlow(flow) {
        var _this3 = this;

        this.set('selectedFlow', flow);

        if (flow.isActive) {
          Ember.set(flow, 'isActive', false);
          var hash = {
            workflowId: flow.id,
            isActive: false
          };
          this.ajax.query(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', hash).then(function () {
            _this3.paperToaster.show(_this3.intl.t('deactivate_flow', {
              name: flow.title
            }));
          }).finally(function () {
            _this3.queryFlows();
          });
        } else {
          this.set('openActivateFlowDialog', true);
        }
      },
      activateFlow: function activateFlow() {
        var _this4 = this;

        var flow = this.get('selectedFlow');

        if (flow.isValid) {
          Ember.set(flow, 'isActive', true);
          var hash = {
            workflowId: flow.id,
            isActive: true
          };
          this.ajax.query(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', hash).then(function () {
            _this4.paperToaster.show(_this4.intl.t('activate_workflow', {
              name: flow.name
            }));
          }).finally(function () {
            _this4.queryFlows();
          });
        }
      }
    },
    queryFlows: function queryFlows() {
      var _this5 = this;

      var hash = {
        properties: [this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id]
      };
      this.set('isLoader', true);
      return this.ajax.query(_query.default.WORKFLOWS, 'workflows', hash).then(function (data) {
        if (data.errors) {
          _this5.set('allFlows', []);
        } else {
          _this5.set('allFlows', data.map(function (item) {
            return _objectSpread({
              title: item.name,
              updatedDate: item.updatedAt
            }, item);
          }));

          _this5.set('pageNum', _this5.get('sliceData').getPageNum());
        }
      }).finally(function () {
        _this5.set('isLoader', false);
      });
    }
  });

  _exports.default = _default;
});