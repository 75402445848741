define("inboxfront/templates/components/assistants/dialogs/delete-flow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sjL03oMs",
    "block": "{\"symbols\":[\"dialog\"],\"statements\":[[4,\"common/dialogs/warning\",null,[[\"open\",\"onConfirm\"],[[24,[\"open\"]],[24,[\"onConfirm\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"title\"]],\"expected `dialog.title` to be a contextual component but found a string. Did you mean `(component dialog.title)`? ('inboxfront/templates/components/assistants/dialogs/delete-flow.hbs' @ L6:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"wait\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `dialog.body` to be a contextual component but found a string. Did you mean `(component dialog.body)`? ('inboxfront/templates/components/assistants/dialogs/delete-flow.hbs' @ L10:C7) \"],null]],[[\"icon\"],[\"trash2\"]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[1,[28,\"t\",[\"r_u_sure_to_delete\"],null],false],[0,\" \"],[1,[24,[\"flow\",\"title\"]],false],[0,\"?\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[23,1,[\"btn-cancel\"]],false],[0,\"\\n    \"],[1,[23,1,[\"btn-confirm\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/dialogs/delete-flow.hbs"
    }
  });

  _exports.default = _default;
});