define("inboxfront/components/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sliceData: Ember.inject.service(),
    tagName: '',
    rangeNum: Ember.computed('list.length', 'rowsPerPage', function () {
      return this.sliceData.calculateRange(this.get('list') || [], this.get('rowsPerPage'));
    }),
    actions: {
      prevPage: function prevPage(page) {
        if (page > 1) {
          this.set('pageNum', page - 1);
        }
      },
      nextPage: function nextPage(page) {
        if (page < this.rangeNum) {
          this.set('pageNum', page + 1);
        }
      },
      firstPage: function firstPage() {
        if (this.get('pageNum') !== 1) {
          this.set('pageNum', 1);
        }
      },
      lastPage: function lastPage() {
        if (this.get('pageNum') !== this.rangeNum) {
          this.set('pageNum', this.rangeNum);
        }
      }
    }
  });

  _exports.default = _default;
});