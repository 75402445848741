define("inboxfront/components/chat/create-redirect-chat", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    validation: Ember.inject.service(),
    showSelectedGuest: false,
    formIsValid: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('isThread')) {
        var _this$get;

        this.set('agent', this.get('selectedThread.agent.agentProfile.user.id') || null);
        this.set('guest', this.get('selectedThread.guestContact.guest') || {});

        if ((_this$get = this.get('guest')) === null || _this$get === void 0 ? void 0 : _this$get.companyProperty.id) {
          this.updateSelectUsers(this.get('guest').companyProperty.id);
        }
      } else {
        this.set('agent', null);
        this.set('guest', {
          companyProperty: {
            id: null
          }
        });
      }
    },
    actions: {
      selectProperty: function selectProperty(id) {
        var guestPropertyId = this.get('guestPropertyId');
        this.set('guest.companyProperty.id', id);
        /*
        if (guestPropertyId && guestPropertyId !== propertyId) {
            this.set('filterContacts', []);
            this.set('showSearchListGuests', false);
            this._deselectContact();
            this._checkForm();
        }
        */

        this.updateSelectUsers(id);
      },
      chooseAgent: function chooseAgent(agent) {
        this.set('agent', agent);

        this._checkForm();
      },
      searchContact: function searchContact(fieldId, value) {
        var _this = this;

        var propertyId = this.get('guest.companyProperty.id');
        this.set("guest.".concat(fieldId), value);

        if (propertyId) {
          if ((value === null || value === void 0 ? void 0 : value.length) > 2) {
            var hash = {
              page: 'GUEST',
              q: value,
              properties: [propertyId]
            };
            this.set('showSearchListGuests', true);
            this.ajax.query(_query.default.SEARCH_GUEST, 'search', hash).then(function (data) {
              _this.set('filterContacts', data);

              _this.set('guestPropertyId', propertyId);
            });
          } else {
            this.set('filterContacts', []);
            this.set('showSearchListGuests', false);
          }
        }

        this._checkForm();
      },
      selectContact: function selectContact(contact) {
        this.set('guest.firstName', contact.firstName);
        this.set('guest.lastName', contact.lastName);
        this.set('guest.primaryEmail', contact.email);
        this.set('filterContacts', []);
        this.set('showSearchListGuests', false);
        this.set('showSelectedGuest', true);

        this._checkForm();
      },
      deselectContact: function deselectContact() {
        this._deselectContact();
      },
      createChatLink: function createChatLink() {
        var _this2 = this;

        var _this$get2 = this.get('guest'),
            firstName = _this$get2.firstName,
            lastName = _this$get2.lastName,
            primaryEmail = _this$get2.primaryEmail,
            companyProperty = _this$get2.companyProperty,
            id = _this$get2.id;

        var agent = this.get('agent');
        var hash = {
          propertyId: companyProperty.id,
          firstName: firstName,
          lastName: lastName,
          email: primaryEmail
        };

        if (id && this.get('isThread')) {
          hash.guestId = id;
        }

        if (this.get('selectedThread.id') && this.get('isThread')) {
          hash.oldThreadId = this.get('selectedThread.id');
        }

        if (agent) {
          hash.agent = agent.staffprofile.chatAgent[0].id;
        }

        this.ajax.mutation(_mutation.default.CHAT_REDIRECT, 'chatRedirect', hash, function (res) {
          if (res.link) {
            _this2.onAction(res.link, !_this2.get('isThread'), _this2.get('guest'));
          } else {
            _this2.get('paperToaster').show(_this2.intl.t('internal_error') + ' ' + _this2.intl.t('try_later'));
          }
        });
      },
      redirectToContact: function redirectToContact() {},
      closeDialog: function closeDialog() {
        this.set('guestPropertyId', null);
        this.set('agent', null);
        this.set('guest', {
          companyProperty: {
            id: null
          }
        });
        this.onClose();
      }
    },
    updateSelectUsers: function updateSelectUsers(propertyId) {
      var _this3 = this;

      var application = 'LIVECHAT';
      var hash = {
        propertyId: propertyId,
        application: application
      };
      this.ajax.query(_query.default.HAVE_INBOX_ACCESS, 'haveInboxAccess', hash).then(function (data) {
        var users = data.errors ? [] : data;

        var agent = _this3.get('agent');

        _this3.set('users', users);

        if (agent) {
          var selectedAgent = users.findBy('id', agent);

          _this3.set('agent', selectedAgent || null);
        }

        _this3._checkForm();
      });
    },
    _deselectContact: function _deselectContact() {
      this.set('guest', this.get('selectedThread.guestContact.guest'));
      this.set('guestPropertyId', null);
      this.set('showSelectedGuest', false);
    },
    _checkForm: function _checkForm() {
      var _guest$companyPropert;

      var guest = this.get('guest');
      var agent = this.get('agent');
      var emailValid = this.get('validation').emailValidation[0].validate(guest.primaryEmail);
      var formIsValid = [guest.firstName, guest.lastName, (_guest$companyPropert = guest.companyProperty) === null || _guest$companyPropert === void 0 ? void 0 : _guest$companyPropert.id, agent].every(Boolean);
      this.set('formIsValid', formIsValid && emailValid);
    }
  });

  _exports.default = _default;
});