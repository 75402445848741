define("inboxfront/routes/escalations/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    users: Ember.inject.service(),
    departments: Ember.inject.service(),
    tasks: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var escalations = this.ajax.query(_query.default.ESCALATIONS);
      return Ember.RSVP.all([escalations, this.users.getPropertyUsers(), this.departments.load(), this.tasks.getIssueTypes()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 4),
            escalations = _ref2[0],
            propertyUsers = _ref2[1],
            departments = _ref2[2],
            issueTypes = _ref2[3];

        propertyUsers.forEach(function (u) {
          return Ember.set(u, 'fullName', "".concat(u.firstName, " ").concat(u.lastName));
        });
        propertyUsers.unshift({
          pk: 0,
          fullName: _this.intl.t('assigned_agent')
        });
        return {
          escalations: escalations,
          propertyUsers: propertyUsers,
          departments: departments.map(function (d) {
            return {
              id: d.pk,
              title: d.displayName
            };
          }),
          issueTypes: issueTypes.map(function (issue) {
            return _objectSpread(_objectSpread({}, issue), {}, {
              title: issue.issueType
            });
          }),
          properties: _this.sessionAccount.loggeduser.properties
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('targetModules', ['tasks', 'conversation']);
    }
  });

  _exports.default = _default;
});