define("inboxfront/components/kb/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    kb: Ember.inject.service(),
    tagName: '',
    actions: {
      updateVisibility: function updateVisibility(value) {
        var _this = this;

        this.get('kb').updateVisibility(this.get('base'), value).then(function () {
          _this.get('paperToaster').show(_this.intl.t('settings_saved'));
        }).catch(function (e) {});
      },
      onBlurInput: function onBlurInput() {
        var _this2 = this;

        this.get('kb').saveBase(this.get('base')).then(function () {
          _this2.get('paperToaster').show(_this2.intl.t('settings_saved'));
        }).catch(function (e) {});
      }
    }
  });

  _exports.default = _default;
});