define("inboxfront/services/ajax", ["exports", "ember-ajax/services/ajax", "inboxfront/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    queryHint: function queryHint(sessionId) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.request("".concat(_environment.default.getHint).concat(sessionId, "/"), {
          method: 'GET'
        }).then(function (data) {
          return resolve(data);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    queryTyping: function queryTyping(sessionId, isTyping) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        _this2.request("".concat(_environment.default.typingUrl).concat(sessionId, "/"), {
          method: 'POST',
          data: JSON.stringify({
            typing: isTyping
          })
        }).then(function (data) {
          return resolve(data);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    querySessionKey: function querySessionKey() {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _this3.request(_environment.default.getSession, {
          method: 'GET',
          headers: {
            'Authorization': "Bearer ".concat(_this3.get('session.data.authenticated.access_token'))
          },
          xhrFields: {
            withCredentials: true
          }
        }).then(function (data) {
          return resolve(data);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    query: function query(_query) {
      var _this4 = this;

      var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var variables = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var url = arguments.length > 3 ? arguments[3] : undefined;
      return new Promise(function (resolve, reject) {
        _this4.request(url || _environment.default.apollo.apiURL, {
          method: 'POST',
          data: {
            query: _query,
            operationName: name,
            variables: variables !== null ? JSON.stringify(variables) : ''
          },
          headers: {
            'authorization': "Bearer ".concat(_this4.get('session.data.authenticated.access_token'))
          },
          crossDomain: true
        }).then(function (data) {
          var filter;

          if (data.data.data !== null) {
            filter = data.data.data;
          } else {
            filter = data;
          }

          resolve(filter);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    mutation: function mutation(request, functionName, data, onSuccess, onError) {
      var _this5 = this;

      return this.query(request, functionName, data).then(function (result) {
        if (result && (result.status || result.success || result.ok)) {
          onSuccess && onSuccess(result);
        } else {
          onError ? onError() : _this5.get('paperToaster').show(_this5.intl.t('internal_error'));
        }
      }).catch(function (e) {
        _this5.get("paperToaster").show(_this5.intl.t('network_error'));
      });
    },
    mutationN: function mutationN(request, functionName, data) {
      var _this6 = this;

      return new Promise(function (resolve, reject) {
        _this6.query(request, functionName, data).then(function (result) {
          if (result && (result.status || result.success || result.ok)) {
            resolve(result);
          } else {
            reject(_this6.intl.t('internal_error'));

            _this6.get('paperToaster').show(_this6.intl.t('internal_error'));
          }
        }).catch(function (error) {
          reject(error);

          _this6.get('paperToaster').show(_this6.intl.t('network_error'));
        });
      });
    }
  });

  _exports.default = _default;
});