define("inboxfront/components/compact-menu", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    languages: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: '',
    conversationCount: 0,
    tooltipPosition: Ember.computed('languages.isRTL', function () {
      return this.languages.isRTL ? 'left' : 'right';
    }),
    isActiveSettingsMenu: Ember.computed('currentLink', function () {
      return this.get('adminPages').includes(this.get('currentLink'));
    }),
    isActiveContactsMenu: Ember.computed('currentLink', function () {
      return this.get('currentLink') === 'organizations';
    }),
    isActiveFlowsMenu: Ember.computed('currentLink', function () {
      return this.get('currentLink') === 'workflows';
    }),
    currentLink: Ember.computed('router.currentRoute.queryParams', function () {
      return this.router.currentRouteName.split('.')[0];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.adminPages = ['companies', 'group', 'departments', 'contactfields', 'orgfields', 'issuetypes', 'ticketfields', 'users', 'roles', 'permissions', 'channels', 'chatslimit', 'response', 'ecommerce'];
      this.menuItems = [{
        link: 'dashboard'
      }, {
        link: 'chats',
        title: 'conversations'
      }, {
        link: 'flows',
        title: 'assistants'
      }, {
        link: 'kb'
      }, {
        link: 'contacts',
        title: 'Contacts'
      }, {
        link: 'tasks',
        title: 'Tasks'
      }, {
        link: 'escalations'
      }, {
        link: 'settings',
        title: 'Administration'
      }];
      this.ajax.query(_query.default.THREADS_COUNT).then(function (res) {
        if (res.edges && res.edges.length > 0) {
          var count = (res.edges.filter(function (obj) {
            return obj.node && obj.node.unreadCount > 0;
          }) || []).length;

          _this.set('conversationCount', count);
        }
      });
    }
  });

  _exports.default = _default;
});