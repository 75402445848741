define("inboxfront/templates/components/assistants/flows/templates-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1k7Rfa9t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ecommerce-tab\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"onSelectTemplate\"]],[24,[\"template\"]]],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-space-between\"],[8],[0,\"\\n        \"],[1,[28,\"svg-icon\",[[24,[\"logo\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[24,[\"template\",\"title\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tip\"],[8],[0,\"Built by Raincheck\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"info\"],[8],[0,\"\\n        \"],[1,[24,[\"template\",\"description\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/flows/templates-list-item.hbs"
    }
  });

  _exports.default = _default;
});