define("inboxfront/controllers/escalations/main", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('channelTypes', [{
        title: 'Facebook',
        id: 'facebook'
      }, {
        title: 'LINE',
        id: 'line'
      }, {
        title: 'WeChat',
        id: 'wechat'
      }, {
        title: 'Telegram',
        id: 'telegram'
      }, {
        title: 'Twitter',
        id: 'twitter'
      }, {
        title: 'WhatsApp',
        id: 'whatsapp'
      }]);
      this.set('rules', [{
        title: 'Escalations.first_response_target',
        id: 'frt'
      }, {
        title: 'Escalations.next_response_target',
        id: 'nrt'
      }, {
        title: 'Escalations.resolution_target',
        id: 'rt'
      }]);
    },
    actions: {
      changeEscalationStatus: function changeEscalationStatus(escalation) {
        var _this = this;

        escalation = escalation || this.get('escalation');
        var status = !escalation.isActive;
        var hash = {
          escalationId: escalation.id,
          isActive: status
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_ESCALATION, 'createUpdateEscalation', hash, function () {
          Ember.set(escalation, 'isActive', status);
          escalation._original && Ember.set(escalation, '_original.isActive', status);

          _this.paperToaster.show(_this.intl.t(status ? 'Escalations.escalation_activated' : 'Escalations.escalation_deactivated'));
        });
      }
    },
    copyEscalation: function copyEscalation(escalation) {
      var conditions = escalation.conditions.map(function (c) {
        return {
          rule: c.rule,
          values: _toConsumableArray(c.values)
        };
      });
      return {
        id: escalation.id,
        name: escalation.name,
        description: escalation.description,
        targetType: escalation.targetType,
        isActive: escalation.isActive,
        conditions: conditions,
        slaTarget: JSON.parse(JSON.stringify(escalation.slaTarget)),
        reminders: escalation.reminders.map(this.copyReminder),
        escalations: escalation.escalations.map(this.copyReminder)
      };
    },
    copyReminder: function copyReminder(r) {
      return {
        id: r.id,
        minutes: r.minutes,
        rule: r.rule,
        values: _toConsumableArray(r.values),
        violated: r.violated
      };
    },
    deserializeEscalation: function deserializeEscalation(rawEscalation) {
      var escalation = rawEscalation;
      escalation.targetType = escalation.targetType.toLowerCase();
      escalation.conditions = this.deserializeConditions(escalation.conditions);
      escalation.slaTarget = JSON.parse(escalation.slaTarget);
      escalation.reminders = this.deserializeReminders(escalation.reminderSet.filter(function (r) {
        return !r.violated;
      }));
      escalation.escalations = this.deserializeReminders(escalation.reminderSet.filter(function (r) {
        return r.violated;
      }));
      escalation._original = this.copyEscalation(escalation);
      return escalation;
    },
    deserializeConditions: function deserializeConditions(rawConditions) {
      rawConditions = JSON.parse(rawConditions);
      var conditions = [];

      if (rawConditions.issueType) {
        conditions.push({
          rule: 'Issue Type',
          values: this.deserializeConditionValues(rawConditions.issueType, this.model.issueTypes)
        });
      }

      if (rawConditions.departments) {
        conditions.push({
          rule: 'Department',
          values: this.deserializeConditionValues(rawConditions.departments, this.model.departments)
        });
      }

      if (rawConditions.properties) {
        conditions.push({
          rule: 'Property',
          values: this.deserializeConditionValues(rawConditions.properties, this.model.properties)
        });
      }

      if (rawConditions.channels) {
        conditions.push({
          rule: 'Channel',
          values: this.deserializeConditionValues(rawConditions.channels, this.get('channelTypes'))
        });
      }

      return conditions;
    },
    deserializeConditionValues: function deserializeConditionValues(rawValues, allValues) {
      var values = [];
      rawValues.forEach(function (id) {
        var value = allValues.findBy('id', id);

        if (value) {
          values.push(value);
        }
      });
      return values;
    },
    deserializeReminders: function deserializeReminders(reminderSet) {
      var _this2 = this;

      var reminders = reminderSet.map(function (r) {
        return _this2.deserializeReminder(r, _this2.get('rules'), _this2.model.propertyUsers);
      });

      if (reminders.length === 0) {
        reminders.push({
          values: []
        });
      }

      return reminders;
    },
    deserializeReminder: function deserializeReminder(rawReminder, allRules, allUsers) {
      var rule = {};
      var minutes = 0;
      var rules = JSON.parse(rawReminder.rules);

      if (rules.frt) {
        rule = allRules.findBy('id', 'frt');
        minutes = +rules.frt;
      } else if (rules.nrt) {
        rule = allRules.findBy('id', 'nrt');
        minutes = +rules.nrt;
      } else {
        rule = allRules.findBy('id', 'rt');
        minutes = +rules.rt;
      }

      var values = [];

      if (rawReminder.assigned) {
        values.push(allUsers[0]);
      }

      rawReminder.users.forEach(function (userPK) {
        var user = allUsers.findBy('pk', userPK);

        if (user) {
          values.push(user);
        }
      });
      return {
        id: rawReminder.id,
        rule: rule,
        minutes: minutes,
        values: values,
        violated: rawReminder.violated
      };
    },
    serializeConditions: function serializeConditions(conditions, targetType) {
      var ret = {};
      var propertyMissed = true;
      conditions.forEach(function (c) {
        var name = '';

        switch (c.rule) {
          case 'Issue Type':
            name = 'issueType';
            break;

          case 'Department':
            name = 'departments';
            break;

          case 'Property':
            name = 'properties';
            propertyMissed = false;
            break;

          case 'Channel':
            name = 'channels';
        }

        ret[name] = c.values.map(function (v) {
          return v.id;
        });
      });

      if (targetType === 'conversation' && propertyMissed) {
        ret['properties'] = this.model.properties.map(function (p) {
          return p.id;
        });
      }

      return ret;
    },
    serializeReminder: function serializeReminder(reminder, escalationId) {
      var assigned = false;
      var users = [];
      reminder.values.forEach(function (u) {
        if (u.pk === 0) {
          assigned = true;
        } else {
          users.push(u.pk);
        }
      });
      var rules = {};
      rules[reminder.rule.id] = reminder.minutes || 0;
      var ret = {
        rules: JSON.stringify(rules),
        assigned: assigned,
        users: users,
        violated: reminder.violated
      };

      if (escalationId !== undefined) {
        ret.escalationId = escalationId;
      }

      if (reminder.id !== undefined) {
        ret.reminderId = reminder.id;
      }

      return ret;
    },
    checkFormIndex: function checkFormIndex() {
      this.set('formIsInvalid', true);
      var name = this.get('name');

      if (!this.validation.trimValidation[0].validate(name)) {
        return;
      }

      var targetType = this.get('targetType');

      if (!targetType) {
        return;
      }

      var conditions = this.get('conditions');
      var valid = true;

      for (var i = 0; i < conditions.length && valid; i++) {
        if (!conditions[i].rule) valid = false;
        if (conditions[i].values.length < 1) valid = false;
      }

      if (!valid) {
        return;
      }

      var slaTarget = this.get('slaTarget');
      valid = true;

      for (var _i = 0; _i < slaTarget.length && valid; _i++) {
        if (!slaTarget[_i].frt) valid = false;
        if (!slaTarget[_i].rt) valid = false;
      }

      if (!valid) {
        return;
      }

      var reminders = this.get('reminders');
      valid = true;

      for (var _i2 = 0; _i2 < reminders.length && valid; _i2++) {
        if (reminders[_i2].rule) {
          valid = this.validateReminder(reminders[_i2]);
        }
      }

      if (!valid) {
        return;
      }

      var escalations = this.get('sectionEscalations');
      valid = true;

      for (var _i3 = 0; _i3 < escalations.length && valid; _i3++) {
        valid = this.validateReminder(escalations[_i3]);
      }

      if (!valid) {
        return;
      }

      this.set('formIsInvalid', false);
    },
    checkFormEdit: function checkFormEdit() {
      this.set('formIsInvalid', true);
      var conditions = this.get('escalation.conditions');
      var valid = true;

      for (var i = 0; i < conditions.length && valid; i++) {
        if (!conditions[i].rule) valid = false;
        if (conditions[i].values.length < 1) valid = false;
      }

      if (!valid) {
        return;
      }

      var slaTarget = this.get('escalation.slaTarget');
      valid = true;

      for (var _i4 = 0; _i4 < slaTarget.length && valid; _i4++) {
        if (!slaTarget[_i4].frt) valid = false;
        if (!slaTarget[_i4].rt) valid = false;
      }

      if (!valid) {
        return;
      }

      var reminders = this.get('escalation.reminders');
      valid = true;

      for (var _i5 = 0; _i5 < reminders.length && valid; _i5++) {
        if (reminders[_i5].rule) {
          valid = this.validateReminder(reminders[_i5]);
        }
      }

      if (!valid) {
        return;
      }

      var escalations = this.get('escalation.escalations');
      valid = true;

      for (var _i6 = 0; _i6 < escalations.length && valid; _i6++) {
        valid = this.validateReminder(escalations[_i6]);
      }

      if (!valid) {
        return;
      }

      this.set('formIsInvalid', false);
    },
    validateReminder: function validateReminder(r) {
      return r.rule && r.minutes && r.values.length > 0;
    }
  });

  _exports.default = _default;
});