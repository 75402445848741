define("inboxfront/routes/flows/tmpl", ["exports", "inboxfront/routes/common", "inboxfront/helpers/countries-list", "inboxfront/gql/query"], function (_exports, _common, _countriesList, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var WORKFLOW = 2;

  var _default = _common.default.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    emoji: Ember.inject.service(),
    users: Ember.inject.service(),
    tasks: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('flows');
      } else {
        var flow = this.ajax.query(_query.default.CHATBOT, 'chatbot', {
          flowId: params.id
        });
        var ruleOptions = this.ajax.query(_query.default.OPTIONS);
        var rooms = this.ajax.query(_query.default.ROOMS_FLOW);
        this.emoji.initEmojis();
        return Ember.RSVP.all([flow, this.users.getPropertyUsers(), ruleOptions, rooms, this.tasks.getIssueTypes()]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 5),
              flow = _ref2[0],
              propertyUsers = _ref2[1],
              ruleOptions = _ref2[2],
              rooms = _ref2[3],
              issueTypes = _ref2[4];

          if (flow.errors) {
            _this.transitionTo('flows');
          } else {
            return {
              flow: flow,
              propertyUsers: propertyUsers,
              ruleOptions: ruleOptions,
              rooms: rooms,
              issueTypes: issueTypes
            };
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('widget', this.users.widgetSettings());
      var flow = model.flow;
      controller.set('dataCollectors', [{
        id: 1,
        type: 'email',
        label: 'Email',
        text: 'Your email please?',
        icon: 'email'
      }, {
        id: 2,
        type: 'firstName',
        label: 'First Name',
        text: 'Your first name please?',
        icon: 'user-circle'
      }, {
        id: 3,
        type: 'lastName',
        label: 'Last Name',
        text: 'Your last name please?',
        icon: 'user-circle'
      }, {
        id: 4,
        type: 'number',
        label: 'Phone Number',
        text: 'Your number please?',
        icon: 'phone'
      }, {
        id: 5,
        type: 'mobile',
        label: 'Mobile Number',
        text: 'Your mobile please?',
        icon: 'mobile'
      }]);
      controller.updateDataCollectorsSelect(flow.blocks);
      var ruleOptions = JSON.parse(model.ruleOptions);
      controller.set('ruleOptions', ruleOptions);
      controller.set('flow', flow);
      controller.set('flowId', flow.id);
      controller.set('blocks', controller.deserializeFlow(flow.blocks));
      controller.set('isActiveFlow', flow.isActive);

      if (flow.type === WORKFLOW) {
        controller.set('flowName', flow.name);
      } else {
        controller.set('flowName', flow.title);

        if (flow.extraData) {
          var extraData = JSON.parse(flow.extraData);
          flow.isDisabled = Boolean(extraData.isDisabled);
          flow.isTemplate = Boolean(extraData.isTemplate);
        }
      }

      controller.set('rulesConditions', []);

      if (flow.type === WORKFLOW) {
        var rules = [];

        if (flow.trigger && flow.trigger.conditions && flow.trigger.conditions.any) {
          var rulesObj = this.buildRules(flow.trigger.conditions.any, rules, 'OR');
          rulesObj && rulesObj.forEach(function (obj) {
            rules.addObject(obj);
          });
        }

        rules[rules.length - 1] && (rules[rules.length - 1].operator = 'AND');

        if (flow.trigger && flow.trigger.conditions && flow.trigger.conditions.all) {
          var _rulesObj = this.buildRules(flow.trigger.conditions.all, rules, 'AND');

          _rulesObj && _rulesObj.forEach(function (obj) {
            rules.addObject(obj);
          });
        }

        rules[rules.length - 1] && (rules[rules.length - 1].operator = '');
        controller.set('triggerRules', rules);
        controller.set('conditionOptions', {
          'Status': [{
            id: "OPEN",
            name: this.intl.t('status.open')
          }, {
            id: "RE_OPENED",
            name: this.intl.t('status.re_opened')
          }, {
            id: "IN_PROGRESS",
            name: this.intl.t('status.in_progress')
          }, {
            id: "RESOLVED",
            name: this.intl.t('status.resolved')
          }, {
            id: "CLOSED",
            name: this.intl.t('status.closed')
          }, // {id: "ARCHIVED", name: this.intl.t('status.archived')},
          {
            id: "DELETED",
            name: this.intl.t('status.deleted')
          }],
          'Priority': [{
            id: "URGENT",
            name: this.intl.t('priorities.urgent')
          }, {
            id: "HIGH",
            name: this.intl.t('priorities.high')
          }, {
            id: "MEDIUM",
            name: this.intl.t('priorities.medium')
          }, {
            id: "LOW",
            name: this.intl.t('priorities.low')
          }],
          'Issue Type': model.issueTypes,
          'Source': [{
            id: "NA",
            name: "NA"
          }, {
            id: "PHONE",
            name: "Phone"
          }, {
            id: "LIVECHAT",
            name: "Live Chat"
          }, {
            id: "WECHAT",
            name: "WeChat"
          }, {
            id: "TELEGRAM",
            name: "Telegram"
          }, {
            id: "LINE",
            name: "LINE"
          }, {
            id: "TWITTER",
            name: "Twitter DM"
          }, {
            id: "WHATSAPP",
            name: "WhatsApp"
          }, {
            id: "FACEBOOK",
            name: "Facebook"
          }, {
            id: 'INSTAGRAM',
            name: 'Instagram'
          }],
          'Department': flow.departments,
          'Assignee': flow.users,
          'Attachment': [{
            name: 'Is true'
          }, {
            name: 'Is false'
          }],
          'Channel/source': [{
            id: "LIVECHAT",
            name: "Live Chat"
          }, {
            id: "WECHAT",
            name: "WeChat"
          }, {
            id: "TELEGRAM",
            name: "Telegram"
          }, {
            id: "LINE",
            name: "LINE"
          }, {
            id: "TWITTER",
            name: "Twitter DM"
          }, {
            id: "WHATSAPP",
            name: "WhatsApp"
          }, {
            id: "FACEBOOK",
            name: "Facebook"
          }, {
            id: 'INSTAGRAM',
            name: 'Instagram'
          }, {
            id: 'SHOPIFY',
            name: 'Shopify'
          }, {
            id: 'BIGCOMMERCE',
            name: 'BigCommerce'
          }],
          'Country': _countriesList.COUNTRIES_LIST,
          'Refund': [{
            name: 'Is true'
          }, {
            name: 'Is false'
          }],
          'Cancel': [{
            name: 'Is true'
          }, {
            name: 'Is false'
          }],
          'Profile qualification': [{
            name: 'Is complete'
          }, {
            name: 'Is incomplete'
          }]
        });
      } else if (flow.rules) {
        (function () {
          var rules = JSON.parse(flow.rules.rules);
          controller.set('checkCondition', rules.checkCondition);
          rules.conditions.all.forEach(function (item) {
            Object.keys(item).forEach(function (key) {
              if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
            });
          });

          var _loop = function _loop(i) {
            var rulesConditions = {};
            var fieldType = ruleOptions.variables.filter(function (el) {
              return el.name === rules.conditions.all[i].name.replace(' ', '_');
            });

            if (!fieldType[0]) {
              return "continue";
            }

            ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
              if (val.name === rules.conditions.all[i].operator.replace(' ', '_')) {
                rulesConditions.selectedCondition = val;
              }
            });
            rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
            ruleOptions.variables.forEach(function (val) {
              if (val.name === rules.conditions.all[i].name.replace(' ', '_')) {
                rulesConditions.selectedColumn = val;
              }
            });
            rulesConditions.selectedConditionValue = rules.conditions.all[i].value;
            controller.rulesConditions.pushObject(rulesConditions);
          };

          for (var i = 0; i < rules.conditions.all.length; i++) {
            var _ret = _loop(i);

            if (_ret === "continue") continue;
          }
        })();
      }

      controller.set('checkConditions', [{
        id: 1,
        title: this.intl.t('matches_all_conditions')
      }, {
        id: 2,
        title: this.intl.t('matches_any_conditions')
      }]);
      controller.set('checkCondition', {
        id: 1,
        title: this.intl.t('matches_all_conditions')
      });
      controller.set('ruleStruct', {
        conditions: {
          all: []
        },
        actions: []
      });
      controller.set('rule', {});
      controller.set('flowComponents', [{
        name: 'Message',
        type: 'message',
        icon: 'message-circle',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Options (Single select)',
        type: 'single',
        icon: 'options-single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Options (Cards)',
        type: 'slider',
        icon: 'slider',
        data: null,
        blockType: 'SLIDER'
      }, {
        name: 'Data Collector (form)',
        type: 'data',
        icon: 'data-collector',
        data: null,
        blockType: 'FORM'
      }, {
        name: 'Action',
        type: 'action',
        icon: 'play',
        data: null,
        blockType: 'TEXT'
      }]);
      controller.set('commentModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      controller.set('chatModules', [{
        id: 'rooms',
        label: 'Teams'
      }, {
        id: 'users',
        label: 'Direct Messages'
      }]);
      controller.set('tagModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      controller.set('optionsEditor', {
        actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'olist', 'ulist']
      });
      controller.set('actionsFlow', [{
        type: 'assign',
        label: 'Assign to'
      }, {
        type: 'tag',
        label: 'Tag the visitor'
      }, {
        type: 'contacts',
        label: 'Add visitor to Contacts'
      }, {
        type: 'close',
        label: 'Close the conversation'
      }, {
        type: 'create_ticket',
        label: 'Create ticket'
      }]);
      controller.set('triggerOptions', {
        variables: ['Ticket/Task', 'Conversation']
      });
      controller.set('someText', '{{..}}');
      controller.set('subjVariables', ['Ticket Subject', 'Order ID', 'Ticket Issue Type', 'Ticket Source']);
      controller.set('bodyVariables', ['Customer Name', 'Customer Email', 'Issue Type', 'Source', 'Assignee']);
      controller.set('toVariables', ['Customer Email']);
      controller.set('periodTimes', ['days', 'hours', 'minutes']);
      controller.set('conditionRules', {
        'Ticket/Task': ['Status', 'Priority', 'Issue Type', 'Source', 'Department', 'Assignee', 'Attachment'],
        'Conversation': ['Channel/source', 'Assignee', 'Country', 'Refund', 'Cancel', 'Profile qualification']
      });
      controller.set('conditionOperators', ['AND', 'OR']);
      setTimeout(function () {
        controller.dragCanvas();
      }, 3000);
      console.log('TMPL', flow);
    },
    buildRules: function buildRules(flow, rules, operator) {
      var options = {
        'Ticket/Task': ['Is created', 'Is updated', 'Is deleted', 'Is idled'],
        'Conversation': ['New incoming', 'Visitor has responded', 'Is idled']
      };
      var selectedColumn = 'Ticket/Task';
      var selectedCondition;
      var idledPeriod = 0;
      var idledPeriodTimes = 'minutes';
      var arrayRules = [];
      flow.forEach(function (r) {
        switch (r.name) {
          case 'age':
            selectedColumn = 'Ticket/Task';
            break;

          case 'idled':
            if (r.module) {
              selectedColumn = 'Conversation';
            } else {
              selectedColumn = 'Ticket/Task';
            }

            break;

          case 'is_new_incomming':
            selectedColumn = 'Conversation';
            selectedCondition = 'New incoming';
            break;

          case 'visitor_has_responded':
            selectedColumn = 'Conversation';
            selectedCondition = 'Visitor has responded';
            break;
        }

        switch (r.operator) {
          case 'greater_than':
            selectedCondition = 'Is idled';
            idledPeriod = +r.value;

            if (idledPeriod >= 3600) {
              idledPeriodTimes = 'days';
              idledPeriod = Math.floor(idledPeriod / 3600);
            } else if (idledPeriod >= 60) {
              idledPeriodTimes = 'hours';
              idledPeriod = Math.floor(idledPeriod / 60);
            }

            break;

          case 'equal_to':
            selectedCondition = r.value === 'created' ? 'Is created' : r.value === 'updated' ? 'Is updated' : 'Is deleted';
        }

        arrayRules.addObject({
          selectedColumn: selectedColumn,
          selectedCondition: selectedCondition,
          idledPeriod: idledPeriod,
          idledPeriodTimes: idledPeriodTimes,
          variables: ['Ticket/Task', 'Conversation'],
          operator: operator,
          ruleOptions: options[selectedColumn]
        });
      });
      return arrayRules;
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.controller.resetView && this.controller.resetView();

        this._super(transition);
      }
    }
  });

  _exports.default = _default;
});