define("inboxfront/controllers/permissions", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    customTabs: Ember.computed('grid', function () {
      return this.get('grid').map(function (el) {
        return el.name;
      });
    }),
    roles: Ember.computed('model.roles', function () {
      var roles = _toConsumableArray(this.get('model.roles'));

      roles.push({
        id: 'create',
        name: '+ Create new role'
      });
      return roles;
    }),
    actions: {
      onChangeAllCheckbox: function onChangeAllCheckbox(el) {
        Ember.set(el, 'enabled', !el.enabled);
        el.subArr.forEach(function (item, i) {
          Ember.set(el.subArr[i], 'enabled', el.enabled);
        });

        if (this.get('selectedTab') === 'Conversation') {
          var filterContactsView = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr;
          filterContactsView.forEach(function (item, i) {
            Ember.set(filterContactsView[i], 'enabled', el.enabled);
          });
        }
      },
      onChangeTabs: function onChangeTabs(tab) {
        var selectedRole = this.get('selectedRole');
        if (tab === this.get('selectedTab')) return;
        var currentGrid = selectedRole.readyGrid.filter(function (el) {
          return el.name === tab;
        })[0];
        var arr = [];
        currentGrid.arr.filter(function (item) {
          return item;
        }).forEach(function (item) {
          var a = _objectSpread({}, item);

          a.subArr = _toConsumableArray(item.subArr);
          arr.pushObject(a);
        });

        if (currentGrid.name === 'Conversation') {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.delegate_assign', 'Permissions.respond_reply']);
        } else if (currentGrid.name === 'Administration') {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.publish']);
          arr.forEach(function (i) {
            var a = i.subArr.slice(0, 4);
            i.subArr = a.concat(i.subArr.slice(i.subArr.length - 1));
          });
        } else {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete']);
          arr.forEach(function (i) {
            i.subArr = i.subArr.slice(0, -2);
          });
        }

        Ember.set(this, 'currentGrid', arr);
        Ember.set(this, 'selectedTab', currentGrid.name);
      },
      statusChanged: function statusChanged(value) {
        this.set('isActiveRule', value);
      },
      createRole: function createRole() {
        this.callCreateRole();
      },
      toggleShowPopup: function toggleShowPopup() {
        this.set('showAddGroup', false);
      },
      onNextStep: function onNextStep() {
        this.callCreateRole(true);
        this.set('currentStep', 1);
      },
      onSelectRole: function onSelectRole(role) {
        if (role === this.get('selectedRole')) return;

        if (role.id === 'create') {
          this.clearForm();
          this.set('showAddGroup', true);
          return;
        }

        this.selectRole(role);
      },
      checkPermission: function checkPermission(val) {
        var currentGrid = this.get('currentGrid');
        var enabled = !val.enabled;

        if (!val.enabled) {
          var filterSubTypeView = currentGrid.filter(function (el) {
            return el.name === val.type;
          })[0].subArr.filter(function (el) {
            return el.name === 'view';
          })[0];

          if (val.name === 'add') {
            var filterSubTypeChange = currentGrid.filter(function (el) {
              return el.name === val.type;
            })[0].subArr.filter(function (el) {
              return el.name === 'change';
            })[0];
            var filterSubTypeDelete = currentGrid.filter(function (el) {
              return el.name === val.type;
            })[0].subArr.filter(function (el) {
              return el.name === 'delete';
            })[0];
            Ember.set(filterSubTypeView, 'enabled', true);
            Ember.set(filterSubTypeChange, 'enabled', true);
            Ember.set(filterSubTypeDelete, 'enabled', true);

            if (this.get('selectedTab') === 'Conversation') {
              var filterContacts = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr;
              filterContacts.forEach(function (item, i) {
                Ember.set(filterContacts[i], 'enabled', true);
              });
            }
          } else if (val.name === 'delete' || val.name === 'change' || val.name === 'assign') {
            Ember.set(filterSubTypeView, 'enabled', true);

            if (this.get('selectedTab') === 'Conversation') {
              var _filterContacts = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr.findBy('name', val.name);

              var filterContactsView = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr[0];
              Ember.set(_filterContacts, 'enabled', true);
              Ember.set(filterContactsView, 'enabled', true);
            }
          } else if (this.get('selectedTab') === 'Conversation' && val.name === 'reply') {
            var filterContactsEdit = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr[2];
            var filterTemplateRead = this.get('selectedRole.readyGrid').findBy('name', 'Templates').arr[0].subArr[0];
            Ember.set(filterSubTypeView, 'enabled', true);
            Ember.set(filterTemplateRead, 'enabled', true);
            Ember.set(filterContactsEdit, 'enabled', true);
          }
        }

        if (val.name === 'add' || val.name === 'change') {
          var _filterSubTypeChange = currentGrid.filter(function (el) {
            return el.name === val.type;
          })[0].subArr.filter(function (el) {
            return el.name === 'change';
          })[0];
          var filterSubTypeAdd = currentGrid.filter(function (el) {
            return el.name === val.type;
          })[0].subArr.filter(function (el) {
            return el.name === 'add';
          })[0];
          Ember.set(_filterSubTypeChange, 'enabled', enabled);
          Ember.set(filterSubTypeAdd, 'enabled', enabled);
        } else {
          Ember.set(val, 'enabled', !val.enabled);
        }
      },
      updatePermissions: function updatePermissions() {
        var _this = this;

        var hash = {
          roleId: this.get('selectedRole.id'),
          permissions: this.sortCheckBox(),
          readyGrid: JSON.stringify(this.get('selectedRole.readyGrid'))
        };
        this.ajax.mutation(_mutation.default.ADD_REMOVE_PERMISSIONS, 'addRemovePermissions', hash).then(function () {
          _this.paperToaster.show(_this.intl.t('permissions_updated'));
        });
      }
    },
    sortCheckBox: function sortCheckBox() {
      var arr = [];
      this.get('selectedRole.readyGrid').forEach(function (el) {
        el.arr.forEach(function (el) {
          el.subArr.forEach(function (el) {
            el.enabled && arr.push(el.id);
          });
        });
      });
      return arr;
    },
    selectRole: function selectRole(role) {
      Ember.set(role, 'readyGrid', this.sortGrid(JSON.parse(this.get('model.grid'))));
      Ember.set(this, 'currentGrid', role.readyGrid[0].arr);
      Ember.set(this, 'selectedRole', role);
      var currentGrid = role.readyGrid[0];
      var arr = []; //TODO remove duplication for select role

      currentGrid.arr.forEach(function (item) {
        var a = _objectSpread({}, item);

        a.subArr = _toConsumableArray(item.subArr);
        arr.pushObject(a);
      });

      if (currentGrid.name === 'Conversation') {
        this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.delegate_assign', 'Permissions.respond_reply']);
      } else if (currentGrid.name === 'Administration') {
        this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.publish']);
        arr.forEach(function (i) {
          var a = i.subArr.slice(0, 4);
          i.subArr = a.concat(i.subArr.slice(i.subArr.length - 1));
        });
      } else {
        this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete']);
        arr.forEach(function (i) {
          i.subArr = i.subArr.slice(0, -2);
        });
      }

      Ember.set(this, 'currentGrid', arr);
    },
    callToggleShowPopup: function callToggleShowPopup() {
      this.clearForm();
      this.set('showAddGroup', !this.get('showAddGroup'));
    },
    callCreateRole: function callCreateRole() {
      var _this2 = this;

      var notClose = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var hash = {
        name: this.get('name'),
        description: this.get('description'),
        isActive: this.get('isActiveRule')
      };
      this.ajax.query(_mutation.default.CREATE_UPDATE_CUSTOM_ROLE, 'createUpdateCustomRole', hash).then(function (res) {
        _this2.set('roleId', res.role.id);

        if (notClose) return;

        _this2.callToggleShowPopup();

        _this2.selectRole(res.role);

        _this2.get('roles').unshiftObject(res.role);
      });
    },
    clearForm: function clearForm() {
      this.set('currentStep', 0);
      this.set('name', '');
      this.set('description', '');
      this.set('isActiveRule', false);
      this.set('isNextStep', false);
      this.set('usersId', []);
    },
    sortGrid: function sortGrid(parse) {
      var _this3 = this;

      return Object.keys(parse).map(function (key) {
        return {
          name: key,
          arr: _this3.mappingGrid(parse[key])
        };
      });
    },
    mappingGrid: function mappingGrid(val) {
      var data = {
        "Tasks": {
          "task": [["view", 353, 1], ["add", 196, 2], ["change", 197, 3], ["delete", 198, 4], ["", 0, 5], ["", 0, 6]]
        },
        "Automation": {
          "chat flow": [["view", 354, 1], ["add", 254, 2], ["change", 255, 3], ["delete", 256, 4], ["", 0, 5], ["", 0, 6]],
          "workflow": [["view", 355, 1], ["add", 278, 2], ["change", 279, 3], ["delete", 280, 4], ["", 0, 5], ["", 0, 6]],
          "quick response": [["view", 356, 1], ["add", 184, 2], ["change", 185, 3], ["delete", 186, 4], ["", 0, 5], ["", 0, 6]],
          "escalation": [["view", 357, 1], ["add", 314, 2], ["change", 315, 3], ["delete", 316, 4], ["", 0, 5], ["", 0, 6]],
          "orchestration": null
        },
        "Contacts": {
          "organization": [["view", 359, 1], ["add", 103, 2], ["change", 104, 3], ["delete", 105, 4], ["", 0, 5], ["", 0, 6]],
          "contact": [["view", 358, 1], ["add", 82, 2], ["change", 83, 3], ["delete", 84, 4], ["", 0, 5], ["", 0, 6]]
        },
        "Administration": {
          "user": [["view", 360, 1], ["add", 10, 2], ["change", 11, 3], ["delete", 12, 4], ["", 0, 5], ["", 0, 6]],
          "company group": [["view", 361, 1], ["add", 73, 2], ["change", 74, 3], ["delete", 75, 4], ["", 0, 5], ["", 0, 6]],
          "role": [["view", 362, 1], ["add", 308, 2], ["change", 309, 3], ["delete", 310, 4], ["", 0, 5], ["", 0, 6]],
          "widget": [["view", 365, 1], ["", 0, 2], ["change", 140, 3], ["", 0, 4], ["", 0, 5], ["", 0, 6]],
          "knowledge base": [["view", 367, 1], ["add", 329, 2], ["change", 330, 3], ["delete", 331, 4], ["", 0, 5], ["", 0, 6], ["publish_article", 366, 10]],
          "channel": [["view", 364, 1], ["add", 106, 2], ["change", 107, 3], ["delete", 108, 4], ["", 0, 5], ["", 0, 6]],
          "company": [["view", 363, 1], ["add", 70, 2], ["change", 71, 3], ["delete", 72, 4], ["", 0, 5], ["", 0, 6]]
        },
        "Conversation": {
          "internal chat": [["view", 368, 1], ["add", 293, 2], ["change", 294, 3], ["delete", 295, 4], ["", 0, 5], ["", 0, 6]],
          "livechat": [["view", 369, 1], ["add", 370, 2], ["change", 371, 3], ["delete", 372, 4], ["assign", 373, 5], ["reply", 374, 6]],
          "facebook": [["view", 375, 1], ["add", 376, 2], ["change", 377, 3], ["delete", 378, 4], ["assign", 379, 5], ["reply", 380, 6]],
          "twitter": [["view", 381, 1], ["add", 382, 2], ["change", 383, 3], ["delete", 384, 4], ["assign", 385, 5], ["reply", 386, 6]],
          "wechat": [["view", 387, 1], ["add", 388, 2], ["change", 389, 3], ["delete", 390, 4], ["assign", 391, 5], ["reply", 392, 6]],
          "telegram": [["view", 393, 1], ["add", 394, 2], ["change", 395, 3], ["delete", 396, 4], ["assign", 397, 5], ["reply", 398, 6]],
          "line": [["view", 399, 1], ["add", 400, 2], ["change", 401, 3], ["delete", 402, 4], ["assign", 403, 5], ["reply", 404, 6]],
          "whatsapp": [["view", 405, 1], ["add", 406, 2], ["change", 407, 3], ["delete", 408, 4], ["assign", 409, 5], ["reply", 410, 6]],
          "instagram": [["view", 411, 1], ["add", 412, 2], ["change", 413, 3], ["delete", 414, 4], ["assign", 415, 5], ["reply", 416, 6]]
        }
      };
      return Object.keys(val).map(function (key) {
        if (!val[key]) {
          return null;
        }

        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          enabled: false,
          subArr: val[key].map(function (item) {
            return {
              name: item[0],
              id: item[1],
              enabled: false,
              type: key.charAt(0).toUpperCase() + key.slice(1)
            };
          })
        };
      });
    }
  });

  _exports.default = _default;
});