define("inboxfront/helpers/highlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.highlight = highlight;
  _exports.default = void 0;
  var MAX_VALUE_LENGTH_FOR_INDICES_IMPL = 250;
  var DEFAULT_OPTIONS = {
    caseSensitive: false
  };

  function highlight() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_OPTIONS;
    console.log('HIGHLIGHT ARTICLE', params, options);
    var value = findValueAndTransformToStringIfApplicable(params);
    var query = options.query;
    var queryIsValid = Ember.typeOf(query) === 'string' && !Ember.isEmpty(query.trim());
    var valueIsValid = Ember.typeOf(value) === 'string' && !Ember.isEmpty(value.trim());

    if (!queryIsValid) {
      return Ember.String.htmlSafe(value);
    }

    if (!valueIsValid) {
      return '';
    }

    options = Ember.assign(DEFAULT_OPTIONS, options);

    if (value.length > MAX_VALUE_LENGTH_FOR_INDICES_IMPL) {
      return regexImplementation(value, query, options);
    }

    if (!window._text_highlight_fastest_impl) {
      if (isSafari()) {
        window._text_highlight_fastest_impl = regexImplementation;
      } else {
        window._text_highlight_fastest_impl = indicesImplementation;
      }
    }

    return window._text_highlight_fastest_impl(value, query, options);
  }

  function findValueAndTransformToStringIfApplicable(params) {
    var value = params[0];
    return Ember.typeOf(value) === 'number' ? value.toString() : value;
  }

  function isSafari() {
    return /constructor/i.test(window.HTMLElement) || function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    }(!window['safari'] || typeof safari !== 'undefined' && safari.pushNotification);
  }

  function indicesImplementation(value, query, options) {
    var indices = findIndicesOf(query, value, options.caseSensitive);

    if (Ember.isEmpty(indices)) {
      return Ember.String.htmlSafe(value);
    }

    var queryLength = query.length;
    var indicesCount = indices.length;
    var valueLength = value.length;
    var result = '';
    var lastIndex;

    for (var i = 0; i < indicesCount; i++) {
      var index = lastIndex = indices[i];
      var matchPrefixStartIndex = findMatchPrefixStartIndex(indices, i, queryLength);
      result += value.slice(matchPrefixStartIndex, index);
      result += "<span class=\"mark\">".concat(value.slice(index, index + queryLength), "</span>");
    }

    if (hasRemainingUnmatchedCharacters(lastIndex, queryLength, valueLength)) {
      result += value.slice(lastIndex + queryLength, valueLength);
    }

    return Ember.String.htmlSafe(result);
  }

  function findMatchPrefixStartIndex(indices, i, queryLength) {
    return i === 0 ? 0 : indices[i - 1] + queryLength;
  }

  function hasRemainingUnmatchedCharacters(lastIndex, queryLength, valueLength) {
    var lastMatchEndIndex = lastIndex + queryLength;
    return lastMatchEndIndex < valueLength;
  }

  function findIndicesOf(query, source, caseSensitive) {
    var index,
        startIndex = 0;
    var queryLength = query.length;
    var indices = [];

    if (!caseSensitive) {
      query = query.toLowerCase();
      source = source.toLowerCase();
    }

    while ((index = source.indexOf(query, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + queryLength;
    }

    return indices;
  }

  function regexImplementation(value, query, options) {
    var regexFlags = 'gm';

    if (!options.caseSensitive) {
      regexFlags += 'i';
    }

    var regexp = new RegExp(escape(query), regexFlags);
    return Ember.String.htmlSafe(value.replace(regexp, '<span class="mark">$&</span>'));
  }

  function escape(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  var _default = Ember.Helper.helper(highlight);

  _exports.default = _default;
});